<template>
  <v-container fluid fill-height align="center">
    <v-layout align-center justify-center>
      <v-card outline width="400" max-width="570">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title align-center>
            <v-layout align-center>
              <v-icon class="mr-1">mdi-lock-outline</v-icon>
              <div class="font-weight-medium">
                {{
                  isFacebookLogin
                    ? `Continue as - ${facebookLoginData.username}`
                    : 'Login'
                }}
              </div>
            </v-layout>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-progress-circular
            size="20"
            width="3"
            color="red"
            indeterminate
            v-if="loading"
          />
        </v-toolbar>
        <v-container v-if="isFacebookLogin">
          <v-form ref="form" @submit.stop="continueFB">
            <v-text-field
              ref="brandInput"
              :error="brandInputError"
              v-model.trim="brand"
              prepend-icon="mdi-label"
              :rules="[rules.required]"
              :disabled="loading"
              label="Account"
            ></v-text-field>
            <v-row class="mt-3 mb-3" align="center" justify="space-around">
              <v-btn
                width="160"
                color="primary"
                type="submit"
                :disabled="loading"
                >Continue</v-btn
              >
            </v-row>
            <v-row class="mt-3 mb-3" align="center" justify="space-around">
              <v-btn
                plain
                small
                color="secondary"
                @click="logoutFB"
                :disabled="loading"
                >Logout Facebook</v-btn
              >
            </v-row>
          </v-form>
        </v-container>
        <v-container v-else>
          <v-form ref="form" @submit.stop="loginDOM">
            <v-text-field
              ref="brandInput"
              :error="brandInputError"
              v-model.trim="brand"
              prepend-icon="mdi-label"
              :rules="[rules.required]"
              :disabled="loading"
              label="Account"
            ></v-text-field>
            <v-text-field
              v-model="username"
              prepend-icon="mdi-account"
              :rules="[rules.required]"
              :disabled="loading"
              label="Username"
            ></v-text-field>
            <v-text-field
              v-model="password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              :disabled="loading"
              name="password-input"
              label="Password"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-row class="mt-3 mb-3" align="center" justify="space-around">
              <v-btn
                width="120"
                color="primary"
                type="submit"
                :disabled="loading"
                >Login</v-btn
              >
            </v-row>
          </v-form>
          <template v-if="hasFBLogin">
            <hr />
            <v-row class="mt-3" align="center" justify="space-around">
              <v-btn color="#395ca9" dark @click="loginFB" :disabled="loading">
                <v-icon class="mr-2">mdi-facebook</v-icon>
                Login with Facebook
              </v-btn>
            </v-row>
            <v-row class="mt-5" justify="end">
              <span class="text-caption text--disabled mr-2 mt-2"
                >V{{ appVersion }}</span
              >
            </v-row>
          </template>
        </v-container>
      </v-card>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="showErrorDialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-card-title>{{ errorDialog.title }}</v-card-title>
            <v-card-text>
              {{ errorDialog.text }}
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      brandInputError: false,
      brand: '',
      username: '',
      password: '',
      showPassword: false,
      showErrorDialog: false,
      isFacebookLogin: false,
      facebookLoginData: {
        username: '',
        password: '',
      },
      errorDialog: {
        title: '',
        text: '',
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  mounted() {
    const qs = helper.getSearchQuery();
    const { account, token } = qs;
    if (account === 'x' && token === 'x') {
      // TODO dialog error and redirect to logout
    } else if (account === 'pttgc') {
      // TODO do login
      this.loading = true;
      this.doLogin('', token, 'ad');
    }
  },
  methods: {
    ...mapActions({
      setConfig: 'account/setConfig',
    }),
    doLogin(username, password, mode) {
      api
        .login({
          username,
          password,
          mode,
          brand: this.brand,
          grant_type: 'password',
        })
        .then((res) => {
          // Got config data - save into store / localStorage
          this.setConfig(res);
          // Clear old v2 data
          if (window.localStorage) {
            window.localStorage.removeItem('domv2');
            window.localStorage.removeItem('domv2brand');
          }
          // Redirec to home
          this.$router.push({ name: 'Home' });
        })
        .catch((e) => {
          if (e && e.response && e.response.data) {
            if (e.response.data.error === 'invalid_grant') {
              this.errorDialog.title = 'Login error';
              this.errorDialog.text =
                'Invalid username or password, please try again.';
            } else if (e.response.data.error === 'invalid_login_mode') {
              this.errorDialog.title = 'Login error';
              this.errorDialog.text =
                'Login method is not currently supported.';
            } else if (e.response.data.error === 'invalid_account_right') {
              this.errorDialog.title = 'Forbidden account';
              this.errorDialog.text =
                'This account has no right to use this product.';
            } else if (e.response.status === 404) {
              this.errorDialog.title = 'Connection error';
              this.errorDialog.text =
                'Cannot connect to server, server might be under maintenance.';
            } else {
              this.errorDialog.title = 'Unknown error occured';
              this.errorDialog.text =
                'Unknown error occured, please contact administrator.';
            }
          }
          this.showErrorDialog = true;
          this.loading = false;
        });
    },
    loginDOM(e) {
      e.preventDefault();
      e.stopPropagation();
      const result = this.$refs.form.validate();
      if (!result) return;
      this.loading = true;
      this.doLogin(this.username, this.password, 'dom');
    },
    loginFB() {
      if (window.FB) {
        this.loading = true;
        window.FB.login(
          (res) => {
            if (res.status === 'connected') {
              window.FB.api('/me?fields=id,name,picture,email', (result) => {
                this.facebookLoginData.username = result.name || result.email;
                this.facebookLoginData.password = res.authResponse.accessToken;
                this.isFacebookLogin = true;
              });
            }
            this.loading = false;
          },
          { scope: 'public_profile,email' }
        );
      }
    },
    continueFB() {
      const { username, password } = this.facebookLoginData;
      this.doLogin(username, password, 'facebook');
    },
    logoutFB() {
      if (window.FB) {
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            window.FB.logout(() => {
              window.location = '/logout';
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      appVersion: 'appVersion',
      hasFBLogin: 'hasFBLogin',
    }),
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background-color: #fff;
}
</style>
