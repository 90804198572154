<template>
  <v-card class="overview-block">
    <v-overlay
      :value="loading || error"
      :opacity="0.3"
      :absolute="true"
      z-index="1"
    >
      <v-btn @click="init" icon>
        <v-icon>fa-refresh {{ loading ? 'fa-spin' : '' }}</v-icon>
      </v-btn>
    </v-overlay>
    <v-layout column>
      <div class="block-title">Overview</div>
      <v-row class="block-wrapper">
        <ValueBlock
          :countUpVal="todayCount"
          :summaryData="summaryData.today"
          @click="drillDown('today')"
        >
          <template v-slot:date>Today so far</template>
        </ValueBlock>
        <ValueBlock
          :countUpVal="yesterdayCount"
          :summaryData="summaryData.yesterday"
          @click="drillDown('yesterday')"
        >
          <template v-slot:date>Yesterday</template>
          <template v-slot:vs>vs same day last week</template>
        </ValueBlock>
        <ValueBlock
          :countUpVal="last7DayCount"
          :summaryData="summaryData.last7Day"
          @click="drillDown('previous7days')"
        >
          <template v-slot:date>Last 7 days</template>
          <template v-slot:vs>vs previous 7 days</template>
        </ValueBlock>
        <ValueBlock
          :countUpVal="last28DayCount"
          :summaryData="summaryData.last28Day"
          @click="drillDown('previous28days')"
        >
          <template v-slot:date>Last 28 days</template>
          <template v-slot:vs>vs previous 28 days</template>
        </ValueBlock>
      </v-row>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import anime from 'animejs';
import api from '@/services/api.js';
import ValueBlock from './sub/HomeOverviewValue.vue';

export default {
  name: 'HomeOverviewBlock',
  components: {
    ValueBlock,
  },
  data() {
    return {
      loading: true,
      error: false,
      todayCount: 0,
      yesterdayCount: 0,
      last7DayCount: 0,
      last28DayCount: 0,
      summaryData: {
        today: {},
        yesterday: {},
        last7Day: {},
        last28Day: {},
      },
    };
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      this.loading = true;
      this.error = false;
      const result = await api
        .getHomeSummary(this.isEngagementMode)
        .catch(() => {
          this.error = true;
        });
      if (result && result.message && result.message.message) {
        const {
          today,
          last7Day,
          last28Day,
          yesterday,
        } = result.message.message;
        this.animateCount(today.count, (n) => (this.todayCount = n));
        this.animateCount(yesterday.count, (n) => (this.yesterdayCount = n));
        this.animateCount(last7Day.count, (n) => (this.last7DayCount = n));
        this.animateCount(last28Day.count, (n) => (this.last28DayCount = n));
        this.summaryData = result.message.message;
      }
      this.loading = false;
    },
    animateCount(val, onUpdate) {
      const obj = { n: 0 };
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 500,
        easing: 'linear',
        update: () => onUpdate(obj.n),
      });
    },
    onChangePreset(preset) {
      this.selectedPreset = preset;
      this.init();
    },
    drillDown(preset) {
      const criteria = {
        payload: {
          title: 'messages',
          preset: preset,
          drilldownType: 'engage',
        },
        original: {
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
    engagementUnit() {
      return this.isEngagementMode ? 'Engagements' : 'Messages';
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-block {
  display: flex;
  grid-row: span 3;
  grid-column: span 1;
  margin: 12px;
  background-color: #4a6c91 !important;
  @media screen and (min-width: 960px) {
    grid-row: span 2;
    grid-column: span 3;
  }
  .block-title {
    padding: 18px 15px 0 15px;
    font-size: 24px;
    color: #fff;
  }
  .block-wrapper {
    margin-left: 0;
    padding-bottom: 20px;
  }
}
</style>
