<template>
  <v-container class="influencer">
    <InfluencerMain />
  </v-container>
</template>

<script>
import InfluencerMain from '@/components/Influencer/InfluencerMain.vue';

export default {
  name: 'Influencer',
  components: {
    InfluencerMain,
  },
};
</script>
