<template>
  <v-container fluid class="category-setting">
    <v-layout align-center class="tab-list">
      <div
        v-for="c in categoryData"
        :key="c.level"
        class="tab-item"
        :class="{ 'active-tab': selectedLevel === c.level }"
        @click="selectLevel(c.level)"
      >
        <span class="mr-2">{{ c.level + 1 }}. {{ c.name }}</span>
        <v-menu v-if="selectedLevel === c.level">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              :disabled="userRole === 'user'"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link @click="editLevel(c.level)">
              <v-list-item-icon>
                <v-icon> mdi-pencil </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="c.level > 0 && c.level === categoryData.length - 1"
              link
              @click="deleteLevel"
            >
              <v-list-item-icon>
                <v-icon> mdi-trash-can </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="tab-item tab-add-item" @click="addLevel">
        <v-icon> mdi-plus </v-icon>
      </div>
    </v-layout>
    <v-row>
      <v-col sm="12" md="4">
        <v-card>
          <v-card-text>
            <div clas="mb-3">
              <v-text-field
                v-model="categoryAddInput"
                label="Add category ..."
                hide-details="auto"
                @focus="focusCategoryInput = true"
                :disabled="userRole === 'user'"
                dense
                outlined
              />
              <v-layout align-center class="my-3" v-if="focusCategoryInput">
                <v-spacer></v-spacer>
                <v-btn text class="mr-3" @click="focusCategoryInput = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="categoryAddInput.length === 0"
                  @click="addCategory"
                  >Add</v-btn
                >
              </v-layout>
            </div>
            <v-row class="head-row">
              <v-col cols="6">
                <div class="title-text">Categories</div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.trim="categoryFilterInput"
                  outlined
                  label="Search"
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-list
              class="cat-list-wrapper"
              v-if="selectedCategoryGroup && filteredCategoryItem"
            >
              <v-list-item-group
                v-model="selectedCategoryIndex"
                mandatory
                color="primary"
              >
                <v-list-item
                  v-for="cItem in filteredCategoryItem"
                  :key="cItem.category"
                  :input-value="cItem.category"
                  @click="selectCategory(cItem.category)"
                >
                  <v-layout align-center justify-space-between>
                    <div class="text-body-1">{{ cItem.category }}</div>
                    <div class="d-flex flex-nowrap align-center">
                      <v-switch
                        small
                        v-model="cItem.visible"
                        inset
                        :disabled="userRole === 'user'"
                        @change="setVisible(cItem.visible, cItem.category)"
                      ></v-switch>
                      <v-btn
                        small
                        icon
                        color="#ff5555"
                        :disabled="
                          cItem.category === 'others' || userRole === 'user'
                        "
                        @click.stop="deleteCategory(cItem.category)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </v-layout>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="8">
        <v-card>
          <v-card-text>
            <div clas="mb-3">
              <v-text-field
                v-model.trim="keywordAddInput"
                label="Add keyword ..."
                hide-details="auto"
                @focus="focusKeywordInput = true"
                :disabled="userRole === 'user'"
                dense
                outlined
              />
              <template v-if="focusKeywordInput">
                <v-layout align-center class="my-3">
                  <v-select
                    v-model="keywordSentimentInput"
                    :items="sentimentValueItem"
                    label="Sentiment"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-btn text class="mr-3" @click="focusKeywordInput = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="keywordAddInput.length === 0"
                    @click="addKeyword"
                    >Add</v-btn
                  >
                </v-layout>
              </template>
            </div>
            <v-row class="head-row">
              <v-col cols="6">
                <div class="title-text">
                  Keywords for "{{ selectedCategoryName | capitalize }}""
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="keywordFilterInput"
                  outlined
                  label="Search"
                  dense
                  hide-details="auto"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="keywordHeader"
              :items="filteredKeywordItem"
              :items-per-page="10"
              :loading="loadingKeywords"
              class="elevation-0"
            >
              <template v-slot:[`item.value`]="{ item }">
                {{ getSentimentText(item.value) }}
              </template>
              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ item.updatedAt | timeFromNow }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-layout align-center>
                  <v-btn
                    x-small
                    icon
                    @click="editKeyword(item)"
                    color="primary"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    icon
                    @click="deleteKeyword(item.id)"
                    color="red"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-layout>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="levelDialogOpen" max-width="350">
      <v-card>
        <v-card-title>
          <span v-if="levelDialogMode === 'add'">
            Adding new category group
          </span>
          <span v-else>
            Rename category group {{ selectedCategoryGroupName }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              label="Group name"
              v-model.trim="levelDialogInput"
              required
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onLevelDialogClose(false)"
            tabindex="0"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="onLevelDialogClose(true)"
            :disabled="levelDialogInput.length === 0"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialogOpen" max-width="350">
      <v-card>
        <v-card-title> Edit keyword criteria </v-card-title>
        <v-card-text>
          <v-container>
            <div class="text-body-2">
              Word: <strong>"{{ editingKeyword.id }}"</strong>
            </div>
            <div class="text-body-2">
              Current criteria:
              <strong>
                "{{ this.getSentimentText(editingKeyword.value) }}"
              </strong>
            </div>
            <v-select
              v-model="editSentimentInput"
              :items="sentimentValueItem"
              label="Select new criteria"
            ></v-select>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onKeywordDialogClose(false)"
            tabindex="0"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onKeywordDialogClose(true)"
            :disabled="editSentimentInput === ''"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :open="confirmDialogOpen"
      :title="confirmDialogData.title"
      :content="confirmDialogData.content"
      okButton="Delete"
      @close="onDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';

export default {
  name: 'CategorySetting',
  components: {
    ConfirmDialog,
  },
  async created() {
    await this.getAccountCategory();
    this.selectCategory('others');
    // Admin has action for keyword
    if (this.userRole !== 'user') {
      this.keywordHeader.push({ text: 'Actions', value: 'action' });
    }
  },
  data() {
    return {
      selectedLevel: 0,
      levelDialogOpen: false,
      levelDialogMode: 'add',
      levelDialogInput: '',
      levelDialogEditing: 0,
      confirmDialogOpen: false,
      confirmDialogData: {
        title: '',
        content: '',
        payload: null,
      },
      categoryAddInput: '',
      focusCategoryInput: false,
      keywordAddInput: '',
      focusKeywordInput: false,
      sentimentValueItem: [
        { text: 'Positive', value: 5 },
        { text: 'Slightly Positive', value: 2.5 },
        { text: 'Neutral', value: 0 },
        { text: 'Slightly Negative', value: -2.5 },
        { text: 'Negative', value: -5 },
      ],
      keywordSentimentInput: 0,
      selectedCategoryIndex: 0,
      selectedCategoryName: 'others',
      loadingKeywords: false,
      keywordHeader: [
        { text: 'Word', value: 'id' },
        { text: 'Criteria', value: 'value' },
        { text: 'Updated At', value: 'updatedAt' },
      ],
      keywordList: [],
      categoryFilterInput: '',
      keywordFilterInput: '',
      //
      editingKeyword: '',
      editDialogOpen: false,
      editSentimentInput: '',
    };
  },
  methods: {
    ...mapActions({
      getAccountCategory: 'config/getAccountCategory',
    }),
    getSentimentText: helper.getSentimentText,
    selectLevel(level) {
      this.selectedLevel = level;
      this.selectCategory('others');
    },
    addLevel() {
      this.levelDialogMode = 'add';
      this.levelDialogInput = 'Level ' + this.categoryData.length;
      this.levelDialogOpen = true;
    },
    editLevel(level) {
      this.levelDialogMode = 'edit';
      this.levelDialogInput = this.selectedCategoryGroupName;
      this.levelDialogEditing = level;
      this.levelDialogOpen = true;
    },
    async onLevelDialogClose(state) {
      let result;
      if (state) {
        if (this.levelDialogMode === 'add') {
          result = await api
            .addCategoryGroup(this.levelDialogInput)
            .catch(() => {});
        } else {
          result = await api
            .renameCategoryGroup(this.levelDialogEditing, this.levelDialogInput)
            .catch(() => {});
        }
        if (result) {
          this.$toast.success(`Category group ${this.levelDialogMode}ed.`);
          await this.getAccountCategory();
        } else {
          this.$toast.error(`Category group ${this.levelDialogMode} failed.`);
        }
      }
      this.levelDialogEditing = '';
      this.levelDialogInput = '';
      this.levelDialogOpen = false;
    },
    deleteLevel() {
      this.confirmDialogData.payload = {
        mode: 'level',
      };
      this.confirmDialogData.title = `Would you like to delete category group "${this.selectedCategoryGroupName}"?`;
      this.confirmDialogData.content =
        'All categories in this group and their keywords will be removed.';
      this.confirmDialogOpen = true;
    },
    deleteCategory(category) {
      this.confirmDialogData.payload = {
        mode: 'category',
        data: {
          category,
          level: this.selectedLevel,
        },
      };
      this.confirmDialogData.title = `Would you like to delete category "${category}"?`;
      this.confirmDialogData.content =
        'All keywords in this category will be removed.';
      this.confirmDialogOpen = true;
    },
    deleteKeyword(keyword) {
      this.confirmDialogData.payload = {
        mode: 'keyword',
        data: {
          keyword,
          level: this.selectedLevel,
          category: this.selectedCategoryName,
        },
      };
      this.confirmDialogData.title = `Would you like to delete keyword "${keyword}"?`;
      this.confirmDialogData.content =
        'The selected keyword will be removed from the category.';
      this.confirmDialogOpen = true;
    },
    async onDialogConfirm(state) {
      if (state) {
        const { mode, data } = this.confirmDialogData.payload;
        let complete = false;
        let resultText = '';
        if (mode === 'level') {
          const result = await api.deleteLatestCategoryGroup().catch(() => {
            resultText = 'Category group remove failed.';
          });
          if (result) {
            complete = true;
            resultText = 'Category group removed.';
            this.selectedLevel = 0;
            await this.getAccountCategory();
          }
        } else if (mode === 'keyword') {
          const { level, keyword, category } = data;
          const result = await api
            .deleteCategoryKeyword(level, keyword, category)
            .catch(() => {
              resultText = 'Keyword remove failed.';
            });
          if (result) {
            complete = true;
            resultText = 'Keyword removed.';
            await this.selectCategory(category);
          }
        } else if (mode === 'category') {
          const { level, category } = data;
          const result = await api.deleteCategory(level, category).catch(() => {
            resultText = 'Category remove failed.';
          });
          if (result) {
            complete = true;
            resultText = 'Category removed.';
            if (this.selectedCategoryName === category) {
              await this.getAccountCategory();
              this.selectedCategoryIndex = 0;
              await this.selectCategory('others');
            }
          }
        }
        if (complete) {
          this.$toast.success(resultText);
        } else {
          this.$toast.error(resultText);
        }
      }
      this.confirmDialogOpen = false;
    },
    async addCategory() {
      this.focusCategoryInput = false;
      const result = await api
        .addCategory(this.selectedLevel, this.categoryAddInput)
        .catch(() => {});
      if (result && result.message) {
        await this.getAccountCategory();
        this.selectedCategoryName = this.categoryAddInput;
        this.categoryAddInput = '';
        this.$toast.success('Category added.');
      } else {
        this.$toast.error('Category add failed.');
      }
    },
    async addKeyword() {
      this.focusKeywordInput = false;
      const result = await api
        .addKeywordToCategory(
          this.selectedLevel,
          this.keywordAddInput,
          this.selectedCategoryName,
          this.keywordSentimentInput
        )
        .catch(() => {});
      if (result && result.message) {
        this.$toast.success('Keyword added.');
        this.keywordAddInput = '';
        this.selectCategory(this.selectedCategoryName);
      } else {
        this.$toast.error('Keyword add failed.');
      }
    },
    editKeyword(keyword) {
      this.editingKeyword = keyword;
      // TODO Convert current value to nearest?
      this.editSentimentInput = '';
      this.editDialogOpen = true;
    },
    async onKeywordDialogClose(state) {
      if (state) {
        const level = this.selectedLevel;
        const keyword = this.editingKeyword.id;
        const category = this.selectedCategoryName;
        const value = this.editSentimentInput;
        const result = await api
          .updateCategoryKeyword(level, keyword, category, value)
          .catch(() => {});
        if (result && result.message) {
          this.$toast.success('Keyword updated.');
        } else {
          this.$toast.error('Keyword update failed.');
        }
      }
      await this.selectCategory(this.selectedCategoryName);
      this.editSentimentInput = '';
      this.editDialogOpen = false;
    },
    async setVisible(state, name) {
      const level = this.selectedLevel;
      let result;
      if (state) {
        result = await api.addVisibleCategory(level, name).catch(() => {});
      } else {
        result = await api.deleteVisibleCategory(level, name).catch(() => {});
      }
      const res = state ? 'on' : 'off';
      if (result && result.message) {
        this.$toast.success(`Category is turned ${res} successfully.`);
      } else {
        this.$toast.success(`Category is failed to turn ${res}.`);
      }
      await this.getAccountCategory();
    },
    async selectCategory(category) {
      this.loadingKeywords = false;
      this.selectedCategoryName = category;
      const result = await api
        .getCategoryKeywords(this.selectedLevel, category)
        .catch(() => {});
      if (result && result.message) {
        this.keywordList = result.message;
      } else {
        this.keywordList = [];
      }
      this.loadingKeywords = false;
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'account/userRole',
      categoryData: 'config/categoryData',
      categoryLevelNameList: 'config/categoryLevelNameList',
    }),
    selectedCategoryGroup() {
      if (this.categoryData) {
        return this.categoryData[this.selectedLevel];
      }
      return { categories: [], level: 0, name: 'loading...' };
    },
    selectedCategoryGroupName() {
      if (this.selectedCategoryGroup) {
        return this.selectedCategoryGroup.name;
      }
      return '';
    },
    filteredCategoryItem() {
      if (this.categoryFilterInput && this.selectedCategoryGroup.categories) {
        const str = this.categoryFilterInput.toLowerCase();
        return this.selectedCategoryGroup.categories.filter((obj) => {
          const cName = obj.category.toLowerCase();
          return cName.includes(str);
        });
      }
      return this.selectedCategoryGroup.categories;
    },
    filteredKeywordItem() {
      if (this.keywordFilterInput && this.keywordList) {
        const str = this.keywordFilterInput.toLowerCase();
        return this.keywordList.filter((obj) => {
          const cName = obj.id.toLowerCase();
          return cName.includes(str);
        });
      }
      return this.keywordList;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-setting {
  .tab-list {
    margin-bottom: 1rem;
  }
  .tab-item {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 0.5rem 0.8rem;
    margin-right: -1px;
    cursor: pointer;
    line-height: 20px;
    height: 36px;
    &.active-tab {
      background-color: var(--primary);
      color: #fff;
    }
  }
  .tab-add-item {
    padding: 0.3rem;
  }
  .head-row {
    margin: 36px 0 8px 0;
    background-color: rgb(250, 250, 250);
    .title-text {
      line-height: 24px;
      padding: 8px 0;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
