<template>
  <v-container class="feed-content">
    <v-row>
      <v-col class="feed-col">
        <MessageCard
          class="feed-message"
          v-for="message in evenOrAllData"
          :key="message.id"
          :data="message"
          :subCategoryName="subCategoryName"
          :truncate="true"
          @click="seeMore(message)"
        />
      </v-col>
      <v-col class="feed-col pl-0" v-if="show2Column && separateFeedData.odd">
        <MessageCard
          class="feed-message"
          v-for="message in separateFeedData.odd"
          :key="message.id"
          :data="message"
          :subCategoryName="subCategoryName"
          :truncate="true"
          @click="seeMore(message)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import MessageCard from '../Messages/MessageCard.vue';

export default {
  name: 'FeedPageContent',
  props: {
    type: String,
    feedData: Array,
    subCategoryName: Object,
  },
  components: {
    MessageCard,
  },
  methods: {
    ...mapActions({
      seeMore: 'seemore/show',
    }),
  },
  mounted() {
    // Test lineage KFC
    // this.seeMore({
    //   info: {
    //     id: 'facebook_131743256880713_4011291152259218_4011349855586681',
    //     source: 'facebook',
    //     origin_id: 'facebook_131743256880713_4011291152259218',
    //   },
    // });
    // Test video seemore KFC
    // this.seeMore({
    //   info: {
    //     id: 'facebook_131743256880713_4017198175001849',
    //     source: 'facebook',
    //     origin_id: 'facebook_131743256880713_4017198175001849',
    //   },
    // });
    // Test twitter video KBANK
    // this.seeMore({
    //   info: {
    //     id: 'twitter_1399693603299946498',
    //     source: 'twitter',
    //     origin_id: 'twitter_1399693603299946498',
    //   },
    // });
  },
  computed: {
    show2Column() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false;
        case 'sm':
          return false;
        default:
          return true;
      }
    },
    separateFeedData() {
      const result = {
        odd: [],
        even: [],
      };
      if (this.feedData) {
        for (let i in this.feedData) {
          if (i % 2 === 0) {
            result.even.push(this.feedData[i]);
          } else {
            result.odd.push(this.feedData[i]);
          }
        }
      }
      return result;
    },
    evenOrAllData() {
      if (this.show2Column && this.separateFeedData.even) {
        return this.separateFeedData.even;
      }
      return this.feedData;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-content {
  padding-bottom: 15px;
  .feed-col {
    // padding-right: 0;
  }
}
</style>
