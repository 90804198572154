<template>
  <div class="category-bar">
    <span
      class="category-item"
      v-for="c in categoryItemList"
      :key="c.key"
      :class="c.levelClass"
    >
      {{ c.category }} : {{ c.value }}
    </span>
  </div>
</template>

<script>
const categoryKeys = [
  'categories',
  'sub_categories_level_1',
  'sub_categories_level_2',
  'sub_categories_level_3',
  'sub_categories_level_4',
];
export default {
  name: 'CategoryBar',
  props: {
    analyticData: Object,
    subCategoryName: Object,
  },
  computed: {
    categoryItemList() {
      const result = [];
      if (
        this.analyticData &&
        this.analyticData.categories &&
        this.subCategoryName
      ) {
        for (let kIndex in categoryKeys) {
          const k = categoryKeys[kIndex];
          const catList = this.analyticData[k];
          const catName = this.subCategoryName[k];
          if (catList) {
            for (let sub of catList) {
              result.push({
                category: catName,
                value: sub,
                index: kIndex,
                key: `${kIndex}-${sub}`,
                levelClass: `level-${kIndex}`,
              });
            }
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-bar {
  display: flex;
  flex-wrap: wrap;
  .category-item {
    font-size: 10px;
    border: 1px solid #ddd;
    padding: 3px;
    text-transform: uppercase;
    margin: 2px;
    padding: 2px 5px;
    border-radius: 3px;
    white-space: nowrap;
    font-weight: bold;
    color: #888;
    &.level-1 {
      border-color: #ffcfbf !important;
    }
    &.level-2 {
      border-color: #f4e68d !important;
    }
    &.level-3 {
      border-color: #afd6e3 !important;
    }
    &.level-4 {
      border-color: #b0dea9 !important;
    }
  }
}
</style>
