import Vue from 'vue';
import api from '@/services/api';

import dayjs from 'dayjs';

const titleDatePostfix = {
  today: 'on today so far',
  yesterday: 'on yesterday',
  previous7days: 'during last 7 days',
  previous28days: 'during last 28 days',
};
function convertPreset(preset) {
  let untilDate = dayjs().endOf('day').valueOf();
  let sinceDate;
  switch (preset) {
    case 'today':
      sinceDate = dayjs().startOf('day').valueOf();
      break;
    case 'yesterday':
      sinceDate = dayjs().subtract(1, 'days').startOf('day').valueOf();
      break;
    case 'previous7days':
      sinceDate = dayjs().subtract(7, 'days').startOf('day').valueOf();
      break;
    case 'previous28days':
      sinceDate = dayjs().subtract(28, 'days').startOf('day').valueOf();
      break;
  }
  return { sinceDate, untilDate, postFix: titleDatePostfix[preset] };
}

export default {
  namespaced: true,
  state: {
    open: false,
    loading: false,
    drilldownType: '',
    title: '',
    origin: '',
    resultData: {},
  },
  getters: {
    messageList: ({ resultData }) => {
      if (resultData && resultData.data) {
        return resultData.data;
      }
      return [];
    },
    subCategoryName: ({ resultData }) => {
      if (resultData && resultData.subCategoryName) {
        return resultData.subCategoryName;
      }
      return {};
    },
    currentCriteria: ({ resultData }) => {
      if (resultData && resultData.criteria) {
        return resultData.criteria;
      }
      return {};
    },
    currentPage: ({ resultData }) => {
      if (resultData && resultData.currentPage) {
        return resultData.currentPage;
      }
      return 0;
    },
    totalPage: ({ resultData }) => {
      if (resultData && resultData.totalPage) {
        return resultData.totalPage;
      }
      return 0;
    },
  },
  actions: {
    showDrilldown({ commit, dispatch }, criteria) {
      // TODO load other criteria from current filter?
      // Setup criteria
      const { payload, original } = criteria;
      const finalCriteria = { ...original };
      const { title, preset, drilldownType } = payload;
      let finalTitle = title;
      if (preset) {
        const { sinceDate, untilDate, postFix } = convertPreset(preset);
        finalCriteria.sinceDate = sinceDate;
        finalCriteria.untilDate = untilDate;
        finalTitle = `${finalTitle} ${postFix}`;
      }
      // Set open, title and loading
      commit('setDialogState', {
        loading: true,
        open: true,
        title: finalTitle,
        drilldownType,
      });
      dispatch('loadDrilldownData', finalCriteria);
    },
    changePage({ commit, getters, dispatch }, pageNumber) {
      commit('setDialogState', {
        loading: true,
      });
      const criteria = getters.currentCriteria;
      const paging = {
        ...criteria.paging,
        page: pageNumber,
      };
      const finalCriteria = { ...criteria, paging };
      dispatch('loadDrilldownData', finalCriteria);
    },
    async loadDrilldownData(
      { commit, dispatch, rootGetters, state },
      criteria
    ) {
      // Load data
      // TODO handle error...
      const type = state.drilldownType;
      let result;
      if (type === 'influencer') {
        result = await api.getInfluencerDrilldown(criteria).catch(() => {});
      } else if (type === 'engage') {
        const isEngagement = rootGetters.isEngagementMode;
        if (isEngagement) {
          criteria.sort = {
            direction: 'desc',
            field: 'engagement_score',
          };
          result = await api.getEngageDrilldown(criteria).catch(() => {});
        } else {
          result = await api.getDrilldown(criteria).catch(() => {});
        }
      }
      if (result && result.message) {
        // Do highlight
        dispatch(
          'config/highlightKeywords',
          {
            messageList: result.message.data,
          },
          { root: true }
        );
        commit('setDialogState', {
          resultData: result.message,
          loading: false,
          open: true,
        });
      }
    },
    close({ commit }) {
      commit('setDialogState', {
        open: false,
        loading: false,
        drilldownType: '',
        title: '',
        origin: '',
        resultData: {},
      });
    },
  },
  mutations: {
    setDialogState(state, data) {
      const keys = Object.keys(data);
      for (let key of keys) {
        Vue.set(state, key, data[key]);
      }
    },
  },
};
