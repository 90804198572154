import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// import auth from './modules/auth';
import account from './modules/account';
import config from './modules/config';
import drilldown from './modules/drilldown';
import seemore from './modules/seemore';
import filter from './modules/filter';
import toast from './modules/toast';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'domv3',
  reducer: (state) => ({ account: state.account }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: process.env.NODE_ENV,
    appVersion: process.env.VUE_APP_VERSION,
    appBuiltDate: process.env.VUE_APP_BUILTDATE,
    hasFBLogin: !!process.env.VUE_APP_FB_LOGIN,
    // Other app state
  },
  // getters: {
  //   route()
  // }
  mutations: {},
  actions: {},
  modules: {
    account,
    config,
    drilldown,
    seemore,
    filter,
    toast,
  },
  plugins: [vuexLocal.plugin],
});
