<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="counter-element"
        :class="additionClass"
      >
        <SentimentIcon :type="type" />
        {{ count | numeral('0,0.0a', true) }}
      </span>
    </template>
    {{ typeName }} : {{ count | numeral('0,0', true) }}
  </v-tooltip>
</template>

<script>
import SentimentIcon from '../SentimentIcon.vue';

export default {
  name: 'EngagementCounter',
  components: {
    SentimentIcon,
  },
  props: {
    type: String,
    typeName: String,
    count: Number,
    additionClass: String,
  },
};
</script>

<style lang="scss" scoped>
.counter-element {
  color: rgba(0, 0, 0, 0.38);
  font-weight: 500;
  padding: 5px;
  font-size: 13px;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 30px;
  &.reaction-element {
    min-width: 70px;
  }
}
</style>
