<template>
  <div class="advance-setting">
    <AlertToast />
    <template v-if="loading">...</template>
    <template v-else>
      <v-tabs v-model="tab" centered>
        <v-tab>Keyword & Excluded</v-tab>
        <v-tab>Fanpage</v-tab>
        <v-tab>Category</v-tab>
        <v-tab>Alert</v-tab>
        <v-tab>User Manager</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <KeywordAndExcludedKeyword />
        </v-tab-item>
        <v-tab-item>
          <Fanpage />
        </v-tab-item>
        <v-tab-item>
          <Category />
        </v-tab-item>
        <v-tab-item>
          <AlertPage />
        </v-tab-item>
        <v-tab-item>
          <UserManager />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>
<script>
import KeywordAndExcludedKeyword from '@/components/Setting/AdvanceSetting/KeywordAndExcludedKeyword.vue';
import Fanpage from '@/components/Setting/AdvanceSetting/Fanpage.vue';
import Category from '@/components/Setting/AdvanceSetting/Category.vue';
import UserManager from '@/components/Setting/AdvanceSetting/UserManager.vue';
import AlertPage from '@/components/Setting/AdvanceSetting/Alert.vue';
import AlertToast from '@/components/Alert/AlertToast.vue';
export default {
  name: 'AdvanceSetting',
  components: {
    KeywordAndExcludedKeyword,
    Fanpage,
    AlertPage,
    AlertToast,
    Category,
    UserManager,
  },
  data() {
    return {
      loading: true,
      tab: null,
    };
  },
  async created() {
    this.loading = false;
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>
