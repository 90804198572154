/* eslint-disable no-unused-vars, max-len */

import axios from 'axios';

const loginUrl = '/services/login';
const logoutUrl = '/services/logout';

const apiMainUrl = '/api';
const authApiUrl = `${apiMainUrl}/auth`;
const analyticApiUrl = `${apiMainUrl}/analytic`;
const corpusApiUrl = `${apiMainUrl}/corpus`;
const corpusV2ApiUrl = `${apiMainUrl}/corpusV2`;
const exportApiUrl = `${apiMainUrl}/export`;
const notificationApiUrl = `${apiMainUrl}/notification`;

// Interceptors for API call
const apiInterceptor = (response) => {
  // Auto extract data from response
  if (response.data) {
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }
  return response;
};

// Setup Global intercepter
axios.interceptors.response.use(apiInterceptor, (error) =>
  Promise.reject(error)
);

// Api Instance + intercepter
const apiInstance = axios.create({
  timeout: 600000,
});
apiInstance.interceptors.response.use(apiInterceptor, (error) =>
  Promise.reject(error)
);

function apiRequest(url, method, path, data, params) {
  return apiInstance
    .request({
      method,
      url: `${url}/${path}`,
      data,
      params,
    })
    .catch((e) => {
      // TODO handle more error here if possible
      if (e.response && e.response.status === 401) {
        window.localStorage.removeItem('domv3');
        window.location = '/logout';
      }
      throw e;
    });
}

function getReq(apiUrl, path, params) {
  return apiRequest(apiUrl, 'get', path, undefined, params);
}

function delReq(apiUrl, path, params, data) {
  return apiRequest(apiUrl, 'delete', path, data, params);
}

function postReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'post', path, data, params);
}

function putReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'put', path, data, params);
}

function extractErrorMessage(e) {
  console.log(e.response)
  if (typeof e === 'string') {
    return e;
  }
  if (e.response && e.response.data) {
    const d = e.response.data;
    return d.description || d.message || 'Unknown error';
  }
}

function login(data) {
  const { username, password, brand, mode } = data;
  const grant_type = 'password';
  return axios.post(loginUrl, {
    username,
    password,
    grant_type,
    brand,
    mode,
  });
}

function logout() {
  return axios.get(logoutUrl);
}

function engageUrl(isEngage) {
  return isEngage ? 'engagement' : 'count';
}

// prettier-ignore
export default {
  // Handler
  extractErrorMessage,
  // Internal
  login,
  logout,
  // Analytic
  // -- Drilldown
  getDrilldown: (data) => postReq(analyticApiUrl, 'drilldown', data),
  getEngageDrilldown: (data) => postReq(analyticApiUrl, 'engagement-drilldown', data),
  getInfluencerDrilldown: (data) => postReq(analyticApiUrl, 'influencer/drilldown', data),
  // -- Home
  getHomeSummary: (isEngage) => postReq(analyticApiUrl, `home/${engageUrl(isEngage)}`),
  getHomeSentiment: (isEngage, time) => postReq(analyticApiUrl, `home/sentiment/${engageUrl(isEngage)}`,{},{ time }),
  getHomeWordTrend: (isEngage, time) => postReq(analyticApiUrl, `home/word-trend/${engageUrl(isEngage)}`,{},{ time }),
  getHomeInfluencerTrend: (isEngage, time) => postReq(analyticApiUrl,`home/influencer-trend/${engageUrl(isEngage)}`,{},{ time }),
  getHomeSourceTraffic: (isEngage, time) => postReq(analyticApiUrl, `home/source-traffic/${engageUrl(isEngage)}`, {}, { time }),
  getHomeCategoryTrend: (isEngage, time, level = 0) => postReq(analyticApiUrl, `home/category-trend/${engageUrl(isEngage)}`, {}, { time, level }),
  // -- Graph
  getSentimentPie: (data, options) => postReq(analyticApiUrl, 'graph/sentiment-pie', data, options),
  getCategoryPie: (data, options) => postReq(analyticApiUrl, 'graph/category-pie', data, options),
  getSourcePie: (data, options) => postReq(analyticApiUrl, 'graph/source-pie', data, options),
  getCategoryHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/category-history', data, options),
  getSentimentHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/sentiment-history', data, options),
  getCategorySentimentHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/category-history/sentiment', data, options),
  // -- Location
  getLocation: (data) => postReq(analyticApiUrl, 'location', data),
  getMapDrilldown: (data) => postReq(analyticApiUrl, 'map-drilldown', data),
  getFeedbyID: (id) => getReq(analyticApiUrl, 'search/find-by-id', { id }),
  // -- Influencer
  getInfluencer: (isEngage, data) => postReq(analyticApiUrl, `influencer/get-parent/${engageUrl(isEngage)}`, data),
  getInfluencerChildNode: (isEngage, parentID, data) => postReq(analyticApiUrl, `influencer/get-children/${engageUrl(isEngage)}`, data, { parentIds: parentID }),
  // -- Feed
  getLatestFeed: (data) => postReq(analyticApiUrl, 'feed', data),
  getImageFeed: (data) => postReq(analyticApiUrl, 'feed/image', data),
  getVideoFeed: (data) => postReq(analyticApiUrl, 'feed/video', data),
  getOlderLatestFeed: (data) => postReq(analyticApiUrl, 'feed/seemore', data),
  getOlderImageFeed: (data) => postReq(analyticApiUrl, 'feed/image/seemore', data),
  getOlderVideoFeed: (data) => postReq(analyticApiUrl, 'feed/video/seemore', data),
  // -- Search
  isLineageAvailable: (id) => getReq(analyticApiUrl, 'search/find-by-id/is-lineage-available', { id }),
  getSearchData: (data) => postReq(analyticApiUrl, 'search', data),
  getSearchImage: (data) => postReq(analyticApiUrl, 'search/image', data),
  getSearchVideo: (data) => postReq(analyticApiUrl, 'search/video', data),
  // -- Seemore
  getSeemoreData: (originId, paging) => postReq(analyticApiUrl, 'seemore', { originId, paging }),
  getSeemoreLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage', null, { 'record-id': id }),
  getNextLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage/next', null, { 'record-id': id, limit: 5 }),
  getPreviousLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage/previous', null, { 'record-id': id, limit: 5 }),
  getLineageReplies: (id) => postReq(analyticApiUrl, 'seemore/lineage/sub', null, { 'record-id': id, limit: 5 }),
  // -- Usage
  getUsageStats: (sinceDate, untilDate) => getReq(analyticApiUrl, 'usage/stats', { sinceDate, untilDate }),
  // -- Message
  rejectMessage: (id, mode) => putReq(analyticApiUrl, 'message/reject', null, { 'message-id': id, mode }),
  updateSentiment: (id, mode, sentiment) => putReq(analyticApiUrl, 'message/sentiment-edit', null, { 'message-id': id, mode, sentiment }),
  updateCategory: (id, mode, category) => putReq(analyticApiUrl, 'message/sub-category-edit', category, { 'message-id': id, mode }),
  // Export
  newExport: (data, fileType, fileName) => postReq(exportApiUrl, 'export', data, { fileType, fileName }),
  getExportStatus: (user, pageNumber) => postReq(exportApiUrl, 'userexportinfo', { statuses: [] }, { user, pageNumber }),
  setDownloadedStatus: (ticketID) => getReq(exportApiUrl, 'setdownloaded', { ticketID }),
  setRetryStatus: (ticketID) => getReq(exportApiUrl, 'retryexport', { ticketID }),
  removeExport: (ticketID) => getReq(exportApiUrl, 'removeexport', { ticketID }),
  // CorpusV2
  getAccountCategory: () => getReq(corpusV2ApiUrl, 'category/get-account-category'),
  // -- Category group
  addCategoryGroup: (groupName) => postReq(corpusV2ApiUrl, 'category/add-category-group', null, { groupName }),
  renameCategoryGroup: (level, groupName) => putReq(corpusV2ApiUrl, 'category/rename-category-group', null, { level, groupName }),
  deleteLatestCategoryGroup: () => delReq(corpusV2ApiUrl, 'category/delete-latest-category-group'),
  // -- Category
  addCategory: (level, category) => postReq(corpusV2ApiUrl, 'category/add-category', null, { level, category }),
  deleteCategory: (level, category) => delReq(corpusV2ApiUrl, 'category/delete-category', { level, category }),
  addVisibleCategory: (level, category) => postReq(corpusV2ApiUrl, 'category/add-visible-category', null, { level, category }),
  deleteVisibleCategory: (level, category) => delReq(corpusV2ApiUrl, 'category/delete-visible-category', { level, category }),
  // -- Keyword
  getCategoryKeywords: (level, category) => getReq(corpusV2ApiUrl, 'category/get-keyword-from-category', { level, category }),
  addKeywordToCategory: (level, id, category, value) => postReq(corpusV2ApiUrl, 'category/add-keyword-to-category', { level, id, category, value }),
  deleteCategoryKeyword: (level, id, category) => delReq(corpusV2ApiUrl, 'category/delete-keyword-from-category', null, { level, id, category }),
  updateCategoryKeyword: (level, id, category, value) => putReq(corpusV2ApiUrl, 'category/update-keyword-in-category', { level, id, category, value }),
  // CorpusV1
  addAccountKeyword: (data) => postReq(corpusApiUrl, 'keyword/add-account-keyword', data),
  getAccountKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-keyword'),
  deleteAccountKeyword: (keywordId) => delReq(corpusApiUrl, 'keyword/delete-account-keyword', { keyword: keywordId }),
  addAccountExcludedKeyword: (data) => postReq(corpusApiUrl, 'keyword/add-account-exclude-keyword', data),
  getAccountExcludedKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-exclude-keyword'),
  deleteAccountExcludedKeyword: (keywordId) => delReq(corpusApiUrl, 'keyword/delete-account-exclude-keyword',{ keyword: keywordId }),
  getAccountFanpage: () => getReq(corpusApiUrl, '/fanpage/get-fanpage-list'),
  addAccountFanpage: (data) => postReq(corpusApiUrl, '/fanpage/add-fanpage', data),
  updateAccountFanpage: (data) => putReq(corpusApiUrl, '/fanpage/update-fanpage', data),
  deleteAccountFanpage: (fanpageId) => delReq(corpusApiUrl, '/fanpage/delete-fanpage', { 'fanpage-id': fanpageId }),
  changeUserPassword: (options) => putReq(corpusApiUrl, 'user/change-password', null, options),
  getUserList: () => getReq(corpusApiUrl, 'user/get-all-account/admin', { 'account-id': 'insightera', 'type': 'all' }),
  removeUser: (id, role) => delReq(corpusApiUrl, `user/delete-by-id/${role}`, { id }),
  addUser: (data) => postReq(corpusApiUrl, `user/add-user/${data.role}`, data ),
  updateUser: (data, options) => putReq(corpusApiUrl, `user/update-by-id/${data.role}`, data, options),
  getAlertKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-alert-keyword'),
  addAlertKeyword: (id, value) => postReq(corpusApiUrl, 'keyword/add-account-alert-keyword', { id, value }),
  removeAlertKeyword: (keyword) => delReq(corpusApiUrl, 'keyword/delete-account-alert-keyword', { keyword }),
  editAlertKeyword: (id, value) => putReq(corpusApiUrl, 'keyword/update-account-alert-keyword', { id, value }),
};
