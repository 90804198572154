import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

import Home from '../views/Home.vue';
import Influencer from '../views/Influencer.vue';
import AdvanceSetting from '../views/AdvanceSetting.vue';
import Location from '../views/Location.vue';
import Login from '../views/Auth/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    alias: '/callback',
    meta: { layout: 'BlankLayout' },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      hasModeToggle: true,
    },
  },
  {
    path: '/graph',
    name: 'Sentiment',
    component: () =>
      import(/* webpackChunkName: "sentiment" */ '../views/Sentiment.vue'),
    meta: {
      requiresAuth: true,
      hasFilter: true,
      hasSearch: true,
      hasModeToggle: true,
      disableFilter: ['sort'],
    },
  },
  {
    path: '/live-feed',
    name: 'LiveFeed',
    component: () =>
      import(/* webpackChunkName: "live-feed" */ '../views/LiveFeed.vue'),
    meta: {
      requiresAuth: true,
      hasFilter: true,
      hasSearch: true,
      disableFilter: ['sort', 'time'],
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: {
      requiresAuth: true,
      hasFilter: true,
      hasSearch: true,
      hasModeToggle: true,
      disableFilter: ['sort', 'time'],
    },
  },
  {
    path: '/highlight',
    name: 'Highlight',
    component: () =>
      import(/* webpackChunkName: "highlight" */ '../views/Highlight.vue'),
    meta: {
      requiresAuth: true,
      hasFilter: true,
      hasSearch: true,
      disableFilter: ['sort', 'sentiment'],
    },
  },
  {
    path: '/location',
    name: 'Location',
    component: Location,
    meta: {
      requiresAuth: true,
      hasSearch: true,
    },
  },
  {
    path: '/influencer',
    name: 'Influencer',
    component: Influencer,
    meta: {
      requiresAuth: true,
      hasFilter: true,
      hasSearch: true,
      hasModeToggle: true,
      enableFilter: ['influencer'],
      disableFilter: ['originOnly'],
    },
  },
  {
    path: '/export',
    name: 'Export',
    component: () =>
      import(/* webpackChunkName: "export" */ '../views/Export.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: AdvanceSetting,
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Virtual logout route -> would be redirect to login anyway - no need to interrupt
  if (to.path === '/login' && to.redirectedFrom === '/logout') {
    store.dispatch('account/logout').then(() => {
      next();
    });
  } else if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    // Do simple check with cookie
    store
      .dispatch('account/getUsageStats')
      .then(() => {})
      .catch(() => {});
    if (store.getters['account/hasUserInfo']) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
