<template>
  <v-dialog
    scrollable
    v-model="isOpen"
    content-class="drilldown-dialog"
    max-width="900px"
  >
    <v-card outline>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title align-center>
          <v-layout class="text-body-2" align-center>
            <span v-if="isLoading">
              <v-icon>fa-spinner fa-spin</v-icon>
              <span class="ml-1">Loading messages ...</span>
            </span>
            <span v-else> Detail for {{ title }} </span>
          </v-layout>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon small> fa-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-layout justify-space-around v-if="isLoading">
          <v-progress-circular class="my-4" size="50" width="3" indeterminate />
        </v-layout>
        <template v-else>
          <MessageItem
            v-for="msg in messageList"
            :key="msg.info.id"
            :data="msg"
            :subCategoryName="subCategoryName"
            @click="seeMore(msg)"
          />
          <v-container>
            <v-pagination
              v-model="currentPage"
              :length="totalPageValue"
              :total-visible="7"
            />
          </v-container>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MessageItem from '@/components/Messages/MessageItem.vue';

export default {
  name: 'DrilldownDialog',
  components: {
    MessageItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      seeMore: 'seemore/show',
      close: 'drilldown/close',
      changePage: 'drilldown/changePage',
    }),
  },
  computed: {
    ...mapState({
      open: (state) => state.drilldown.open,
      isLoading: (state) => state.drilldown.loading,
      title: (state) => state.drilldown.title,
    }),
    ...mapGetters({
      messageList: 'drilldown/messageList',
      subCategoryName: 'drilldown/subCategoryName',
      currentPageValue: 'drilldown/currentPage',
      totalPageValue: 'drilldown/totalPage',
    }),
    currentPage: {
      get: function () {
        return this.currentPageValue;
      },
      set: function (val) {
        this.changePage(val);
      },
    },
    isOpen: {
      get: function () {
        return this.open;
      },
      set: function (val) {
        if (!val) {
          this.close();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.drilldown-dialog {
}
</style>
