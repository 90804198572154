<template>
  <div class="lineage-item">
    <v-card
      class="load-more-card"
      :class="loadMoreClass"
      v-if="message.lineageControl && message.lineageControl.hasPrev"
      @click="getPrevious"
    >
      <v-card-text>
        <span v-if="loadingPrev"> Loading&nbsp;</span>
        <span v-else>Load previous {{ message.lineage.index }}&nbsp;</span>
        <span v-if="message.lineage.level === 1">comments</span>
        <span v-if="message.lineage.level === 2">replies</span>
      </v-card-text>
    </v-card>
    <MessageItem
      :data="message"
      :subCategoryName="subCategoryName"
      :class="messageClass"
    />
    <v-card
      class="load-more-card message-subcomment"
      v-if="message.lineageControl && message.lineageControl.hasReplies"
      @click="getReply"
    >
      <v-card-text>
        <span v-if="message.lineage.child_count > 1">
          Load more {{ message.lineage.child_count }} replies
        </span>
        <span v-if="message.lineage.child_count === 1"> Load more reply </span>
      </v-card-text>
    </v-card>
    <v-card
      class="load-more-card"
      :class="loadMoreClass"
      v-if="message.lineageControl && message.lineageControl.hasNext"
      @click="getNext"
    >
      <v-card-text>
        <span v-if="loadingNext"> Loading&nbsp;</span>
        <span v-else
          >Load more {{ message.lineageControl.nextCount }}&nbsp;</span
        >
        <span v-if="message.lineage.level === 1">comments</span>
        <span v-if="message.lineage.level === 2">replies</span>
      </v-card-text>
    </v-card>
    <v-card
      class="load-more-card"
      :class="loadMoreClass"
      v-if="message.lineageControl && message.lineageControl.hasFinalNext"
      @click="getNextFinal"
    >
      <v-card-text>
        <span v-if="loadingFinalNext"> Loading&nbsp;</span>
        <span v-else
          >Load more {{ message.lineageControl.finalNextCount }} comments</span
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MessageItem from './MessageItem.vue';

export default {
  name: 'LineageItem',
  props: {
    message: Object,
    subCategoryName: Object,
  },
  components: {
    MessageItem,
  },
  data() {
    return {
      loadingPrev: false,
      loadingNext: false,
      loadingFinalNext: false,
      loadingReplies: false,
    };
  },
  methods: {
    ...mapActions({
      updateDataList: 'seemore/updateDataList',
      getRepliesLineage: 'seemore/getRepliesLineage',
      getNextLineage: 'seemore/getNextLineage',
      getPrevLineage: 'seemore/getPreviousLineage',
      getFinalNextLineage: 'seemore/getFinalNextLineage',
    }),
    async getPrevious() {
      this.loadingPrev = true;
      await this.getPrevLineage(this.message);
      this.loadingPrev = false;
    },
    async getReply() {
      this.loadingReplies = true;
      await this.getRepliesLineage(this.message);
      this.loadingReplies = false;
    },
    async getNext() {
      this.loadingNext = true;
      await this.getNextLineage(this.message);
      this.loadingNext = false;
    },
    async getNextFinal() {
      this.loadingFinalNext = true;
      const { lastCommentId } = this.message.lineageControl;
      await this.getFinalNextLineage(lastCommentId).catch(() => {});
      this.loadingFinalNext = false;
    },
  },
  computed: {
    messageClass() {
      if (this.message && this.message.info) {
        // Set message class
        const { origin_id, parent_id, id } = this.message.info;
        if (id === origin_id) {
          return 'message-origin';
        } else if (origin_id === parent_id) {
          return 'message-comment';
        } else {
          return 'message-subcomment';
        }
      }
      return '';
    },
    loadMoreClass() {
      if (this.message && this.message.lineage) {
        if (this.message.lineage.level === 1) {
          return 'message-comment';
        } else if (this.message.lineage.level === 2) {
          return 'message-subcomment';
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.lineage-item {
  .load-more-card {
    margin-bottom: 8px;
    cursor: pointer;
  }
  .message-comment {
    margin-left: 15px;
  }
  .message-subcomment {
    margin-left: 30px;
  }
}
</style>
