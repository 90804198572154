import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    isEngagementMode: true,
    filterOpen: false,
    filterCriteria: {},
    usingFilter: false,
    dirtyCategory: false,
  },
  getters: {
    usingFilter: (state) => state.usingFilter,
    filterCriteria: (state) => state.filterCriteria,
    filterEnableConfig(state, getters, rootState) {
      const { meta } = rootState.route;
      const config = {
        category: true,
        source: true,
        keywordPhrase: true,
        excludeKeywordPhrase: true,
        sentiment: true,
        username: true,
        sort: true,
        time: true,
        hashtag: true,
        neutralizeUsername: true,
        influencer: false,
        originOnly: true,
        edited: true,
      };
      if (meta && meta.enableFilter) {
        for (let f of meta.enableFilter) {
          config[f] = true;
        }
      }
      if (meta && meta.disableFilter) {
        for (let f of meta.disableFilter) {
          config[f] = false;
        }
      }
      return config;
    },
  },
  actions: {
    toggleEngageMode({ commit }, data) {
      commit('toggleEngageMode', data);
    },
    async initExportFilter({ rootGetters, dispatch }) {
      console.log('INIT FIlterE');

      const c = rootGetters['config/categoryData'];
      // NOTE in case of it doesn't load data
      if (!c || c.length === 0) {
        await dispatch('config/getAccountCategory', null, { root: true });
      }
      let category = rootGetters['config/categoryNameList'];
      const subCategory = rootGetters['config/subCategoryList'];
      const source = rootGetters['account/sourceNameList'];
      const sort = {
        field: 'engagement_score',
        direction: 'desc',
      };

      return {
        category,
        subCategory,
        source,
        sentiment: ['positive', 'neutral', 'negative'],
        sort,
        keywordPhrase: '',
        excludeKeywordPhrase: '',
        username: '',
      };
    },
    async initFilterCriteria({
      commit,
      getters,
      rootGetters,
      rootState,
      dispatch,
      state,
    }) {
      console.log('INIT FIlterC');

      const c = rootGetters['config/categoryData'];
      // NOTE in case of it doesn't load data
      if (!c || c.length === 0) {
        await dispatch('config/getAccountCategory', null, { root: true });
      }
      let category = rootGetters['config/categoryNameList'];
      const subCategory = rootGetters['config/subCategoryList'];
      const source = rootGetters['account/sourceNameList'];

      // If this page is 'Sentiment' - remove Others from level 0 category;
      const { name: routeName } = rootState.route;
      if (
        routeName === 'Sentiment' &&
        !state.dirtyCategory &&
        subCategory &&
        subCategory.length > 0
      ) {
        category = category.filter((c) => c !== 'others');
      }

      let sort = {
        field: 'engagement_score',
        direction: 'desc',
      };
      if (!getters.usingFilter) {
        if (routeName === 'Search') {
          sort = {
            field: 'search_score',
            direction: 'desc',
          };
        }
      }
      commit('setFilterCriteria', {
        category,
        subCategory,
        source,
        sentiment: ['positive', 'neutral', 'negative'],
        sort,
        originOnly: false,
        isAndCategory: false,
        edited: 'null',
      });
    },
    setFilterCriteria({ commit }, data) {
      commit('setFilterCriteria', data);
      commit('setUsingFilter', true);
    },
    async resetFilterCriteria({ commit, dispatch }) {
      await dispatch('initFilterCriteria');
      commit('setUsingFilter', false);
      commit('setDirtyCategory', false);
    },
    setKeywordPhrase({ commit }, data) {
      commit('setKeywordPhrase', data);
    },
    setFilterOpen({ commit }, data) {
      commit('setFilterOpen', data);
    },
    setDirtyCategory({ commit }, data) {
      commit('setDirtyCategory', data);
    },
  },
  mutations: {
    toggleEngageMode(state, data) {
      Vue.set(state, 'isEngagementMode', data);
    },
    setFilterCriteria(state, data) {
      Vue.set(state, 'filterCriteria', data);
    },
    setKeywordPhrase(state, data) {
      const fc = JSON.parse(JSON.stringify(state.filterCriteria));
      if (!data) {
        fc.keywordPhrase = '';
      } else {
        fc.keywordPhrase = data;
      }
      Vue.set(state, 'filterCriteria', fc);
    },
    setUsingFilter(state, data) {
      state.usingFilter = data;
    },
    setFilterOpen(state, data) {
      state.filterOpen = data;
    },
    setDirtyCategory(state, data) {
      state.dirtyCategory = data;
    },
  },
};
