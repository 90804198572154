<template>
  <v-layout
    @click="$emit('click')"
    class="widget-row"
    :class="{ 'has-mid': hasMid }"
    :justify-space-between="!hasRanking"
    align-center
  >
    <v-layout class="ranking-column" align-center v-if="hasRanking">
      <div>
        <div class="main-rank">
          <slot name="main-rank"></slot>
        </div>
        <div
          class="sub-rank"
          v-if="!hideChange"
          :class="isPNZ(rankChange, isNewRank)"
        >
          <slot name="sub-rank"></slot>
        </div>
      </div>
    </v-layout>
    <v-layout align-center>
      <slot name="left"></slot>
    </v-layout>
    <v-layout class="mid-column" align-center v-if="hasMid">
      <slot name="mid"></slot>
    </v-layout>
    <v-layout class="value-column" align-center justify-end>
      <div>
        <div class="main-value">
          <slot name="main-value"></slot>
        </div>
        <div class="sub-value" v-if="!hideChange" :class="isPNZ(countChange)">
          <slot name="sub-value"></slot>
        </div>
        <div class="sub-value" v-else>--</div>
      </div>
    </v-layout>
  </v-layout>
</template>
<script>
export default {
  name: 'WidgetRow',
  props: {
    hasMid: Boolean,
    hasRanking: Boolean,
    isNewRank: Boolean,
    rankChange: Number,
    countChange: Number,
    hideChange: Boolean,
  },
  methods: {
    isPNZ(value, isNew) {
      if (isNew) return 'is-new';
      // Positive Negative Zero LOLLL
      if (value > 0) return 'is-positive';
      else if (value < 0) return 'is-negative';
      else return 'is-zero';
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-row {
  padding: 0.8rem;
  color: #333;
  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }
  .main-rank,
  .main-value {
    font-size: 18px;
    line-height: 22px;
    padding: 5px 0;
  }
  .main-rank {
    font-size: 14px;
  }
  .main-value,
  .sub-value {
    text-align: right;
  }
  .sub-rank,
  .sub-value {
    font-size: 11px;
  }
  .ranking-column {
    flex: 0 0 20%;
  }
  .value-column {
    flex: 0 0 40%;
  }
  &.has-mid {
    .mid-column {
      flex: 0 0 20%;
    }
    .value-column {
      flex: 0 0 30%;
    }
  }
}
</style>
