<template>
  <home-base-widget
    class="home-categorytrend-block"
    title="Trending Categories"
    :selectedPreset="selectedPreset"
    :hasRowHead="true"
    :error="error"
    :loading="loading"
    @reload="init"
    @changePreset="onChangePreset"
  >
    <template v-slot:row-head>
      <div style="flex: 0 0 20%">Rank</div>
      <div style="flex: 1 1 auto">Category</div>
      <div style="flex: 0 0 40%" class="text-right pr-3">Engagement</div>
    </template>
    <widget-row
      v-for="s in categoryTrendData"
      :key="s.trend"
      :hasRanking="true"
      :countChange="s.countChanges"
      :rankChange="s.positionChanges"
      :isNewRank="s.positionTrend === 'NEW'"
      @click="drillDown(s.trend)"
    >
      <template v-slot:main-rank>
        {{ s.currentPosition }}
      </template>
      <template v-slot:sub-rank>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span>
                {{ s.positionTrend }}
              </span>
              <span v-if="s.positionChanges !== 0">
                {{ s.positionChanges }}
              </span>
            </span>
          </template>
          <span v-if="s.positionTrend !== 'NEW'">
            From position {{ s.previousPosition }}
          </span>
          <span v-else>Newly added</span>
        </v-tooltip>
      </template>
      <template v-slot:left>
        <div>
          <span class="text-body ml-2">{{ s.trend }}</span>
        </div>
      </template>
      <template v-slot:main-value>
        {{ s.count | numeral('0.00a') }}
      </template>
      <template v-slot:sub-value>
        <div class="text-center" v-if="s.countTrend === 'NEW'">-</div>
        <div v-else>
          <span>
            {{ s.countTrend }}
          </span>
          <span>
            {{ s.countChanges | numeral('+0.0a', true) }}
          </span>
          <span> ({{ (s.changesPercent * 100) | numeral('+0.00a') }}%) </span>
        </div>
      </template>
    </widget-row>
  </home-base-widget>
</template>

<script>
import HomeBaseWidget from './sub/HomeBaseWidget.vue';
import WidgetRow from './sub/WidgetRow.vue';
import { mapState, mapActions } from 'vuex';
import api from '@/services/api.js';

export default {
  name: 'HomeCategoryTrend',
  components: {
    HomeBaseWidget,
    WidgetRow,
  },
  data() {
    return {
      loading: true,
      error: false,
      selectedPreset: 'previous7days',
      categoryTrendData: [],
    };
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      this.error = false;
      this.loading = true;
      const result = await api
        .getHomeCategoryTrend(this.isEngagementMode, this.selectedPreset)
        .catch(() => {
          this.error = true;
        });
      if (result && result.message && result.message.trends) {
        this.categoryTrendData = result.message.trends;
      }
      this.loading = false;
    },
    onChangePreset(preset) {
      this.selectedPreset = preset;
      this.init();
    },
    drillDown(category) {
      const criteria = {
        payload: {
          title: `category "${category}"`,
          preset: this.selectedPreset,
          drilldownType: 'engage',
        },
        original: {
          category: [category],
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
  },
};
</script>

<style lang="scss" scoped>
.home-categorytrend-block {
  grid-row: span 5;
  grid-column: span 1;
}
</style>
