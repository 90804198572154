<template>
  <div v-show="showNetworkGraph" class="networkgraph">
    <v-card outline>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title align-center>
          <v-layout class="text-body-2" align-center>
            <span v-if="isNetworkGraphLoading">
              <v-icon>fa-spinner fa-spin</v-icon>
              <span class="ml-1">Loading network graph ...</span>
            </span>
            <span v-else> Network Graph for user {{ graphTitle }} </span>
          </v-layout>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon small> fa-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2" light>
        <v-layout justify-space-around v-show="isNetworkGraphLoading">
          <v-progress-linear
            class="networkgraph-loading-line"
            indeterminate
          ></v-progress-linear>
        </v-layout>
        <div class="tool">
          <span class="title-btn">Number of children</span>
          <v-btn-toggle mandatory borderless tile v-model="toggle_exclusive">
            <v-btn
              elevation="2"
              tile
              min-width="30"
              width="30"
              height="36"
              @click="setEdgeLimit(10)"
              >10</v-btn
            >
            <v-btn
              elevation="2"
              tile
              min-width="30"
              width="30"
              height="36"
              @click="setEdgeLimit(25)"
              >25</v-btn
            >
            <v-btn
              elevation="2"
              tile
              min-width="30"
              width="30"
              height="36"
              @click="setEdgeLimit(50)"
              >50</v-btn
            >
            <v-btn
              elevation="2"
              tile
              min-width="30"
              width="30"
              height="36"
              @click="setEdgeLimit(100)"
              >100</v-btn
            >
          </v-btn-toggle>
        </div>
        <div id="influencer-graph" ref="graph"></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import { mapState } from 'vuex';
import api from '@/services/api.js';

export default {
  name: 'NetworkGraph',
  props: {
    showNetworkGraph: Boolean,
  },
  data() {
    return {
      isNetworkGraphLoading: Boolean,
      networkParent: Object,
      allParent: Array,
      nodes: new DataSet(),
      edges: new DataSet(),
      toggle_exclusive: 2,
      graphTitle: '-',
      edgeLimit: 50,
      graphOptions: {
        autoResize: true,
        height: '500px',
        width: '100%',
        interaction: {
          hover: true,
          navigationButtons: true,
          keyboard: {
            enabled: true,
            bindToWindow: false,
          },
        },
        edges: {
          smooth: {
            forceDirection: 'none',
            roundness: 0.4,
          },
          scaling: {
            min: 2,
            max: 10,
          },
        },
        nodes: {
          color: {
            highlight: {
              border: '#ff4d4d',
              background: '#ff4d4d',
            },
            hover: {
              border: '#99ffbb',
              background: '#99ffbb',
            },
          },
          scaling: {
            min: 25,
            max: 35,
            label: {
              min: 15,
              max: 18,
            },
          },
        },
        physics: {
          enabled: true,
          forceAtlas2Based: {
            gravitationalConstant: -50,
            centralGravity: 0.01,
            springConstant: 0.1,
            springLength: 80,
            damping: 0.5,
            avoidOverlap: 0.8,
          },
          minVelocity: 2,
        },
      },
    };
  },
  methods: {
    show(parent) {
      this.isNetworkGraphLoading = true;
      this.allParent = [];
      this.networkParent = parent;
      this.nodes.clear();
      this.edges.clear();
      this.setParent(parent);
      this.setChild(parent);
      new Network(
        this.$refs.graph,
        { nodes: this.nodes, edges: this.edges },
        this.graphOptions
      );
    },
    showAll(parentList) {
      this.isNetworkGraphLoading = true;
      this.allParent = parentList;
      this.nodes.clear();
      this.edges.clear();
      for (let i = 0; i < parentList.length; i++) {
        this.setParent(parentList[i]);
        this.setChild(parentList[i]);
      }
      new Network(
        this.$refs.graph,
        { nodes: this.nodes, edges: this.edges },
        this.graphOptions
      );
    },
    setParent(parent) {
      this.graphTitle = parent.username || '-';
      let actions = parent.posts;
      let reactions = parent.engagements;
      let title =
        '' +
        '<div class="name">' +
        parent.username +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        parent.node_score +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';
      this.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.username,
        shape: 'circularImage',
        image:
          'https://cdn.ask-dom.com/img/user-force/' +
          parent.source +
          '/' +
          parent.img,
        title: this.htmlTitle(title),
        value: 2,
      });
    },
    async setChild(parent) {
      let networkArg = { nodeLimit: 50, edgeLimit: this.edgeLimit };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(this.isEngagementMode, parentID, networkArg)
        .catch(() => {
          this.error = true;
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image:
              'https://cdn.ask-dom.com/img/user-force/' +
              childlist.peopleInfo[id].source +
              '/' +
              childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: this.htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      this.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = this.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = this.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = this.htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      this.edges.update(childlist.relationship);
      this.isNetworkGraphLoading = false;
    },
    htmlTitle(html) {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    },
    close() {
      this.$emit('graphClose');
    },
    setEdgeLimit(number) {
      this.edgeLimit = number;
      if (this.allParent) {
        this.showAll(this.allParent);
      } else {
        this.show(this.networkParent);
      }
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: 'isEngagementMode',
    }),
  },
};
</script>

<style lang="scss">
.networkgraph {
  .networkgraph-loading-line {
    position: absolute;
    top: 64px;
  }
  .tool {
    position: absolute;
    z-index: 1;
    .title-btn {
      margin-right: 10px;
    }
    button {
      margin-right: 5px;
    }
  }
  .vis-network {
    outline: none !important;
    .vis-tooltip {
      position: absolute !important;
      background-color: #fff;
      border: 1px solid #888;
      border-radius: 5px;
      padding: 10px;
      z-index: 121;
      .name {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        font-weight: 600;
      }
      .graph-info {
        text-align: center;
        font-size: 12px;
        u {
          color: rgba(0, 0, 0, 0.87);
          font-weight: 600;
        }
      }
      table tr td {
        padding: 10px;
        margin: 10px;
      }
    }
    .vis-navigation {
      .vis-button {
        height: 30px;
        width: 30px;
        position: absolute;
        &.vis-up {
          bottom: 50px;
          right: 50px;
          background-image: url(https://app.ask-dom.com/images/network/upArrow.png);
        }
        &.vis-down {
          bottom: 10px;
          right: 50px;
          background-image: url(https://app.ask-dom.com/images/network/downArrow.png);
        }
        &.vis-left {
          bottom: 10px;
          right: 90px;
          background-image: url(https://app.ask-dom.com/images/network/leftArrow.png);
        }
        &.vis-right {
          bottom: 10px;
          right: 10px;
          background-image: url(https://app.ask-dom.com/images/network/rightArrow.png);
        }
        &.vis-zoomIn {
          top: 10px;
          right: 10px;
          background-image: url(https://app.ask-dom.com/images/network/plus.png);
        }
        &.vis-zoomOut {
          top: 50px;
          right: 10px;
          background-image: url(https://app.ask-dom.com/images/network/minus.png);
        }
        &.vis-zoomExtends {
          top: 10px;
          right: 50px;
          background-image: url(https://app.ask-dom.com/images/network/zoomExtends.png);
        }
      }
    }
  }
}
</style>
