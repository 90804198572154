<template>
  <v-container fluid class="alert-keyowrd">
    <v-card>
      <div class="add-input-wrapper">
        <div class="add-input mt-3">
          <v-text-field
            label="Add Keyword Alert..."
            hide-details="auto"
            :rules="rules"
            v-model="addKeywordInput"
            @focus="focusKeywordAdd = true"
            dense
            outlined
          >
          </v-text-field>
          <v-select
            v-model="addKeywordSentiment"
            :items="sentimentValueItem"
            dense
            outlined
            label="Select new value"
          ></v-select>
          <template v-if="focusKeywordAdd">
            <div class="add-btn-wrapper">
              <div class="add-btn">
                <v-btn
                  text
                  @click="
                    focusKeywordAdd = false;
                    addKeywordInput = '';
                  "
                >
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="addKeyword"> Add </v-btn>
              </div>
            </div>
          </template>
        </div>
      </div>
      <TableBase
        :headers="headers"
        :items="keywordList"
        title="Alert"
        :loading="loading"
        canEdit
        @edit="updateKeyword"
        @delete="deleteKeyword"
      />

      <!-- dialog delete -->
      <ConfirmDialog
        title="Remove confirmation"
        :content="dialogDeleteContent"
        :open="dialogDelete"
        @close="confirmDeleteAlert"
      />

      <!-- dialog update -->
      <v-dialog
        content-class="update-dialog"
        v-model="dialogUpdate"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Updating value for keyword "{{ updatingKeyword.id }}"
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="keywordSentimentInput"
              :items="sentimentValueItem"
              label="Select new value"
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="confirmUpdate(true)">
              Save
            </v-btn>
            <v-btn color="grey darken-1" text @click="confirmUpdate(false)">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/services/api.js';

import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';
import TableBase from '@/components/Table/TableBase.vue';

export default {
  name: 'AlertSetting',
  components: {
    ConfirmDialog,
    TableBase,
  },
  async created() {
    await this.loadKeywordList();
  },
  data() {
    return {
      loading: true,
      keywordList: [],
      focusKeywordAdd: false,
      addKeywordInput: '',
      addKeywordSentiment: '',
      dialogDelete: false,
      dialogDeleteContent: '',
      deletingKeyword: '',
      dialogUpdate: false,
      updatingKeyword: '',
      sentimentValueItem: [
        { text: 'Positive', value: 5 },
        { text: 'Slightly Positive', value: 2.5 },
        { text: 'Neutral', value: 0 },
        { text: 'Slightly Negative', value: -2.5 },
        { text: 'Negative', value: -5 },
      ],
      keywordSentimentInput: '',
    };
  },
  methods: {
    async loadKeywordList() {
      const result = await api.getAlertKeyword().catch(() => {});
      if (result && result.message) {
        this.keywordList = result.message;
      }
      this.loading = false;
    },
    async addKeyword() {
      const id = this.addKeywordInput;
      const value = this.addKeywordSentiment;
      const result = await api.addAlertKeyword(id, value).catch(() => {
        this.$toast.error('Alert keyword add failed');
      });
      if (result && result.message) {
        this.$toast.success('Alert keyword added.');
        await this.loadKeywordList();
        this.addKeywordInput = '';
        this.addKeywordSentiment = '';
      }
      this.focusKeywordAdd = false;
    },
    deleteKeyword(keyword) {
      this.dialogDeleteContent = `Are you sure to remove alert keyword "${keyword.id}"`;
      this.deletingKeyword = keyword;
      this.dialogDelete = true;
    },
    async confirmDeleteAlert(state) {
      if (state) {
        const { id } = this.deletingKeyword;
        const result = await api.removeAlertKeyword(id).catch(() => {
          this.$toast.error('Alert keyword remove failed');
        });
        if (result && result.message) {
          this.$toast.success('Alert keyword removed.');
          await this.loadKeywordList();
        }
      }
      this.dialogDelete = false;
    },
    updateKeyword(keyword) {
      this.updatingKeyword = keyword;
      this.dialogUpdate = true;
      this.keywordSentimentInput = '';
    },
    async confirmUpdate(state) {
      if (state) {
        const { id } = this.updatingKeyword;
        if (typeof this.keywordSentimentInput === 'number') {
          const result = await api
            .editAlertKeyword(id, this.keywordSentimentInput)
            .catch(() => {
              this.$toast.error('Alert keyword update failed');
            });
          if (result && result.message) {
            this.$toast.success('Alert keyword updated.');
            await this.loadKeywordList();
          }
        }
      }
      this.dialogUpdate = false;
    },
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        {
          text: 'Keyword',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Score',
          align: 'center',
          sortable: true,
          value: 'value',
        },
        {
          text: 'Updated At',
          sortable: true,
          filterable: false,
          value: 'updatedAt',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          filterable: false,
          value: 'action',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-input-wrapper {
  display: flex;
  justify-content: center;
  .add-input {
    width: 500px;
    .v-select {
      margin-top: 30px;
    }
  }
  .add-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .add-btn button:last-child {
      margin-left: 20px;
    }
  }
}
</style>
