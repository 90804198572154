<template>
  <v-container class="location pa-0" fluid>
    <v-row no-gutters class="location-content-row">
      <v-col class="location-map">
        <v-overlay class="location-loading-overlay" v-show="mapLoading">
          <v-progress-linear
            class="location-loading-line"
            indeterminate
          ></v-progress-linear>
          <v-progress-circular
            class="my-4 location-loading-circle"
            size="50"
            width="3"
            indeterminate
          />
        </v-overlay>
        <GoogleMap @mapChange="mapChange" />
      </v-col>
      <v-col class="location-message">
        <div class="message-top-button row">
          <div class="col-6 button-wrapper">
            <v-btn elevation="2" tile>Filter</v-btn>
          </div>
          <div class="col-6 button-wrapper">
            <v-btn elevation="2" tile>Amount: {{ totalRecord }}</v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="message-content">
          <v-overlay class="location-loading-overlay" v-show="drilldownLoading">
            <v-progress-linear
              class="location-loading-line"
              indeterminate
            ></v-progress-linear>
            <v-progress-circular
              class="my-4 location-loading-circle"
              size="50"
              width="3"
              indeterminate
            />
          </v-overlay>
          <FeedPageContent
            v-show="!drilldownLoading"
            type="latest"
            :feedData="mapFeedData"
            :subCategoryName="mapCategoryName"
          />
          <v-container v-if="totalPage > 1" v-show="!drilldownLoading">
            <v-pagination
              v-model="currentPage"
              :length="totalPage"
              :total-visible="7"
            />
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoogleMap from '@/components/Map/GoogleMap.vue';
import FeedPageContent from '@/components/LiveFeed/FeedPageContent.vue';
import api from '@/services/api.js';

export default {
  name: 'Location',
  components: {
    GoogleMap,
    FeedPageContent,
  },
  data() {
    return {
      mapLoading: false,
      drilldownLoading: false,
      mapFeedData: [],
      mapCategoryName: {},
      currentPage: 1,
      totalPage: 1,
      totalRecord: 0,
    };
  },
  watch: {
    currentPage: function () {
      this.updateMapDrilldown();
    },
  },
  methods: {
    async updateMapDrilldown(boundArg) {
      let locationArg = {
        location: {
          hasLocationOnly: true,
          latitudeMax: boundArg.latitudeMax,
          latitudeMin: boundArg.latitudeMin,
          longitudeMax: boundArg.longitudeMax,
          longitudeMin: boundArg.longitudeMin,
        },
        paging: {
          recordPerPage: 10,
          page: this.currentPage,
        },
      };
      this.drilldownLoading = true;
      const result = await api.getMapDrilldown(locationArg).catch(() => {
        this.error = true;
      });
      if (result) {
        this.drilldownLoading = false;
        this.mapFeedData = result.message.data;
        this.mapCategoryName = result.message.subCategoryName;
        this.currentPage = result.message.currentPage;
        this.totalPage = result.message.totalPage;
        this.totalRecord = result.message.totalRecord;
      }
    },
    mapChange(boundArg) {
      this.updateMapDrilldown(boundArg);
    },
  },
};
</script>
<style lang="scss">
.location {
  height: 100%;
  .location-loading-overlay {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    width: 100%;
    height: 100%;
    .v-overlay__scrim {
      background-color: #fafafa !important;
    }
    .v-overlay__content {
      width: 100%;
      height: 100%;
      .location-loading-circle {
        color: var(--primary);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .location-content-row {
    height: 100%;
    .location-map {
      flex: 100%;
      @media (min-width: 960px) {
        flex: 70%;
      }
    }
    .location-message {
      height: calc(100vh - 64px);
      overflow: auto;
      display: none;
      @media (min-width: 600px) {
        display: block;
        flex: 30%;
      }
      .message-top-button {
        margin: 0;
        .button-wrapper {
          text-align: center;
          padding-top: 20px;
          padding-bottom: 10px;
        }
      }
      .message-content {
        height: calc(100vh - 131px);
        .feed-content {
          .feed-col.col {
            padding-bottom: 0;
          }
          .feed-col.pl-0.col {
            padding-left: 12px !important;
            padding-top: 0;
          }
          .post-text {
            color: #555555;
          }
          .sentiment-wrap {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
