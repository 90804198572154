<template>
  <v-navigation-drawer v-model="openState" right app temporary width="350">
    <div class="sidebar-wrapper">
      <div class="sidebar-title">Filters</div>
      <div class="sidebar-content">
        <v-list class="filter-list" dense>
          <v-list-item class="title-row">
            <v-list-item-title>
              <v-icon>mdi-vector-intersection</v-icon> General
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-layout column align-start>
              <v-select
                v-model="filterData.sentiment"
                :items="selectOptions.sentimentItem"
                class="w-100"
                label="Sentiment"
                multiple
                chips
                outlined
                small-chips
                deletable-chips
                hide-details="auto"
                :error-messages="
                  invalidSentiment
                    ? ['At least one sentiment must be selected.']
                    : []
                "
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleAll('sentiment')">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          filterData.sentiment &&
                          filterData.sentiment.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                      >
                        {{ isAllSelected('sentiment') }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="filterData.source"
              :items="sourceList"
              class="w-100"
              label="Source"
              multiple
              chips
              outlined
              small-chips
              hide-details="auto"
              :error-messages="
                invalidSource ? ['At least one source must be selected.'] : []
              "
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="grey--text caption">
                  {{ filterData.source.length }} selected
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleAll('source')">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        filterData.source && filterData.source.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                    >
                      {{ isAllSelected('source') }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-list-item>
          <v-list-item>
            <CategoryTreeSelect
              id="filter-category"
              :criteria="filterData"
              @update="onCategoryChange"
            />
            <!-- TODO add error on no select -->
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="title-row">
            <v-list-item-title>
              <v-icon>mdi-text-search</v-icon> More search
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-text-field
              v-model="filterData.keywordPhrase"
              label="Search Phrase"
              hide-details="auto"
              dense
              outlined
            />
          </v-list-item> -->
          <v-list-item>
            <v-text-field
              v-model="filterData.excludeKeywordPhrase"
              label="Exclude Phrase"
              hide-details="auto"
              dense
              outlined
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="filterData.username"
              label="Username"
              hide-details="auto"
              dense
              outlined
            />
          </v-list-item>
          <v-list-item class="pt-1 hide-dropdown-icon">
            <v-combobox
              v-model="filterData.hashtag"
              dense
              label="Hashtags"
              hide-details="auto"
              :disable-lookup="true"
              @input="onHashChange"
              multiple
              outlined
              chips
              small-chips
              deletable-chips
            >
              <template v-slot:item="{ item }">
                {{ item[0] !== '#' ? `#${item}` : item }} x
              </template>
              x
            </v-combobox>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="title-row">
            <v-list-item-title>
              <v-icon>mdi-calendar</v-icon> Date
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="selectedDateMode"
              @change="onChangeDateMode"
              :items="dateModeList"
              class="w-100"
              label="From period"
              dense
              outlined
              hide-details
            >
            </v-select>
          </v-list-item>
          <v-list-item v-if="selectedDateMode === 'custom'">
            <v-menu
              v-model="dateMenuOpen"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedCustomDate"
                  label="Pick date range"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="customDateInput"
                :min="minDate"
                :max="maxDate"
                range
              >
                <v-spacer></v-spacer>
                <v-btn text color="'#4c7994'" @click="dateMenuOpen = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="setCustomDate"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-list-item>
          <template v-if="selectedDateMode === 'epoch' && filterData.time">
            <v-list-item>
              <v-text-field
                v-model="filterData.time.sinceDate"
                label="Since"
                hide-details="auto"
                dense
                outlined
              />
            </v-list-item>
            <v-list-item>
              <v-text-field
                v-model="filterData.time.untilDate"
                label="Until"
                hide-details="auto"
                dense
                outlined
              />
            </v-list-item>
          </template>
          <template v-if="filterData && filterData.sort">
            <v-divider></v-divider>
            <v-list-item class="title-row">
              <v-list-item-title>
                <v-icon>mdi-sort</v-icon> Sort
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-select
                v-model="filterData.sort.field"
                :items="selectOptions.sortFieldList"
                class="w-100"
                label="Using"
                dense
                outlined
                hide-details
              ></v-select>
            </v-list-item>
            <v-list-item>
              <v-select
                v-model="filterData.sort.direction"
                :items="selectOptions.sortDirectionList"
                class="w-100"
                label="Direction"
                dense
                outlined
                hide-details
              ></v-select>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item class="title-row">
            <v-list-item-title>
              <v-icon>mdi-eye</v-icon> Display
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-layout class="bordered-field" align-center justify-space-between>
              <span class="pl-3 text-body">Origin Only</span>
              <v-switch
                v-model="filterData.originOnly"
                class="ma-0"
                hide-details="auto"
                dense
                inset
              ></v-switch>
            </v-layout>
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="filterData.edited"
              :items="selectOptions.editedModeList"
              class="w-100"
              label="Message by reviewed status"
              dense
              outlined
              hide-details
            ></v-select>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="title-row">
            <v-list-item-title>
              <v-icon>mdi-cog</v-icon> Advance
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="hide-dropdown-icon">
            <v-combobox
              v-model="filterData.neutralizeUsername"
              dense
              label="Neutralize by username"
              placeholder="Enter username and press TAB"
              hide-details="auto"
              :disable-lookup="true"
              multiple
              outlined
              chips
              small-chips
              deletable-chips
            >
            </v-combobox>
          </v-list-item>
        </v-list>
      </div>
      <div class="sidebar-footer">
        <v-btn text dark @click="resetFilter"> Reset </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="success"
          dark
          @click="applyFilter"
          :disabled="invalidFilter"
        >
          <v-icon>mdi-check</v-icon> Apply
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue';
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapState } from 'vuex';

import helper from '@/services/helper';
import selectOptions from '@/services/select-options';
import CategoryTreeSelect from './CategoryTreeSelect.vue';

export default {
  name: 'FilterSidebar',
  components: {
    CategoryTreeSelect,
  },
  data() {
    return {
      selectOptions: [],
      filterData: {},
      selectedTreeCategory: [],
      localDirtyCategory: false,
      selectedDateMode: 'default',
      customDateInput: null,
      dateMenuOpen: false,
      dateModeList: [],
      minDate: null,
      maxDate: null,
      storedMonth: 25,
    };
  },
  created() {
    // If userRole is domadmin
    this.selectOptions = selectOptions;
    this.dateModeList = [
      { value: 'default', text: `Default (${this.queryLength} days)` },
      ...this.selectOptions.dateModeList,
    ];
    if (this.userRole === 'domadmin') {
      // enable epoch input
      this.dateModeList.push({ value: 'epoch', text: 'Epoch' });
    }
    this.maxDate = dayjs().format('YYYY-MM-DD');
    this.minDate = dayjs()
      .subtract(this.storedMonth, 'months')
      .format('YYYY-MM-DD');
  },
  mounted() {
    this.setLocalValue(null);
  },
  methods: {
    ...mapActions({
      setFilterCriteria: 'filter/setFilterCriteria',
      resetFilterCriteria: 'filter/resetFilterCriteria',
      setDirtyCategory: 'filter/setDirtyCategory',
      setFilterOpen: 'filter/setFilterOpen',
    }),
    setLocalValue(d) {
      console.log('INIT local', d);
      if (d && d.category) {
        const ft = JSON.parse(JSON.stringify(this.filterCriteria));
        this.filterData = ft;
      }
    },
    toggleAll(type) {
      const current = this.filterData[type] || [];
      let target;
      let val = [];
      if (type === 'sentiment') {
        target = this.selectOptions.sentimentItem;
      } else if (type === 'source') {
        target = this.sourceList;
      }
      const l = current.length;
      if (l >= 0 && l !== target.length) {
        val = target.map((x) => x.value);
      }
      Vue.set(this.filterData, type, val);
    },
    isAllSelected(type) {
      const current = this.filterData[type];
      let target;
      if (type === 'sentiment') {
        target = this.selectOptions.sentimentItem;
      } else if (type === 'source') {
        target = this.sourceList;
      }
      if (current && target) {
        const l = current.length;
        if (l === target.length) {
          return 'mdi-close-box';
        }
        if (l > 0) {
          return 'mdi-minus-box';
        }
      }
      return 'mdi-checkbox-blank-outline';
    },
    onCategoryChange({ category, subCategory }) {
      // console.log(category, subCategory);
      Vue.set(this.filterData, 'category', category);
      Vue.set(this.filterData, 'subCategory', subCategory);
      this.localDirtyCategory = true;
    },
    onChangeDateMode(mode) {
      Vue.set(this.filterData, 'time', helper.getDateByMode(mode));
    },
    setCustomDate() {
      if (this.customDateInput.length === 2) {
        const a = dayjs(this.customDateInput[0]);
        const b = dayjs(this.customDateInput[1]);
        const diff = a.diff(b);
        const time = {
          sinceDate: '',
          untilDate: '',
        };
        if (diff > 0) {
          // a is more recent
          time.sinceDate = b.startOf('day').valueOf();
          time.untilDate = a.endOf('day').valueOf();
        } else {
          // a is less or equal
          time.sinceDate = a.startOf('day').valueOf();
          time.untilDate = b.endOf('day').valueOf();
        }
        Vue.set(this.filterData, 'time', time);
        this.dateMenuOpen = false;
      }
    },
    onHashChange() {
      const old = this.filterData.hashtag;
      Vue.set(
        this.filterData,
        'hashtag',
        old.map((x) => {
          if (x[0] !== '#') return '#' + x;
          return x;
        })
      );
    },
    applyFilter() {
      // TODO recheck before convert to filterCriteria
      this.setFilterCriteria(this.filterData);
      if (this.localDirtyCategory) {
        this.setDirtyCategory(true);
      }
      // close
      this.setFilterOpen(false);
    },
    resetFilter() {
      this.selectedDateMode = 'default';
      this.customDateInput = null;
      this.dateMenuOpen = false;
      this.localDirtyCategory = false;
      this.resetFilterCriteria();
      // close
      this.setFilterOpen(false);
    },
    onFilterToggle() {
      if (this.filterOpen) {
        // Open
        this.setLocalValue(this.filterCriteria);
        this.localDirtyCategory = this.dirtyCategory;
      } else {
        // Close
        this.localDirtyCategory = false;
        this.dateMenuOpen = false;
      }
    },
  },
  watch: {
    filterCriteria: {
      handler: 'setLocalValue',
      immediate: true,
    },
    filterOpen: 'onFilterToggle',
  },
  computed: {
    ...mapGetters({
      sourceList: 'account/sortedSourceList',
      userRole: 'account/userRole',
      queryLength: 'account/queryLength',
      filterCriteria: 'filter/filterCriteria',
      filterEnableConfig: 'filter/filterEnableConfig',
    }),
    ...mapState({
      filterOpen: (state) => state.filter.filterOpen,
      dirtyCategory: (state) => state.filter.dirtyCategory,
    }),
    openState: {
      get: function () {
        return this.filterOpen;
      },
      set: function (val) {
        this.$store.commit('filter/setFilterOpen', val);
      },
    },
    formattedCustomDate() {
      // using since and until
      if (this.filterData && this.filterData.time) {
        const { sinceDate, untilDate } = this.filterData.time;
        const since = dayjs(sinceDate).format('DD/MM/YYYY');
        const until = dayjs(untilDate).format('DD/MM/YYYY');
        return `${since} - ${until}`;
      }
      return '';
    },
    invalidSentiment() {
      if (this.filterData) {
        const { sentiment } = this.filterData;
        if (!sentiment || sentiment.length === 0) return true;
      }
      return false;
    },
    invalidSource() {
      if (this.filterData) {
        const { source } = this.filterData;
        if (!source || source.length === 0) return true;
      }
      return false;
    },
    invalidCategory() {
      if (this.filterData) {
        const { category, subCategory } = this.filterData;
        // console.log(category, subCategory);

        let isInvalid = false;
        if (!category || category.length === 0) isInvalid = true;
        if (!subCategory) isInvalid = true;
        else {
          for (let c of subCategory) {
            if (c.category && c.category.length > 0) {
              isInvalid = false;
              break;
            }
          }
        }
        return isInvalid;
      }
      return false;
    },
    invalidFilter() {
      // Invalid filter on no source, no sentiment, not category selected
      return (
        this.invalidSentiment || this.invalidSource || this.invalidCategory
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .sidebar-title,
  .sidebar-footer {
    background-color: #4c7994;
    color: #fff;
    padding: 0.8rem 1rem;
  }
  .sidebar-content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sidebar-footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bordered-field {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    padding: 0.4rem 0;
  }
  .filter-list {
    .v-list-item {
      margin-bottom: 12px;
    }
    .title-row {
      margin-bottom: 4px;
    }
    .v-divider {
      margin: 18px 0 12px 0;
    }
  }
}
</style>
