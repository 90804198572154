<template>
  <v-col
    class="overview-value-block"
    cols="6"
    sm="6"
    md="3"
    @click="$emit('click')"
  >
    <div class="date">
      <slot name="date"></slot>
    </div>
    <div class="value">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ countUpVal | numeral('0.00a', true) }}
          </span>
        </template>
        {{ summaryData.count | numeral('0,0') }}
        {{ engagementUnit }}
      </v-tooltip>
    </div>
    <template v-if="summaryData && summaryData.changeVersus">
      <div class="changes" :class="isPNZ(summaryData.changesCount)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span class="mr-1">
                <v-icon class="mt-n1" small v-if="summaryData.changesCount > 0">
                  fa-caret-up
                </v-icon>
                <v-icon class="mt-n1" v-if="summaryData.changesCount < 0">
                  fa-caret-down
                </v-icon>
              </span>
              <span>
                {{ summaryData.changesCount | numeral('0.00a') }}
              </span>
              <span>
                ({{ (summaryData.changesPercent * 100) | numeral('0.00') }}%)
              </span>
            </span>
          </template>
          {{ summaryData.changesCount | numeral('0,0') }}
          {{ engagementUnit }}
        </v-tooltip>
      </div>
      <div class="vsdate">
        <slot name="vs"></slot>
      </div>
    </template>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HomeOverviewValue',
  props: {
    countUpVal: Number,
    summaryData: Object,
  },
  methods: {
    isPNZ(value) {
      // Positive Negative Zero LOLLL
      if (value > 0) return 'is-positive-dark';
      else if (value < 0) return 'is-negative-dark';
      else return 'is-zero';
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
    engagementUnit() {
      return this.isEngagementMode ? 'Engagements' : 'Messages';
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-value-block {
  color: #fff;
  padding: 10px 15px;
  .date {
    font-size: 13px;
  }
  .value {
    font-size: 32px;
    line-height: 32px;
    &:hover {
      cursor: pointer;
    }
  }
  .changes {
    font-size: 12px;
    font-weight: 500;
  }
  .vsdate {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
  }
}
</style>
