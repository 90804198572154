<template>
  <div
    class="flex-img"
    :class="{
      'adv-img-wrapper': !isBigImage,
    }"
  >
    <div class="img-block" ref="imgwrap">
      <img
        :src="img"
        class="post-img"
        alt="Post image"
        @error="setDefaultImage"
        @load="onImageLoad"
      />
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/default-profile.jpg';

export default {
  name: 'FlexImage',
  props: {
    img: String,
  },
  data() {
    return {
      isBigImage: true,
    };
  },
  methods: {
    setDefaultImage(e) {
      e.target.src = defaultImage;
    },
    onImageLoad() {
      if (this.$refs.imgwrap) {
        const h = this.$refs.imgwrap.clientHeight;
        if (h <= 300) {
          this.isBigImage = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-img {
  margin: 0 -1rem;
  .post-img {
    min-width: 100%;
    max-width: 100%;
  }
  &.adv-img-wrapper {
    position: relative;
    text-align: center;
    overflow: hidden;
    background-color: #666;
    border-radius: 2px;
    min-height: 300px;
    .img-block {
      top: 0;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .post-img {
        background-color: #fafafa;
        min-width: 0;
        max-height: 80%;
        width: auto;
        box-shadow: 0 0 10px #333;
      }
    }
  }
}
</style>
