<template>
  <div id="domLocationMap"></div>
</template>

<script>
import api from '@/services/api.js';
import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import positivePin from '@/assets/map-icon/positivePin.png';
import negativePin from '@/assets/map-icon/negativePin.png';
import neutralPin from '@/assets/map-icon/neutralPin.png';

import angryIcon from '@/assets/sentiment/angry.png';
import commentIcon from '@/assets/sentiment/comment.png';
import dislikeIcon from '@/assets/sentiment/dislike.png';
import favoriteIcon from '@/assets/sentiment/favorite.png';
import hahaIcon from '@/assets/sentiment/haha.png';
import likeIcon from '@/assets/sentiment/like.png';
import loveIcon from '@/assets/sentiment/love.png';
import retweetIcon from '@/assets/sentiment/retweet.png';
import sadIcon from '@/assets/sentiment/sad.png';
import shareIcon from '@/assets/sentiment/share.png';
import viewIcon from '@/assets/sentiment/view.png';
import wowIcon from '@/assets/sentiment/wow.png';

export default {
  name: 'GoogleMap',
  components: {
    // GmapCluster,
  },
  data() {
    return {
      map: {},
      google: {},
      clusterer: {},
      spider: {},
      infoWindow: {},
      mapCenter: { lat: 13.753234, lng: 100.500033 },
      mapStyles: [
        {
          featureType: 'all',
          elementType: 'geometry.fill',
          stylers: [{ weight: '2.00' }],
        },
        {
          featureType: 'all',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#9c9c9c' }],
        },
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [{ color: '#f2f2f2' }],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ color: '#eeeeee' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#7b7b7b' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#46bcec' }, { visibility: 'on' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ color: '#c8d7d4' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#070707' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }],
        },
      ],
      mapMarkers: [],
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const loader = new Loader({
        apiKey: 'AIzaSyBWJ7W4tI-jLxQLHMK8lJHxhpQqWm2uWN8',
        version: 'weekly',
        libraries: ['places'],
      });
      const mapOptions = {
        center: this.mapCenter,
        minZoom: 6,
        maxZoom: 17,
        zoom: 11,
        styles: this.mapStyles,
      };
      loader
        .load()
        .then((google) => {
          this.google = google;
          this.map = new google.maps.Map(
            document.getElementById('domLocationMap'),
            mapOptions
          );
          google.maps.event.addListenerOnce(this.map, 'idle', () => {
            var OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
            this.spider = new OverlappingMarkerSpiderfier(this.map, {
              nudgeStackedMarkers: false,
              nearbyDistance: 40,
            });
            this.setMarker();
          });
          this.map.addListener('dragend', () => {
            this.resetMarker();
          });
          this.map.addListener('zoom_changed', () => {
            this.resetMarker();
          });
          this.infoWindow = new google.maps.InfoWindow();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async setMarker() {
      let mapBound = this.map.getBounds();
      let ne = mapBound.getNorthEast();
      let sw = mapBound.getSouthWest();
      let bound = {};
      bound.latitudeMax = ne.lat();
      bound.latitudeMin = sw.lat();
      bound.longitudeMax = ne.lng();
      bound.longitudeMin = sw.lng();
      this.$emit('mapChange', bound);

      let locationArg = {
        location: {
          hasLocationOnly: true,
          latitudeMax: bound.latitudeMax,
          latitudeMin: bound.latitudeMin,
          longitudeMax: bound.longitudeMax,
          longitudeMin: bound.longitudeMin,
        },
        paging: {
          recordPerPage: 1000,
        },
      };
      const result = await api.getLocation(locationArg).catch(() => {
        this.error = true;
      });
      if (result) {
        let data = result.message.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].analytic.sentiment_score > 1) {
            data[i].markerSrc = positivePin;
          } else if (data[i].analytic.sentiment_score < -1) {
            data[i].markerSrc = negativePin;
          } else {
            data[i].markerSrc = neutralPin;
          }
          let marker = new this.google.maps.Marker({
            position: new this.google.maps.LatLng(
              data[i].raw_data.location.latitude,
              data[i].raw_data.location.longitude
            ),
            map: this.map,
            icon: data[i].markerSrc,
            id: data[i].info.id,
          });
          marker.addListener('click', () => {
            this.getDetails(data[i].info.id, marker);
          });
          this.spider.addMarker(marker);
          this.mapMarkers.push(marker);
        }
        this.setClusterer();
      }
    },
    setClusterer() {
      let clustererArg = {
        // CHANGE IMG PATH
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        maxZoom: 15,
        gridSize: 60,
        minimumClusterSize: 10,
        zoomOnClick: true,
      };
      this.clusterer = new MarkerClusterer(
        this.map,
        this.mapMarkers,
        clustererArg
      );
    },
    async getDetails(id, marker) {
      const result = await api.getFeedbyID(id).catch(() => {
        this.error = true;
      });
      if (result) {
        if (result.message.user && result.message.user.photo) {
          // COMMON FACTORY ???
          var largeImageUrl =
            'https://cdn.ask-dom.com/img/user-force/' +
            result.message.info.source +
            '/' +
            result.message.user.photo;
        }
        let sentimentDiv = '';
        if (result.message.analytic.sentiment_score > 1) {
          sentimentDiv =
            '<button type="button" class="sentiment-wrap v-btn v-btn--fab v-btn--icon v-btn--round theme--dark elevation-0 v-size--small sentiment-positive-bg"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate fa fa-smile-o theme--dark"></i></span></button>';
        } else if (result.message.analytic.sentiment_score >= -1) {
          sentimentDiv =
            '<button type="button" class="sentiment-wrap v-btn v-btn--fab v-btn--icon v-btn--round theme--dark elevation-0 v-size--small sentiment-neutral-bg"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate fa fa-meh-o theme--dark"></i></span></button>';
        } else if (result.message.analytic.sentiment_score < -1) {
          sentimentDiv =
            '<button type="button" class="sentiment-wrap v-btn v-btn--fab v-btn--icon v-btn--round theme--dark elevation-0 v-size--small sentiment-negative-bg"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate fa fa-frown-o theme--dark"></i></span></button>';
        }

        let iconDiv = '';
        if ('retweet_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            retweetIcon +
            '" >' +
            result.message.summary.retweet_count +
            '</div>';
        }
        if ('like_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            likeIcon +
            '" >' +
            result.message.summary.like_count +
            '</div>';
        }
        if ('dislike_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            dislikeIcon +
            '" >' +
            result.message.summary.dislike_count +
            '</div>';
        }
        if ('comment_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            commentIcon +
            '" >' +
            result.message.summary.comment_count +
            '</div>';
        }
        if ('share_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            shareIcon +
            '" >' +
            result.message.summary.share_count +
            '</div>';
        }
        if ('angry_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            angryIcon +
            '" >' +
            result.message.summary.angry_count +
            '</div>';
        }
        if ('favourite_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            favoriteIcon +
            '" >' +
            result.message.summary.favourite_count +
            '</div>';
        }
        if ('love_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            loveIcon +
            '" >' +
            result.message.summary.love_count +
            '</div>';
        }
        if ('sad_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            sadIcon +
            '" >' +
            result.message.summary.sad_count +
            '</div>';
        }
        if ('wow_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            wowIcon +
            '" >' +
            result.message.summary.wow_count +
            '</div>';
        }
        if ('haha_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            hahaIcon +
            '" >' +
            result.message.summary.haha_count +
            '</div>';
        }
        if ('view_count' in result.message.summary) {
          iconDiv +=
            '<div><img src="' +
            viewIcon +
            '" >' +
            result.message.summary.view_count +
            '</div>';
        }

        let originDiv = '';
        if (result.message.info.is_origin) {
          originDiv = '(ORIGIN)';
        } else {
          if (result.message.info.source == 'twitter') {
            originDiv = '(RETWEET)';
          } else {
            originDiv = '(COMMENT)';
          }
        }

        let contentString =
          '<div class="info-content">' +
          '<div class="card-head">' +
          '<div class="user-avatar">' +
          '<img src="' +
          largeImageUrl +
          '">' +
          '</div>' +
          '<div class="user-name">' +
          '<div class="user-title">' +
          '<i class="v-icon fa fa-' +
          result.message.info.source +
          ' theme--light"></i>' +
          result.message.user.username +
          '</div>' +
          '<div class="user-subhead">10 days ago</div>' +
          '</div>' +
          '<div class="sentiment-avatar">' +
          sentimentDiv +
          '</div>' +
          '</div>' +
          '<div class="card-body">' +
          '<div class="body-content">' +
          result.message.raw_data.text +
          '</div>' +
          '<div class="body-icon">' +
          iconDiv +
          '</div>' +
          '<div class="body-origin">' +
          originDiv +
          '</div>' +
          '</div>' +
          '</div>';

        this.infoWindow.setContent(contentString);
        this.infoWindow.open(this.map, marker);
      }
    },
    clearMarkers() {
      for (var i = 0; i < this.mapMarkers.length; i++) {
        this.mapMarkers[i].setMap(null);
      }
      this.mapMarkers.length = 0;
      this.mapMarkers = [];
    },
    resetMarker() {
      this.clearMarkers();
      this.spider.clearMarkers();
      this.clusterer.clearMarkers();

      this.setMarker();
    },
  },
};
</script>

<style lang="scss">
#domLocationMap {
  height: 100%;
  .info-content {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    .card-head {
      display: flex;
      align-items: center;
      padding: 16px;
      .user-avatar {
        margin-right: 12px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .user-name {
        display: flex;
        flex-direction: column;
        flex: 1;
        .user-title {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.87);
          i {
            margin-right: 5px;
            color: rgb(64, 153, 255);
            width: 18px;
            height: 18px;
          }
        }
        .user-subhead {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .card-body {
      padding: 16px;
      .body-content {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 24px;
      }
      .body-icon {
        height: 35px;
        display: flex;
        justify-content: space-around;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .body-origin {
        height: 35px;
        line-height: 35px;
        font-size: 14px;
      }
    }
  }
}
</style>
