<template>
  <v-app>
    <AppLayout>
      <router-view />
    </AppLayout>
    <DrilldownDialog />
    <SeeMoreDialog />
    <FilterSidebar />
  </v-app>
</template>
<script>
import DrilldownDialog from './components/Dialog/DrilldownDialog.vue';
import SeeMoreDialog from './components/Dialog/SeeMoreDialog.vue';
import FilterSidebar from './components/Filter/FilterSidebar.vue';

export default {
  name: 'App',
  components: {
    DrilldownDialog,
    SeeMoreDialog,
    FilterSidebar,
  },
};
</script>
<style lang="scss">
:root {
  --primary: #37526f;
  --secondary: #69a3c5;
  --positive-sentiment: #55acdd;
  --neutral-sentiment: #fec85e;
  --negative-sentiment: #ff124f;
  --negative-dark: #ff6464;
  --positive-dark: #40ff46;
  --negative-light: #ff0000;
  --positive-light: #2bac2f;
}

.v-application {
  font-family: 'Roboto', 'Sarabun', sans-serif !important;
}

#app {
  background-color: #fafafa;
}

.no-underline-input {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: transparent;
  }
  .theme--light.v-text-field:not(.v-input--has-state):hover
    > .v-input__control
    > .v-input__slot:before {
    border-color: var(--primary);
  }
}

.smaller-select {
  .v-select__selection {
    font-size: 12px;
  }
}
.widget-date-wrapper {
  flex: 0 0 230px;
  .widget-date-selector {
    .v-text-field__details {
      display: none !important;
    }
    .v-select__selection {
      font-size: 12px;
      color: var(--primary);
    }
  }
}

.is-positive * {
  color: var(--positive-light) !important;
}
.is-positive-dark * {
  color: var(--positive-dark) !important;
}
.is-negative * {
  color: var(--negative-light) !important;
}
.is-negative-dark * {
  color: var(--negative-dark) !important;
}
.is-new {
  color: #6e84ff;
}
.text-error {
  color: red;
}

.sentiment-positive-bg {
  background-color: var(--positive-sentiment) !important;
}
.sentiment-negative-bg {
  background-color: var(--negative-sentiment) !important;
}
.sentiment-neutral-bg {
  background-color: var(--neutral-sentiment) !important;
}

.text-break-all {
  -ms-word-break: break-all;
  word-break: break-all;
  // Non standard for webkit
  // word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.text-break-word {
  word-break: break-word;
}

.w-100 {
  width: 100%;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 24px !important;
}

.nav-list {
  .v-icon {
    font-size: 18px;
  }
}
.group-item-override {
  .v-list-group__items .v-list-item {
    padding-left: 45px;
  }
  .v-list-group__header.v-list-item--active .v-list-item__title {
    color: #333 !important;
  }
  .v-list-item__icon.v-list-group__header__prepend-icon,
  .v-list-item__icon.v-list-group__header__append-icon {
    .v-icon {
      color: #333 !important;
    }
  }
}
.v-navigation-drawer--mini-variant {
  .group-item-override {
    .v-list-group__items .v-list-item {
      padding-left: 15px;
    }
  }
}
.message-quota {
  background-color: #677f98 !important;
  .quota-text {
    color: #fff !important;
  }
}

// override

.expansion-no-wrap .v-expansion-panel-content__wrap {
  padding: 0;
}

.hide-dropdown-icon {
  .v-input__append-inner {
    display: none;
  }
}

// Head row for settings
.head-row,
.user-dialog .head-row {
  margin: 0;
  background-color: rgb(250, 250, 250);
  .title-text {
    line-height: 24px;
    padding: 8px 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.pie-tooltip {
  z-index: 100;
}
.pie-label {
  z-index: 1;
}
</style>
