<template>
  <v-card elevation="3" class="fanpage-table">
    <!-- input add -->

    <div class="add-input-wrapper">
      <div class="add-input mt-3">
        <v-text-field
          label="Add Fanpage..."
          hide-details="auto"
          :rules="rules"
          v-model="toAddFanpage"
          @focus="showSelect = true"
          dense
          outlined
        >
        </v-text-field>
        <v-select
          v-if="showSelect"
          :items="selectItems"
          label="Consider Keyword"
          v-model="considerKeyword"
          dense
          outlined
          item-text="text"
          item-value="value"
        >
        </v-select>
        <div class="add-btn-wrapper" v-if="showButton">
          <div class="add-btn">
            <v-btn color="success" @click="addFanpage()"> Add </v-btn>
            <v-btn @click="cancelAddFanpage()"> Cancel </v-btn>
          </div>
        </div>
      </div>
    </div>

    <TableBase
      :headers="headers"
      :items="fanpages"
      title="Fanpage"
      :loading="loading"
      canEdit
      @edit="updateFanpage"
      @delete="deleteFanpage"
    />

    <!-- dialog delete -->
    <ConfirmDialog
      :title="dialogDeleteTitle"
      :open="dialogDelete"
      @close="confirmDeleteFanpage"
    />

    <!-- dialog update -->
    <v-dialog
      content-class="update-dialog"
      v-model="dialogUpdate"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ dialogUpdateTitle }}
        </v-card-title>
        <v-select
          :items="selectItems"
          label="Consider Keyword"
          v-model="considerKeywordUpdate"
          item-text="text"
          item-value="value"
        ></v-select>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="confirmUpdateFanpage()">
            Save
          </v-btn>
          <v-btn color="grey darken-1" text @click="closeUpdateFanpage()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import api from '@/services/api.js';
import dayjs from 'dayjs';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';
import TableBase from '@/components/Table/TableBase.vue';

export default {
  name: 'FanpageTable',
  components: {
    ConfirmDialog,
    TableBase,
  },
  data() {
    return {
      addDone: false,
      showButton: false,
      showSelect: false,
      considerKeyword: true,
      selectItems: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      rules: [
        (value) => {
          if (value == null || value.length == 0) {
            this.showButton = false;
            return true;
          } else {
            if (!/\S/.test(value)) {
              this.showButton = false;
              return 'Invalid fanpage.';
            } else {
              this.showButton = true;
              return true;
            }
          }
        },
      ],
      toAddFanpage: null,
      toDeletedFanpage: null,
      fanpages: [],
      search: '',
      loading: true,
      dialogDelete: false,
      dialogDeleteTitle: '',
      dialogUpdate: false,
      dialogUpdateTitle: '',
      toUpdateFanpage: null,
      considerKeywordUpdate: true,
    };
  },
  async mounted() {
    await this.getFanpages();
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        {
          text: 'Fanpage',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Fanpage Name',
          align: 'start',
          sortable: true,
          value: 'fanpageName',
        },
        {
          text: 'Consider Keyword',
          align: 'start',
          sortable: true,
          filterable: false,
          value: 'isConsiderKeyword',
        },
        {
          text: 'Updated At',
          sortable: true,
          filterable: false,
          value: 'updatedAt',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          filterable: false,
          value: 'action',
        },
      ];
    },
  },
  methods: {
    ...mapState({
      activeToast(state) {
        state.toast.active = true;
      },
      errorToast(state) {
        state.toast.error = true;
      },
    }),
    getDate(timestamp) {
      var createdAt = new Date(timestamp);
      return dayjs(createdAt).fromNow();
    },
    deleteFanpage(fanpage) {
      this.dialogDelete = true;
      this.toDeletedFanpage = fanpage;
      this.dialogDeleteTitle = `Would you like to delete fanapge "${fanpage.fanpageName}"?`;
    },
    async confirmDeleteFanpage(state) {
      if (state) {
        await api
          .deleteAccountFanpage(this.toDeletedFanpage.id)
          .catch((error) => {
            console.log(error);
            this.errorToast();
          });
        await this.getFanpages();
        this.activeToast();
      }
      this.toDeletedFanpage = null;
      this.dialogDelete = false;
    },
    async getFanpages() {
      var result = {};
      result = await api.getAccountFanpage().catch((error) => {
        console.log(error);
      });
      this.fanpages = result.message;
      this.loading = false;
    },
    async addFanpage() {
      if (this.toAddFanpage && this.toAddFanpage.length > 0) {
        await api
          .addAccountFanpage({
            id: this.toAddFanpage,
            fanpageName: this.toAddFanpage,
            isConsiderKeyword: this.considerKeyword,
            value: 3,
          })
          .catch((error) => {
            console.log(error);
            this.errorToast();
          });
        this.toAddFanpage = null;
        this.showSelect = false;
        this.addDone = true;
        this.getFanpages();
        this.activeToast();
      }
    },
    cancelAddFanpage() {
      this.toAddFanpage = null;
      this.showSelect = false;
    },
    updateFanpage(fanpage) {
      this.dialogUpdate = true;
      this.dialogUpdateTitle = `Edit ${fanpage.fanpageName}`;
      this.toUpdateFanpage = fanpage;
    },
    async confirmUpdateFanpage() {
      this.toUpdateFanpage.isConsiderKeyword = this.considerKeywordUpdate;
      await api.updateAccountFanpage(this.toUpdateFanpage).catch((error) => {
        console.log(error);
        this.errorToast();
      });
      this.considerKeyword = true;
      this.closeUpdateFanpage();
      this.getFanpages();
      this.activeToast();
    },
    closeUpdateFanpage() {
      this.dialogUpdate = false;
      this.considerKeywordUpdate = true;
      this.toUpdateFanpage = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.fanpage-table {
  padding: 20px 20px 0 20px;
  .fanpage-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  .add-input-wrapper {
    display: flex;
    justify-content: center;
    .add-input {
      width: 500px;
      .v-select {
        margin-top: 30px;
      }
    }
    .add-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      .add-btn button:last-child {
        margin-left: 20px;
      }
    }
  }
}
.update-dialog {
  .v-card__title {
    background-color: #37526f;
    color: #fff;
  }
  .v-select {
    margin: 25px;
  }
}
</style>
