<template>
  <home-base-widget
    class="home-influencertrend-block"
    title="Trending Influencer"
    :selectedPreset="selectedPreset"
    :enableToday="true"
    :hasRowHead="true"
    :error="error"
    :loading="loading"
    @reload="init"
    @changePreset="onChangePreset"
  >
    <template v-slot:row-head>
      <div style="flex: 0 0 20%">Rank</div>
      <div style="flex: 1 1 30%">User</div>
      <div style="flex: 0 0 20%">Source</div>
      <div style="flex: 0 0 30%" class="text-right pr-3">Engagement</div>
    </template>
    <widget-row
      v-for="s in influencerTrendData"
      :key="s.trend"
      :hasMid="true"
      :hasRanking="true"
      :countChange="s.countChanges"
      :rankChange="s.positionChanges"
      :isNewRank="s.positionTrend === 'NEW'"
      :hideChange="hideChange"
      @click="drillDown(s.peopleInfo)"
    >
      <template v-slot:main-rank>
        {{ s.currentPosition }}
      </template>
      <template v-slot:sub-rank>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span>
                {{ s.positionTrend }}
              </span>
              <span v-if="s.positionChanges !== 0">
                {{ s.positionChanges }}
              </span>
            </span>
          </template>
          <span v-if="s.positionTrend !== 'NEW'">
            From position {{ s.previousPosition }}
          </span>
          <span v-else>Newly added</span>
        </v-tooltip>
      </template>
      <template v-slot:left>
        <div class="user-image">
          <img
            class="user-image"
            v-if="s.peopleInfo.source === 'facebook'"
            src="../../assets/default-profile.jpg"
            alt="default image"
          />
          <img
            class="user-image"
            v-else
            :src="getImageUrl(s.peopleInfo)"
            alt="source image"
            @error="setDefaultImage"
          />
        </div>
        <div class="text-truncate mr-2">
          <span class="text-body">
            {{
              s.peopleInfo.user_name | facebookAnonymous(s.peopleInfo.source)
            }}
          </span>
        </div>
      </template>
      <template v-slot:mid>
        <div class="text-truncate">
          <span class="text-body">{{ s.peopleInfo.source | capitalize }}</span>
        </div>
      </template>
      <template v-slot:main-value>
        {{ s.count | numeral('0.00a') }}
      </template>
      <template v-slot:sub-value>
        <div class="text-center" v-if="s.countTrend === 'NEW'">-</div>
        <div v-else>
          <span>
            {{ s.countTrend }}
          </span>
          <span>
            {{ s.countChanges | numeral('+0.0a', true) }}
          </span>
          <span> ({{ (s.changesPercent * 100) | numeral('+0.00a') }}%) </span>
        </div>
      </template>
    </widget-row>
  </home-base-widget>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import defaultImage from '@/assets/default-profile.jpg';

import HomeBaseWidget from './sub/HomeBaseWidget.vue';
import WidgetRow from './sub/WidgetRow.vue';

export default {
  name: 'HomeTrendingInfluencer',
  components: {
    HomeBaseWidget,
    WidgetRow,
  },
  data() {
    return {
      loading: true,
      error: false,
      selectedPreset: 'previous7days',
      influencerTrendData: [],
    };
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      this.loading = true;
      this.error = false;
      const result = await api
        .getHomeInfluencerTrend(this.isEngagementMode, this.selectedPreset)
        .catch(() => {
          this.error = true;
        });
      if (result && result.message && result.message.trends) {
        this.influencerTrendData = result.message.trends;
      }
      this.loading = false;
    },
    onChangePreset(preset) {
      this.selectedPreset = preset;
      this.init();
    },
    getImageUrl(peopleData) {
      const { source, user_photo } = peopleData;
      return `https://cdn.ask-dom.com/img/user-force/${source}/${user_photo}`;
    },
    drillDown(obj) {
      const uname = helper.facebookAnonymous(obj.user_name, obj.source);
      const criteria = {
        payload: {
          title: uname ? `influencer name: ${uname}` : 'an unknown user.',
          preset: this.selectedPreset,
          drilldownType: 'influencer',
        },
        original: {
          userId: obj.id,
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
    setDefaultImage(e) {
      e.target.src = defaultImage;
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
    hideChange() {
      return this.selectedPreset === 'today';
    },
  },
};
</script>

<style lang="scss" scoped>
.home-influencertrend-block {
  grid-row: span 5;
  grid-column: span 1;
  @media screen and (min-width: 960px) {
    grid-column: span 2;
  }
  .user-image {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: inline;
    margin-right: 8px;
  }
}
</style>
