<template>
  <home-base-widget
    class="home-source-traffic"
    title="Total Volume"
    :selectedPreset="selectedPreset"
    :enableToday="true"
    :hasRowHead="true"
    :error="error"
    :loading="loading"
    @reload="init"
    @changePreset="onChangePreset"
  >
    <template v-slot:row-head>
      <div style="flex: 1 1 auto; padding-left: 30px">Source</div>
      <div style="flex: 0 0 40%" class="text-right pr-3">Engagement</div>
    </template>
    <widget-row
      v-for="s in trafficSourceData"
      :key="s.source"
      :countChange="s.changesCount"
      :hideChange="hideChange"
      @click="drillDown(s.source)"
    >
      <template v-slot:left>
        <SourceIcon :source="s.source" />
        <span class="text-body ml-2">{{ s.source | capitalize }}</span>
      </template>
      <template v-slot:main-value>
        {{ (s.proportion * 100) | numeral('0') }}%
      </template>
      <template v-slot:sub-value>
        <span>
          {{ s.changesCount | numeral('+0.0a', true) }}
        </span>
        <span> ({{ (s.changesPercent * 100) | numeral('+0.00a') }}%) </span>
      </template>
    </widget-row>
  </home-base-widget>
</template>

<script>
import HomeBaseWidget from './sub/HomeBaseWidget.vue';
import WidgetRow from './sub/WidgetRow.vue';
import SourceIcon from '../SourceIcon.vue';

import { mapState, mapActions } from 'vuex';
import api from '@/services/api.js';

export default {
  name: 'HomeSourceTraffic',
  components: {
    HomeBaseWidget,
    WidgetRow,
    SourceIcon,
  },
  data() {
    return {
      loading: true,
      error: false,
      selectedPreset: 'previous7days',
      trafficSourceData: [],
    };
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      this.loading = true;
      this.error = false;
      const result = await api
        .getHomeSourceTraffic(this.isEngagementMode, this.selectedPreset)
        .catch(() => {
          this.error = true;
        });
      if (result && result.message && result.message.trafficEntities) {
        this.trafficSourceData = result.message.trafficEntities;
      }
      this.loading = false;
    },
    onChangePreset(preset) {
      this.selectedPreset = preset;
      this.init();
    },
    drillDown(source) {
      const criteria = {
        payload: {
          title: `source "${source}"`,
          preset: this.selectedPreset,
          drilldownType: 'engage',
        },
        original: {
          source: [source],
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
    hideChange() {
      return this.selectedPreset === 'today';
    },
  },
};
</script>

<style lang="scss" scoped>
.home-source-traffic {
  grid-row: span 5;
  grid-column: span 1;
  .sentiment-icon {
    font-size: 28px;
    &.positive {
      color: var(--positive-sentiment) !important;
    }
    &.neutral {
      color: var(--neutral-sentiment) !important;
    }
    &.negative {
      color: var(--negative-sentiment) !important;
    }
  }
}
</style>
