import Vue from 'vue';
import api from '@/services/api';
import helper from '@/services/helper';

const HlStartText = '<strong class="text-highlight">';
const HlEndText = '</strong>';
const HlLength = HlStartText.length + HlEndText.length;

export default {
  namespaced: true,
  state: {
    categoryData: [],
    keywordData: [],
  },
  getters: {
    categoryData: (state) => state.categoryData,
    categoryLevelNameList({ categoryData }) {
      if (categoryData) {
        return categoryData.map((c) => c.name);
      }
      return ['categories'];
    },
    keywordList({ keywordData }) {
      if (keywordData) {
        return keywordData
          .map((k) => k.id)
          .filter((k) => k.length > 0)
          .sort((a, b) => b.length - a.length);
      }
      return [];
    },
    categoryNameList({ categoryData }) {
      if (categoryData && categoryData[0]) {
        return categoryData[0].categories
          .filter((c) => c.visible)
          .map((c) => c.category);
      }
      return [];
    },
    subCategoryList({ categoryData }) {
      if (categoryData && categoryData.length > 1) {
        return categoryData
          .filter((c) => c.level > 0)
          .map((c) => {
            return {
              level: c.level,
              category: c.categories.map((x) => x.category),
            };
          });
      }
      return [];
    },
    categoryTreeData({ categoryData }) {
      const result = {
        tree: [],
        idMap: {},
      };
      if (categoryData && categoryData.length > 0) {
        for (let catLevelObj of categoryData) {
          const { level, categories, name } = catLevelObj;
          const levelData = categories
            .filter((x) => x.visible)
            .map((x) => {
              return {
                id: `${level}::${x.category}`,
                name: x.category,
                category: name,
              };
            });
          result.tree.push({
            id: name,
            name: name,
            children: levelData,
          });
        }
      }
      return result;
    },
  },
  actions: {
    async getAccountCategory({ commit }) {
      const result = await api.getAccountCategory().catch(() => {});
      if (result && result.message && result.message[0]) {
        commit('setCategoryData', result.message);
      }
    },
    async getAccountKeyword({ commit }) {
      const result = await api.getAccountKeyword().catch(() => {});
      if (result && result.message) {
        commit('setKeywordData', result.message);
      }
    },
    highlightKeywords({ getters }, data) {
      const { messageList, truncateAt = 250, forceRun = false } = data;
      const keywordList = getters.keywordList;
      // TODO find more efficient way e.g. merge all text -> find -> split
      for (let i in messageList) {
        const item = messageList[i];
        let targetText;
        let joinTextMode = false;
        // SKIP if already highlight
        // TODO implement force run highlight
        if (item.highlighted_text && !forceRun) {
          continue;
        }
        if (item.analytic && item.analytic.highlighted_text) {
          // Highlight search result this will already in <em> tag
          targetText = item.analytic.highlighted_text.join('|$|');
          joinTextMode = true;
        } else {
          // Else normal case that highlight by retreived keyword list
          targetText = helper.truncate(item.raw_data.text, truncateAt);
        }
        if (targetText && keywordList) {
          let lowCaseText, temp, temp2, temp3, current, keyIndex;
          for (let keyword of keywordList) {
            keyIndex = 0;
            current = 0;
            do {
              // Find keyword from given start
              lowCaseText = targetText.toLowerCase();
              keyIndex = lowCaseText.indexOf(keyword, current);
              if (keyIndex > -1) {
                current = keyIndex + keyword.length;
                temp = targetText.slice(0, keyIndex);
                temp2 = targetText.slice(keyIndex, current);
                temp3 = targetText.slice(current);
                targetText = `${temp}${HlStartText}${temp2}${HlEndText}${temp3}`;
              }
              // Move start position
              current = current += HlLength;
            } while (keyIndex >= 0);
          }
          if (joinTextMode) {
            // Remove joiner from text
            targetText = targetText.replace('|$|', '');
          }
          item.highlighted_text = targetText;
        }
      }
      return messageList;
    },
  },
  mutations: {
    setCategoryData(state, data) {
      Vue.set(state, 'categoryData', data);
    },
    setKeywordData(state, data) {
      Vue.set(state, 'keywordData', data);
    },
  },
};
