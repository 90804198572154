import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';

import vuetify from './plugins/vuetify';

import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import AppLayout from './components/Layout/AppLayout.vue';
Vue.component('AppLayout', AppLayout);

// TODO rewrite this to smth helper.installFilter();
import helper from '@/services/helper';
Vue.filter('numeral', helper.numeral);
Vue.filter('capitalize', helper.capitalize);
Vue.filter('facebookAnonymous', helper.facebookAnonymous);
Vue.filter('truncate', helper.truncate);
Vue.filter('timeFromNow', helper.timeFromNow);
Vue.filter('formatDate', helper.formatDate);

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBWJ7W4tI-jLxQLHMK8lJHxhpQqWm2uWN8',
    libraries: 'places',
  },
});

Vue.config.productionTip = false;

sync(store, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
