var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrapper"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"head-row"},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.title))])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Search","dense":"","hide-details":"auto","prepend-inner-icon":"mdi-magnify","max-width":"260"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSentimentText(item.value))+" ")]}},{key:"item.isConsiderKeyword",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isConsiderKeyword ? 'Yes' : 'No')+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.updatedAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }