<template>
  <div
    class="sentiment-icon"
    :class="{
      'reaction-icon': type == 'Reaction' || type == 'reaction_count',
      'small-reaction-icon': type === 'reaction_small',
    }"
  >
    <img
      src="../assets/sentiment/reaction.png"
      alt=""
      v-if="
        type === 'Reaction' ||
        type === 'reaction_count' ||
        type === 'reaction_small'
      "
    />
    <img
      src="../assets/sentiment/angry.png"
      alt=""
      v-if="type === 'Angry' || type === 'angry_count'"
    />
    <img
      src="../assets/sentiment/comment.png"
      alt=""
      v-if="type === 'Comment' || type === 'comment_count'"
    />
    <img
      src="../assets/sentiment/dislike.png"
      alt=""
      v-if="type === 'Dislike' || type === 'dislike_count'"
    />
    <img
      src="../assets/sentiment/favorite.png"
      alt=""
      v-if="type === 'Favourite' || type === 'favourite_count'"
    />
    <img
      src="../assets/sentiment/haha.png"
      alt=""
      v-if="type === 'Haha' || type === 'haha_count'"
    />
    <img
      src="../assets/sentiment/like.png"
      alt=""
      v-if="type === 'Like' || type === 'like_count'"
    />
    <img
      src="../assets/sentiment/love.png"
      alt=""
      v-if="type === 'Love' || type === 'love_count'"
    />
    <img
      src="../assets/sentiment/retweet.png"
      alt=""
      v-if="type === 'Retweet' || type === 'retweet_count'"
    />
    <img
      src="../assets/sentiment/tweet.png"
      alt=""
      v-if="type === 'Tweet' || type === 'tweet_count'"
    />
    <img
      src="../assets/sentiment/sad.png"
      alt=""
      v-if="type === 'Sad' || type === 'sad_count'"
    />
    <img
      src="../assets/sentiment/share.png"
      alt=""
      v-if="type === 'Share' || type === 'share_count'"
    />
    <img
      src="../assets/sentiment/view.png"
      alt=""
      v-if="type === 'View' || type === 'view_count'"
    />
    <img
      src="../assets/sentiment/wow.png"
      alt=""
      v-if="type === 'Wow' || type === 'wow_count'"
    />
    <img
      src="../assets/sentiment/post.png"
      alt=""
      v-if="type === 'Post' || type === 'post_count'"
    />
  </div>
</template>

<script>
export default {
  name: 'SentimentIcon',
  props: {
    type: String,
  },
};
</script>

<style lang="scss" scoped>
.sentiment-icon {
  position: relative;
  display: inline;
  margin-right: 20px;
  img {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }
  &.small-reaction-icon {
    margin-right: 55px;
    img {
      width: auto;
      height: 32px;
      margin-top: -6px;
      margin-left: -4px;
    }
  }
  &.reaction-icon {
    margin-right: 60px;
    img {
      width: auto;
      height: 32px;
      margin-top: -5px;
    }
  }
}
</style>
