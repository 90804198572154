import numeral from 'numeral';
import dayjs from 'dayjs';

import defaultImage from '@/assets/default-profile.jpg';

function getQuery(inputStr, startStr) {
  const str = inputStr.replace(startStr, '');
  const seg = str.split('&');
  const resultObj = {};
  seg.forEach((s) => {
    const subSplit = s.split('=');
    if (subSplit[0] && subSplit[1]) {
      const { 0: key, 1: value } = subSplit;
      resultObj[key] = value;
    }
  });
  return resultObj;
}

function getCDNImageUrl(source, url) {
  return `https://cdn.ask-dom.com/img/user-force/${source}/${url}`;
}

export default {
  // Filters
  numeral: (value, format = '0,0', skipThousand = false, defaultVal = '') => {
    if (typeof value === 'string' || typeof value === 'number') {
      const v = numeral(value);
      const val = v.value();
      if (skipThousand && val < 1000) {
        return val;
      }
      // Filter out infinity or -infinity and NaN number after parsed
      if (val === Infinity || val === -Infinity || isNaN(val)) {
        return '---';
      }
      return v.format(format);
    }
    return defaultVal;
  },
  capitalize: (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  facebookAnonymous: (value, source) => {
    if (!value) return '';
    if (source === 'facebook') {
      return 'Anonymous';
    }
    return value;
  },
  truncate: (text, count = 250) => {
    if (!text) return '';
    if (text && text.length > count) {
      return `${text.substring(0, count)}...`;
    }
    return text;
  },
  timeFromNow: (str) => {
    return dayjs(str).fromNow();
  },
  formatDate: (str, format = '') => {
    return dayjs(str).format(format);
  },
  // Others
  getSearchQuery: () => getQuery(window.location.search, '?'),
  normalizeKey: (key) => {
    if (key.indexOf('_') > -1) {
      let splitted = key.split('_');
      if (splitted[0]) {
        splitted[0] =
          splitted[0].charAt(0).toUpperCase() + splitted[0].slice(1);
        return splitted[0];
      }
    }
    return key;
  },
  getDateByMode: (mode) => {
    let timeObj = {};
    switch (mode) {
      case 'default':
        timeObj = undefined;
        break;
      case 'today':
        timeObj = {
          sinceDate: dayjs().startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'yesterday':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'days').startOf('day').valueOf(),
          untilDate: dayjs().subtract(1, 'days').endOf('day').valueOf(),
        };
        break;
      case 'last7days':
        timeObj = {
          sinceDate: dayjs().subtract(7, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'last28days':
        timeObj = {
          sinceDate: dayjs().subtract(28, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'lastMonth':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'months').startOf('month').valueOf(),
          untilDate: dayjs().subtract(1, 'months').endOf('month').valueOf(),
        };
        break;
      case 'last37days':
        timeObj = {
          sinceDate: dayjs().subtract(37, 'days').startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'thisYear':
        timeObj = {
          sinceDate: dayjs().startOf('year').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      case 'lastYear':
        timeObj = {
          sinceDate: dayjs().subtract(1, 'years').startOf('year').valueOf(),
          untilDate: dayjs().subtract(1, 'years').endOf('year').valueOf(),
        };
        break;
      case 'custom':
        timeObj = {
          sinceDate: dayjs().startOf('day').toDate(),
          untilDate: dayjs().endOf('day').toDate(),
        };
        break;
      case 'epoch':
        timeObj = {
          sinceDate: dayjs().startOf('day').valueOf(),
          untilDate: dayjs().endOf('day').valueOf(),
        };
        break;
      default:
        timeObj = undefined;
        break;
    }
    return timeObj;
  },
  getCDNImageUrl,
  getPostImage: (data) => {
    if (data && data.raw_data) {
      const { raw_data, info } = data;
      let img;
      if (raw_data.image) {
        img = raw_data.image;
      }
      if (raw_data.video && raw_data.video.thumbnail_large) {
        img = raw_data.video.thumbnail_large;
      }
      if (img) {
        if (info.source === 'facebook') {
          // Skip image if facebook
          return img;
        } else {
          return getCDNImageUrl(info.source, img);
        }
      }
    }
    return defaultImage;
  },
  getSentimentText: (score) => {
    if (score >= 5) {
      return 'Positive';
    } else if (score >= 2.5) {
      return 'Slightly Positive';
    } else if (score >= 0) {
      return 'Neutral';
    } else if (score >= -2.5) {
      return 'Slightly Negative';
    } else if (score >= -5) {
      return 'Negative';
    }
  },
};
