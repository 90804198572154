<template>
  <div>
    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon small>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          @click="onItemClick(item)"
        >
          <v-list-item-icon>
            <v-icon> {{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content> {{ item.title }} </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar
      v-model="snackBarOpen"
      app
      absolute
      timeout="2000"
      top
      right
      :color="snackBarType"
    >
      {{ snackBarText }}
    </v-snackbar>
    <ConfirmDialog
      :open="confirmOpen"
      :content="confirmContent"
      okButton="Reject"
      @close="onRejectConfirm"
    />
    <MessageEditDialog
      :open="editOpen"
      :message="message"
      @close="onEditResult"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/services/api';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';
import MessageEditDialog from '@/components/Dialog/MessageEditDialog.vue';

export default {
  name: 'MessageMenu',
  components: {
    ConfirmDialog,
    MessageEditDialog,
  },
  props: {
    message: Object,
  },
  data() {
    return {
      snackBarOpen: false,
      snackBarType: 'success',
      snackBarText: '',
      confirmOpen: false,
      confirmContent: '',
      confirmType: '',
      editOpen: false,
    };
  },
  methods: {
    onItemClick(item) {
      const { title } = item;
      if (title === 'Open Link' || title === 'Open User Link') {
        window.open(item.link, '_blank');
      } else if (title === 'Copy Link') {
        this.$copyText(item.link).then(
          () => this.showSnackBar('success', 'Link copied'),
          () => this.showSnackBar('error', 'Link copy failed')
        );
      } else if (title === 'Edit') {
        if (this.editOpen) this.editOpen = false;
        this.editOpen = true;
      } else if (title.includes('Reject')) {
        this.confirmOpen = false;
        if (title === 'Reject Tweet') {
          this.confirmContent = 'Would you like to reject this whole tweet?';
          this.confirmType = 'applyall';
        } else if (title === 'Reject Post') {
          this.confirmContent = 'Would you like to reject this whole post?';
          this.confirmType = 'applyall';
        } else if (title === 'Reject Comment') {
          this.confirmContent = 'Would you like to reject this comment?';
          this.confirmType = 'single';
        }
        this.confirmOpen = true;
      }
    },
    showSnackBar(type, text) {
      this.snackBarType = type;
      this.snackBarText = text;
      this.snackBarOpen = true;
    },
    onRejectConfirm(state) {
      if (state) {
        const { id } = this.message.info;
        api
          .rejectMessage(id, this.confirmType)
          .then((res) => {
            if (res.message === 'The following message has been edited.') {
              this.showSnackBar(
                'amber',
                'This message is being rejected, please wait for a while.'
              );
            } else {
              this.showSnackBar('success', 'Message rejected');
            }
          })
          .catch((e) => {
            console.error(e);
            this.showSnackBar('error', 'Message reject failed');
          });
      }
      this.confirmOpen = false;
    },
    onEditResult(state) {
      this.editOpen = false;
      if (state && state.result) {
        this.showSnackBar(state.result, state.message);
      }
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'account/userRole',
    }),
    menuItems() {
      let result = [];

      if (this.message) {
        // Open user link
        const { user, info, raw_data } = this.message;
        if (raw_data && raw_data.link) {
          result.push({
            title: 'Open Link',
            icon: 'mdi-link-variant',
            link: raw_data.link,
          });
          result.push({
            title: 'Copy Link',
            icon: 'mdi-content-copy',
            link: raw_data.link,
          });
        }
        if (user && user.link && user.id !== 'facebook_Anonymous') {
          result.push({
            title: 'Open User Link',
            icon: 'mdi-home-account',
            link: user.link,
          });
        }
        const isNotFeedOnly = this.userRole !== 'feedonly';
        // Is feed only
        if (isNotFeedOnly) {
          result.push({
            title: 'Edit',
            icon: 'mdi-comment-edit',
          });
          // Reject ...
          const messageType = info.source === 'twitter' ? 'Tweet' : 'Post';
          result.push({
            title: `Reject ${messageType}`,
            icon: 'mdi-comment-remove-outline',
          });
        }
        // Reject comment
        if (!info.is_origin && info.source !== 'twitter' && isNotFeedOnly) {
          result.push({
            title: 'Reject Comment',
            icon: 'mdi-comment-remove-outline',
          });
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
