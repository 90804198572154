<template>
  <div class="table-wrapper">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      :search="search"
      :loading="loading"
    >
      <template v-slot:top>
        <v-row class="head-row">
          <v-col cols="7">
            <div class="title-text">{{ title }}</div>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model.trim="search"
              outlined
              label="Search"
              dense
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              max-width="260"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.value`]="{ item }">
        {{ getSentimentText(item.value) }}
      </template>
      <template v-slot:[`item.isConsiderKeyword`]="{ item }">
        {{ item.isConsiderKeyword ? 'Yes' : 'No' }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ getDate(item.updatedAt) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon v-if="canEdit" small @click="$emit('edit', item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="$emit('delete', item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import helper from '@/services/helper';

export default {
  name: 'TableBase',
  props: {
    headers: Array,
    items: Array,
    title: String,
    loading: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    getSentimentText: helper.getSentimentText,
    getDate(timestamp) {
      var createdAt = new Date(timestamp);
      return dayjs(createdAt).fromNow();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  padding: 20px 20px 0 20px;
  .table-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    .v-input {
      font-weight: normal;
    }
  }
  table td .mdi-delete {
    margin-left: 20px;
  }
}
</style>
