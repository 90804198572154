<template>
  <v-card
    class="message-card"
    :disabled="isHiddenCard || data.rejected"
    @click="$emit('click')"
  >
    <v-card-text>
      <v-row class="title-row disabled-title" v-if="isHiddenCard">
        <v-col> This message has an exclude keyword. </v-col>
      </v-row>
      <v-row class="title-row" v-else>
        <v-col class="user-photo-column">
          <v-avatar size="42">
            <img
              class="user-photo"
              :src="userPhoto"
              alt="user profile photo"
              @error="setDefaultImage"
            />
          </v-avatar>
        </v-col>
        <v-col>
          <v-layout justify-space-between>
            <v-layout column justify-center class="title-block">
              <v-layout align-center>
                <SourceIcon :isSmall="true" :source="data.info.source" />
                <span class="username">
                  {{
                    data.user.username
                      | facebookAnonymous(data.info.source)
                      | capitalize
                  }}
                </span>
              </v-layout>
              <div>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="time-text ml-1" v-bind="attrs" v-on="on">
                      {{ timeText }}
                    </span>
                  </template>
                  {{ tooltipTimeText }}
                </v-tooltip>
              </div>
            </v-layout>
            <v-layout class="right-tool" align-center justify-end>
              <SentimentCircleIcon class="sentiment-wrap" :data="data" />
              <MessageMenu :message="data" />
            </v-layout>
          </v-layout>
        </v-col>
      </v-row>
      <FlexImage v-if="shouldShowImage" :img="currentPostImage" />
      <div class="content-row mt-3">
        <div
          class="post-title"
          v-if="data.raw_data.title || data.raw_data.tags"
        >
          <span
            v-if="data.raw_data.title && data.info.source !== 'blockdit'"
            class="text-break-all"
          >
            <strong v-html="data.raw_data.title"></strong>
          </span>
          <v-chip-group column>
            <v-chip
              v-for="tag in data.raw_data.tags"
              :key="tag"
              color="#6495ed"
              label
              dark
              small
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </div>
        <div class="post-subtitle" v-if="data.info.page" style="color: #aaa">
          <small>
            Shared in page <strong>{{ data.info.page }}</strong>
          </small>
        </div>
        <div class="post-text text-break-all">
          <template v-if="data.analytic_text">
            <span v-html="data.analytic_text"></span>&nbsp;...&nbsp;
          </template>
          <template v-else-if="data.analytic && data.analytic.highlighted_text">
            <span v-html="data.analytic.highlighted_text"></span>&nbsp;...&nbsp;
          </template>
          <template v-else-if="data.highlighted_text">
            <span v-html="data.highlighted_text"></span>
          </template>
          <template v-else>
            <span v-if="truncate">
              {{ data.raw_data.text | truncate(250) }}
            </span>
            <span v-else>
              {{ data.raw_data.text }}
            </span>
          </template>
        </div>
      </div>
      <EngagementBar :data="data" />
      <CategoryBar
        :analyticData="data.analytic"
        :subCategoryName="subCategoryName"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import helper from '@/services/helper';
import defaultImage from '@/assets/default-profile.jpg';

import SourceIcon from '../SourceIcon.vue';
import EngagementBar from './EngagementBar.vue';
import CategoryBar from './CategoryBar.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';
import FlexImage from './FlexImage.vue';
import MessageMenu from './MessageMenu.vue';

export default {
  name: 'MessageCard',
  components: {
    SourceIcon,
    EngagementBar,
    CategoryBar,
    SentimentCircleIcon,
    FlexImage,
    MessageMenu,
  },
  props: {
    data: Object,
    subCategoryName: Object,
    truncate: Boolean,
    isVideoCard: Boolean,
  },
  data() {
    return {
      // isImageChecked: false,
      isBigImage: true,
    };
  },
  methods: {
    setDefaultImage(e) {
      e.target.src = defaultImage;
    },
    onImageLoad() {
      if (this.$refs.imgwrap) {
        const h = this.$refs.imgwrap.clientHeight;
        if (h <= 300) {
          this.isBigImage = false;
        }
        // this.isImageChecked = true;
      }
    },
  },
  computed: {
    userPhoto() {
      if (this.data && this.data.info) {
        if (this.data.info.source !== 'facebook' && this.data.user.photo) {
          return helper.getCDNImageUrl(
            this.data.info.source,
            this.data.user.photo
          );
        }
      }
      return '';
    },
    timeText() {
      if (this.data && this.data.info) {
        const createdAt = dayjs(this.data.info.created_at);
        if (this.data.info.source === 'twitter') {
          let text = `Tweeted since ${createdAt.format('DD MMM YYYY, h:mm a')}`;
          if (this.data.summary.retweet_count > 0) {
            const updatedAtText = dayjs(this.data.info.updated_at).fromNow();
            text = `${text} -- has retweeted ${updatedAtText}`;
          }
          return text;
        }
        return createdAt.fromNow();
      }
      return '';
    },
    tooltipTimeText() {
      if (this.data && this.data.info) {
        return dayjs(this.data.info.created_at).format('DD MMM YYYY, h:mm a');
      }
      return '';
    },
    isHiddenCard() {
      if (this.data && this.data.raw_data) {
        return this.data.raw_data.is_hidden;
      }
      return false;
    },
    currentPostImage() {
      return helper.getPostImage(this.data);
    },
    shouldShowImage() {
      if (this.data && this.data.raw_data) {
        const d = this.data.raw_data;
        if (this.isVideoCard) {
          if (d.video && d.video.thumbnail_large) {
            return true;
          }
        }
        return !!d.image;
      }
      return false;
    },
    shouldShowVideo() {
      if (this.isVideoCard) {
        if (this.data.raw_data.image) {
          return false;
        }
        if (
          this.data.info.source === 'youtube' &&
          this.data.raw_data.video &&
          this.data.raw_data.video.thumbnail_large
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-card {
  margin-bottom: 1rem;
  user-select: none;
  .title-row {
    border-bottom: 1px solid #ddd;
    margin: -16px -16px 0 -16px;
    .user-photo-column {
      flex: 0 0 54px;
      padding-right: 0;
      .user-photo {
        width: 100%;
        height: fit-content;
      }
    }
    .time-text {
      font-size: 12px;
      color: rgb(155, 155, 155);
    }
    .title-block {
      .username {
        font-size: 18px;
        line-height: 22px;
        color: #333;
      }
      .time-text {
        margin-top: 3px;
      }
    }
    .sentiment-wrap {
      margin-right: 18px;
    }
    &.disabled-title {
      font-weight: bold;
    }
  }
  .content-row {
    .post-title {
      font-size: 22px;
      line-height: 30px;
      color: #333;
      margin-bottom: 0.8rem;
    }
    .post-subtitle {
      margin-bottom: 0.5rem;
    }
    .post-text {
      font-size: 16px;
      margin-bottom: 0.8rem;
    }
  }
}
</style>
