<template>
  <div>
    <v-overlay
      class="networkgraph-overlay"
      v-show="showNetworkGraph"
      :dark="false"
    >
      <NetworkGraph
        :showNetworkGraph="showNetworkGraph"
        ref="graph"
        @graphClose="closeNetworkGraph"
      />
    </v-overlay>
    <v-overlay class="influencer-loading-overlay" v-show="loading">
      <v-progress-linear
        class="influencer-loading-line"
        indeterminate
      ></v-progress-linear>
      <v-progress-circular
        class="my-4 influencer-loading-circle"
        size="50"
        width="3"
        indeterminate
      />
    </v-overlay>
    <v-card v-show="!loading" elevation="2" class="influencer">
      <v-card-title>
        Influencer
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="networkgraph-all-icon" v-on="on" v-bind="attrs">
              <i
                class="mdi mdi-vector-polygon"
                @click.stop="showGraphAll()"
              ></i>
            </span>
          </template>
          Show whole network graph
        </v-tooltip>
      </v-card-title>
      <v-data-table
        class="influencer-table"
        :headers="headers"
        :items="influencers"
        :items-per-page="-1"
        hide-default-footer
        disable-sort
        @click:row="drillDown"
      >
        <template v-slot:[`item.username`]="{ item }">
          <img :src="getUserImageLink(item.img)" alt="" class="user-img" />
          <span class="uesr-name">{{ item.username }}</span>
        </template>
        <template v-slot:[`item.posts`]="{ item }">
          <ul class="influencer-posts">
            <li v-for="(post, index) in item.posts" :key="index">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <SentimentIcon :type="post.key | capitalize" />:
                    {{ post.value }}
                  </span>
                </template>
                {{ post.key | capitalize }}
              </v-tooltip>
            </li>
            <li v-if="!isEngagementMode">Total: {{ item.node_score }}</li>
          </ul>
        </template>
        <template v-slot:[`item.sentiments`]="{ item }">
          <ul class="influencer-sentiments">
            <li v-for="(sentiment, index) in item.sentiments" :key="index">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <i
                      class="fa fw"
                      :class="getSentimentClass(sentiment.key)"
                    ></i>
                    : {{ sentiment.value }}
                  </span>
                </template>
                {{ sentiment.key | capitalize }}
              </v-tooltip>
            </li>
          </ul>
        </template>
        <template v-slot:[`item.engagements`]="{ item }">
          <ul class="influencer-engagements">
            <li v-for="(engagement, index) in item.engagements" :key="index">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <SentimentIcon :type="engagement.key | capitalize" />:
                    {{ engagement.value }}
                  </span>
                </template>
                {{ engagement.key | capitalize }}
              </v-tooltip>
            </li>
            <li v-if="isEngagementMode">Total: {{ item.node_score }}</li>
          </ul>
        </template>
        <template v-slot:[`item.action_menu`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="networkgraph-icon" v-on="on" v-bind="attrs">
                <i
                  class="mdi mdi-vector-triangle"
                  @click.stop="showGraph(item)"
                ></i>
              </span>
            </template>
            Show network graph
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <i
                  class="fa fa-external-link"
                  @click.stop="openLink(item.link)"
                ></i>
              </span>
            </template>
            Open external user url
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/services/api.js';
import helper from '@/services/helper';
import SentimentIcon from '../SentimentIcon.vue';
import NetworkGraph from '../NetworkGraph/NetworkGraph.vue';

export default {
  name: 'InfluencerMain',
  components: {
    SentimentIcon,
    NetworkGraph,
  },
  data() {
    return {
      loading: true,
      headers: [
        { align: 'center', text: '#', value: 'number' },
        { align: 'start', text: 'Username', value: 'username' },
        { align: 'center', text: 'Source', value: 'source' },
        { align: 'start', text: 'Posts', value: 'posts' },
        { align: 'start', text: 'Sentiments', value: 'sentiments' },
        { align: 'start', text: 'Engagements', value: 'engagements' },
        { align: 'center', text: 'Action Menu', value: 'action_menu' },
      ],
      influencers: [],
      showNetworkGraph: false,
      isNetworkGraphLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    drillDown(obj) {
      const uname = helper.facebookAnonymous(obj.username, obj.source);
      const criteria = {
        payload: {
          title: uname ? `influencer name: ${uname}` : 'an unknown user.',
          preset: 'previous7days',
          drilldownType: 'influencer',
        },
        original: {
          userId: obj.id,
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
    getSentimentClass(key) {
      if (key === 'positive') return 'fa-smile-o';
      if (key === 'negative') return 'fa-frown-o';
      return 'fa-meh-o';
    },
    getUserImageLink(item) {
      return 'https://cdn.ask-dom.com/img/user-force/' + item;
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    async showGraph(item) {
      this.showNetworkGraph = true;
      this.$refs.graph.show(item);
    },
    async showGraphAll() {
      this.showNetworkGraph = true;
      this.$refs.graph.showAll(this.influencers.slice(0, 10));
    },
    closeNetworkGraph() {
      this.showNetworkGraph = false;
    },
    async init() {
      this.loading = true;
      // TODO check what actually need to be sent for networkArg.
      let networkArg = { nodeLimit: 50 };
      const result = await api
        .getInfluencer(this.isEngagementMode, networkArg)
        .catch(() => {
          this.error = true;
        });
      if (result) {
        this.influencers = [];
        let resultList = [];
        let people_info = {};
        let res = result.message;
        people_info = res.peopleInfo;
        const brand = localStorage.getItem('domv3');
        if (JSON.parse(brand).account.brand == 'kfc') {
          resultList = res.influencers.filter((inf) => {
            return inf.source !== 'facebook';
          });
        } else {
          resultList = res.influencers;
        }
        if (resultList.length > 0) {
          for (let i in resultList) {
            let displayTable = {};
            displayTable.number = i * 1 + 1;
            displayTable.source = resultList[i].source;
            displayTable.posts = resultList[i].actions;
            displayTable.sentiments = resultList[i].sentiment_count;
            displayTable.engagements = resultList[i].reactions;
            displayTable.node_score = resultList[i].node_score;
            displayTable.id = resultList[i].id;

            let id = resultList[i].id;
            if (people_info[id]) {
              displayTable.username = people_info[id].user_name;
              displayTable.img =
                resultList[i].source + '/' + people_info[id].user_photo;
              displayTable.link = people_info[id].user_link;
            } else {
              displayTable.username = resultList[i].id;
            }
            this.influencers.push(displayTable);
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.influencer {
  .networkgraph-all-icon {
    margin-left: 5px;
    cursor: pointer;
  }
  .influencer-loading-overlay {
    .v-overlay__scrim {
      background-color: #fafafa !important;
    }
    .influencer-loading-line {
      position: fixed;
      left: 0;
      top: 0;
    }
    .influencer-loading-circle {
      color: var(--primary);
    }
  }
  .networkgraph-overlay {
    .v-overlay__content {
      width: 900px;
      .vis-network canvas {
        min-height: 500px;
      }
    }
  }
  .filter-button {
    .fa.fa-circle {
      color: #ddd;
      font-size: 1rem;
      margin-right: 4px;
    }
  }
  .influencer-table {
    tr {
      cursor: pointer;
    }
    .v-data-table-header th span {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
    .user-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 40px;
    }
    .user-name {
      vertical-align: middle;
    }
    .influencer-posts li {
      white-space: nowrap;
    }
    .influencer-sentiments {
      padding-top: 10px;
      padding-bottom: 10px;
      .fa-frown-o {
        color: var(--negative-sentiment) !important;
      }
      .fa-smile-o {
        color: var(--positive-sentiment) !important;
      }
      .fa-meh-o {
        color: var(--neutral-sentiment) !important;
      }
    }
    .influencer-engagements {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .networkgraph-icon {
      margin-right: 5px;
    }
  }
}
</style>
