<template>
  <v-container fluid class="fanpage">
    <v-row>
      <v-col>
        <FanpageTable />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FanpageTable from '@/components/Table/FanpageTable.vue';
export default {
  name: 'Fanpage',
  components: {
    FanpageTable,
  },
};
</script>

<style lang="scss" scoped></style>
