<template>
  <v-dialog v-model="isOpen" scrollable max-width="600" @click:outside="close">
    <v-card tile outline>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title align-center>
          <v-layout class="text-body-2" align-center> EDIT MESSAGE </v-layout>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon small> fa-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-card class="mt-3" tile>
          <v-card-text v-if="message && message.info">
            <v-layout align-center class="text-body-1 font-weight-bold mb-2">
              <SourceIcon :isSmall="true" :source="message.info.source" />
              <span>{{ message.user.username }}</span>
            </v-layout>
            <div
              class="text-body-2"
              v-if="message && message.raw_data && message.raw_data.text"
            >
              {{ message.raw_data.text | truncate }}
            </div>
          </v-card-text>
        </v-card>
        <div class="mt-3">
          <v-select
            v-model="selectedSentiment"
            :items="sentimentItems"
            label="Sentiment"
            outlined
            hide-details
          >
          </v-select>
        </div>
        <div class="mt-3">
          <CategoryTreeSelect
            id="edit-cat"
            :showAll="true"
            :criteria="categoryData"
            @update="onCategoryChange"
          />
        </div>
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-btn
          v-if="isNotTwitter"
          color="primary"
          @click="onSave('applyall', true)"
          :disabled="invalidSave"
        >
          Force category
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isNotTwitter"
          color="primary"
          @click="close"
          :disabled="invalidSave"
        >
          Apply whole post
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="onSave('single')"
          :disabled="invalidSave"
        >
          <span v-if="isNotTwitter">Apply</span>
          <span v-else>Apply to Tweets</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/api';

import SourceIcon from '@/components/SourceIcon.vue';
import CategoryTreeSelect from '@/components/Filter/CategoryTreeSelect.vue';

const subCatKeys = [
  'sub_categories_level_1',
  'sub_categories_level_2',
  'sub_categories_level_3',
  'sub_categories_level_4',
];

export default {
  name: 'MessageEditDialog',
  components: {
    SourceIcon,
    CategoryTreeSelect,
  },
  props: {
    open: Boolean,
    message: Object,
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
      messageData: {},
      categoryData: {},
      selectedSentiment: 'current',
    };
  },
  methods: {
    init() {
      this.messageData = JSON.parse(JSON.stringify(this.message));
      this.selectedSentiment = 'current';
      const { analytic } = this.messageData;
      const category = analytic.categories;
      const subCategory = [];
      for (let i = 0; i < subCatKeys.length; i += 1) {
        const level = i + 1;
        subCategory.push({
          level,
          category: analytic[subCatKeys[i]],
        });
      }
      // Convert to select component format
      this.categoryData = { category, subCategory };
    },
    close(result = false) {
      this.$emit('close', result);
    },
    onCategoryChange({ category, subCategory, count }) {
      // console.log(category, subCategory);
      this.categoryData = { category, subCategory, count };
    },
    compareArray(a, b) {
      return a.sort().join() === b.sort().join();
    },
    async onSave(mode, forceCategory) {
      // console.log('Saving');
      this.isSaving = true;
      const id = this.messageData.info.id;
      let updateSentimentDone = 0;
      let updateCategoryDone = 0;
      if (this.selectedSentiment !== 'current') {
        const result = await api
          .updateSentiment(id, mode, this.selectedSentiment)
          .catch(() => {});
        if (result && result.status === 200) {
          updateSentimentDone = 1;
        } else {
          updateSentimentDone = -1;
        }
      }
      let updateCategory = false;
      const { category, subCategory } = this.categoryData;
      const oldAnalytic = this.message.analytic;
      const newCatArr = [];
      if (
        forceCategory ||
        !this.compareArray(category, oldAnalytic.categories)
      ) {
        updateCategory = true;
        newCatArr.push({
          level: 0,
          category,
        });
      }
      for (let i = 0; i < subCategory.length; i += 1) {
        if (subCategory[i].category) {
          let cList = ['others'];
          const { level } = subCategory[i];
          if (subCategory[i].category) {
            cList = subCategory[i].category;
          }
          const target = `sub_categories_level_${level}`;
          // if force just set it
          if (forceCategory) {
            updateCategory = true;
            newCatArr.push({
              level,
              category: cList,
            });
          }
          // Check if changed
          if (oldAnalytic[target]) {
            if (!this.compareArray(cList, oldAnalytic[target])) {
              updateCategory = true;
              newCatArr.push({
                level,
                category: cList,
              });
            }
          } else {
            updateCategory = true;
            newCatArr.push({
              level,
              category: cList,
            });
          }
        }
      }
      if (forceCategory || updateCategory) {
        const result = await api
          .updateCategory(id, mode, newCatArr)
          .catch(() => {});
        if (result && result.status === 200) {
          updateCategoryDone = 1;
        } else {
          updateCategoryDone = -1;
        }
      }
      //
      console.log(updateSentimentDone, updateCategoryDone);

      // Show toast and update local based on done status
      if (updateSentimentDone > 0 || updateCategoryDone > 0) {
        // Show toast success
        this.snackBarType = 'success';
        this.showSnackBar = true;
        this.close({
          result: 'success',
          message: 'Edit saved',
        });
      } else if (updateSentimentDone < 0 || updateCategoryDone < 0) {
        // Show toast error
        this.close({
          result: 'error',
          message: 'Cannot edit message',
        });
      } else {
        console.log('Nothing change');
        this.close({
          result: 'amber',
          message: 'Nothing changed',
        });
      }
      this.isSaving = false;
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.init();
      }
      this.isOpen = this.open;
    },
    message: 'init',
  },
  computed: {
    sentimentItems() {
      let sentimentText;
      if (this.messageData && this.messageData.analytic) {
        const score = this.messageData.analytic.sentiment_score;
        if (score >= 3) sentimentText = 'Positive';
        else if (score > 1 && score < 3) sentimentText = 'Slightly Positive';
        else if (score >= -1 && score <= 1) sentimentText = 'Neutral';
        else if (score > -3 && score < -1) sentimentText = 'Slightly Negative';
        else if (score <= -3) sentimentText = 'Negative';
      }
      return [
        { text: `Current: ${sentimentText}`, value: 'current' },
        { text: 'Positive', value: '5', color: '#267cab' },
        { text: 'Slightly Positive', value: '2.5', color: '#6395ab' },
        { text: 'Neutral', value: '0', color: '#aba12d' },
        { text: 'Slightly Negative', value: '-2.5', color: '#ab712c' },
        { text: 'Negative', value: '-5', color: '#ab2648' },
      ];
    },
    isNotTwitter() {
      if (this.messageData && this.messageData.info) {
        return this.messageData.info.source !== 'twitter';
      }
      return true;
    },
    invalidSave() {
      // Check if none of category select
      if (this.categoryData) {
        return this.categoryData.count === 0;
      }
      if (this.isSaving) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
