<template>
  <v-card class="home-base-widget" height="height" max-height="420">
    <v-overlay
      :value="loading || error"
      :opacity="0.3"
      :absolute="true"
      z-index="1"
    >
      <v-btn @click="$emit('reload')" icon>
        <v-icon>fa-refresh {{ loading ? 'fa-spin' : '' }}</v-icon>
      </v-btn>
    </v-overlay>
    <v-card-title class="widget-title">{{ title }}</v-card-title>
    <div class="d-flex px-4 pb-4 bottom-line">
      <div class="widget-date-wrapper no-underline-input">
        <v-select
          class="widget-date-selector"
          dense
          height="22"
          :items="presetItems"
          v-model="selected"
          @change="$emit('changePreset', selected)"
        />
      </div>
    </div>
    <v-layout v-if="hasRowHead" class="row-head" align-center>
      <slot name="row-head"></slot>
    </v-layout>
    <v-card-text class="card-text pa-0">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HomeBaseWidget',
  props: {
    title: String,
    selectedPreset: String,
    hasRowHead: Boolean,
    height: Number,
    error: Boolean,
    loading: Boolean,
    enableToday: Boolean,
  },
  watch: {
    selectedPreset: {
      handler: 'setPreset',
      immediate: true,
    },
  },
  data() {
    return {
      selected: 'previous7days',
    };
  },
  methods: {
    setPreset() {
      this.selected = this.selectedPreset;
    },
  },
  computed: {
    presetItems() {
      const result = [
        { text: 'Yesterday vs same day last week', value: 'yesterday' },
        { text: 'Last 7 days vs previous 7 days', value: 'previous7days' },
        { text: 'Last 28 days vs previous 28 days', value: 'previous28days' },
      ];
      if (this.enableToday) {
        return [{ text: 'Today so far', value: 'today' }, ...result];
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-base-widget {
  display: flex !important;
  flex-direction: column;
  margin: 12px;
  .row-head {
    padding: 5px 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #eee;
    font-size: 12px;
    font-weight: 500;
    color: #333;
    height: 30px;
    max-height: 30px;
  }
  .widget-title {
    color: #37526f;
    font-size: 22px;
    padding-bottom: 0;
    font-weight: 400 !important;
  }
  .bottom-line {
    border-bottom: 1px solid #ddd;
  }
  .card-text {
    flex-grow: 1;
    overflow: auto;
  }
}
</style>
