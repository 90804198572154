<template>
  <v-dialog
    scrollable
    v-model="isOpen"
    content-class="seemore-dialog"
    max-width="900px"
  >
    <v-card outline>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title align-center>
          <v-layout class="text-body-2" align-center>
            <span v-if="isLoading">
              <v-icon>fa-spinner fa-spin</v-icon>
              <span class="ml-1">Loading messages ...</span>
            </span>
            <span v-else> Topic detail </span>
          </v-layout>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon small> fa-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <template v-if="!isLoading">
          <v-card v-if="!messageList || messageList.length === 0">
            Result not found, data may not exist.
          </v-card>
          <v-card class="mb-3 pa-2 video-card" v-if="hasVideo">
            <template v-if="originMessage.info.source === 'youtube'">
              <LazyYoutube ref="lazyVideo" :src="originMessage.raw_data.link" />
            </template>
            <template v-if="originMessage.info.source === 'facebook'">
              <iframe
                :src="convertFBVideoUrl(originMessage)"
                width="500"
                height="420"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
              ></iframe>
            </template>
            <template v-if="originMessage.info.source === 'instagram'">
              <iframe
                :src="originMessage.info.video_url"
                style="width: 100%; border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowTransparency="true"
              ></iframe>
            </template>
            <template v-if="originMessage.info.source === 'twitter'">
              <Tweet
                :id="convertTwitterId(originMessage)"
                class="tweet-wrapper"
                :options="{ theme: 'dark', hide_media: false }"
              />
            </template>
          </v-card>
          <div class="image-card" v-if="canShowImage">
            <FlexImage :img="currentPostImage" />
          </div>
          <template v-if="isLineage">
            <LineageItem
              v-for="message in messageList"
              :key="message.id"
              :message="message"
              :subCategoryName="subCategoryName"
              :disableThumbnail="true"
            />
          </template>
          <template v-else-if="isTwitter">
            <MessageItem
              :data="originMessage"
              :subCategoryName="subCategoryName"
              :showRetweetBar="true"
              :retweetList="messageList"
              :disableThumbnail="!hasVideo"
            />
          </template>
          <template v-else>
            <MessageItem
              v-for="message in messageList"
              :key="message.id"
              :data="message"
              :subCategoryName="subCategoryName"
              :disableThumbnail="true"
              :class="{ 'message-comment': !message.info.is_origin }"
            />
            <v-container>
              <v-pagination
                v-model="currentPage"
                :length="totalPageValue"
                :total-visible="7"
              />
            </v-container>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { LazyYoutube } from 'vue-lazytube';
import { Tweet } from 'vue-tweet-embed';

import LineageItem from '@/components/Messages/LineageItem.vue';
import MessageItem from '@/components/Messages/MessageItem.vue';
import FlexImage from '@/components/Messages/FlexImage.vue';

import helper from '@/services/helper';
import defaultImage from '@/assets/default-profile.jpg';

export default {
  name: 'SeeMoreDialog',
  components: {
    LineageItem,
    MessageItem,
    FlexImage,
    LazyYoutube,
    Tweet,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      close: 'seemore/close',
      changePage: 'seemore/changePage',
    }),
    isValidFBVideo(link) {
      return link && link.indexOf('/videos/') > -1;
    },
    convertFBVideoUrl(obj) {
      let link;
      if (this.isValidFBVideo(obj.info.video_url)) {
        link = obj.info.video_url;
      } else {
        link = obj.raw_data.link;
      }
      let finalLink =
        'https://www.facebook.com/plugins/video.php?href=' +
        encodeURI(link) +
        '&width=500&show_text=false&height=420';
      return finalLink;
    },
    convertTwitterId(obj) {
      if (obj && obj.info && obj.info.id) {
        return obj.info.id.split('_').pop();
      }
      return '';
    },
  },
  computed: {
    ...mapState({
      open: (state) => state.seemore.open,
      isLoading: (state) => state.seemore.loading,
      isLineage: (state) => state.seemore.isLineage,
      messageList: (state) => state.seemore.messageList,
    }),
    ...mapGetters({
      subCategoryName: 'seemore/subCategoryName',
      originMessage: 'seemore/originMessage',
      currentPageValue: 'seemore/currentPage',
      totalPageValue: 'seemore/totalPage',
    }),
    currentPage: {
      get: function () {
        return this.currentPageValue;
      },
      set: function (val) {
        this.changePage(val);
      },
    },
    isOpen: {
      get: function () {
        return this.open;
      },
      set: function (val) {
        if (!val) {
          this.close();
        }
      },
    },
    hasVideo() {
      if (this.originMessage) {
        const { info, raw_data } = this.originMessage;
        // youtube
        if (raw_data.video) return true;
        // fb / ig / twitter
        if (info.has_video) return true;
      }
      return false;
    },
    canShowImage() {
      if (this.originMessage && this.originMessage.raw_data) {
        const { image, video } = this.originMessage.raw_data;
        if (this.hasVideo) {
          // If facebook video that not contain /videos/ in url
          if (this.originMessage.info.source === 'facebook') {
            return !this.isValidFBVideo(this.originMessage.info.video_url);
          }
        } else if (!video && image) {
          // Other case that has video in raw_data
          return true;
        }
      }
      return false;
    },
    currentPostImage() {
      if (this.originMessage && this.originMessage.raw_data) {
        const { raw_data, info } = this.originMessage;
        if (raw_data.image) {
          return helper.getCDNImageUrl(info.source, raw_data.image);
        }
        if (raw_data.video && raw_data.video.thumbnail_large) {
          return helper.getCDNImageUrl(
            info.source,
            raw_data.video.thumbnail_large
          );
        }
      }
      return defaultImage;
    },
    isTwitter() {
      if (this.originMessage && this.originMessage.info) {
        return this.originMessage.info.source === 'twitter';
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.seemore-dialog {
  .image-card {
    padding: 0 1rem;
  }
  .video-card {
    background-color: #000;
    text-align: center;
  }
  .tweet-wrapper {
    display: flex;
    justify-content: center;
  }
  .message-comment {
    margin-left: 15px;
  }
}
</style>
