<template>
  <v-container fluid class="keyword-excluded-keyword">
    <v-row>
      <v-col sm="12" md="6">
        <KeywordTable />
      </v-col>
      <v-col sm="12" md="6">
        <KeywordTable isExcludedKeyword />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import KeywordTable from '@/components/Table/KeywordTable.vue';
export default {
  name: 'KeywordAndExcludedKeyword',
  components: {
    KeywordTable,
  },
};
</script>

<style lang="scss" scoped>
$small: 600px;
.keyword-excluded-keyword {
  @media screen and (max-width: $small) {
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
