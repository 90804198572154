import Vue from 'vue';
import dayjs from 'dayjs';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  namespaced: true,
  state: {
    brand: '',
    colors: {},
    userAccount: {},
    userInfo: {},
    summary: {},
    mode: '',
    totalUsage: 0,
  },
  getters: {
    colors: (state) => state.colors,
    hasUserInfo({ userInfo }) {
      if (userInfo && userInfo.id) {
        return true;
      }
      return false;
    },
    sourceNameList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources;
      }
      return [];
    },
    sourceList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources.map((s) => {
          return {
            value: s,
            text: helper.capitalize(s),
          };
        });
      }
      return [];
    },
    sortedSourceList({ userAccount }) {
      if (userAccount && userAccount.visibleSources) {
        let srcList = userAccount.visibleSources.sort();
        let finalSrc = [];
        const sList = ['facebook', 'instagram', 'pantip', 'twitter', 'youtube'];
        sList.forEach((s) => {
          if (srcList.includes(s)) {
            finalSrc.push(s);
          }
        });
        for (let src of srcList) {
          if (!sList.includes(src)) {
            finalSrc.push(src);
          }
        }
        return finalSrc;
      }
      return [];
    },
    accessRights({ userAccount }) {
      if (userAccount && userAccount.accessRights) {
        return userAccount.accessRights;
      }
      return [];
    },
    queryLength({ userAccount }) {
      if (userAccount && userAccount.queryLength) {
        return userAccount.queryLength;
      }
      return 28;
    },
    userRole({ userInfo }) {
      if (userInfo && userInfo.role) {
        return userInfo.role;
      }
      return 'user';
    },
    userName({ userInfo }) {
      if (userInfo && userInfo.username) {
        return userInfo.username;
      }
      return '';
    },
  },
  actions: {
    async logout({ commit }) {
      await api.logout();
      commit('clearConfig');
    },
    clearConfig({ commit }) {
      commit('clearConfig');
    },
    setConfig({ commit }, configData) {
      commit('setConfig', configData);
      commit('setColorConfig', configData);
    },
    async getUsageStats({ commit }) {
      const since = dayjs().startOf('month').valueOf();
      const until = dayjs().endOf('month').valueOf();
      const result = await api.getUsageStats(since, until).catch(() => {});
      if (result.message && result.message.total) {
        commit('setTotalUsage', result.message.total);
      }
    },
  },
  mutations: {
    clearConfig(state) {
      Vue.set(state, 'brand', '');
      Vue.set(state, 'colors', {});
      Vue.set(state, 'userAccount', {});
      Vue.set(state, 'userInfo', {});
      Vue.set(state, 'summary', {});
      Vue.set(state, 'mode', '');
    },
    setConfig(state, data) {
      Vue.set(state, 'brand', data.brand);
      Vue.set(state, 'userAccount', data.userAccount);
      Vue.set(state, 'userInfo', data.userInfo);
      Vue.set(state, 'summary', data.summary);
      Vue.set(state, 'mode', data.mode);
    },
    setColorConfig(state, data) {
      const result = {};
      data.colors.forEach((c) => {
        result[c.id] = c.color;
      });
      Vue.set(state, 'colors', result);
    },
    setTotalUsage(state, data) {
      Vue.set(state, 'totalUsage', data);
    },
  },
};
