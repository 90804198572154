import Vue from 'vue';
import Vuetify, { VSnackbar, VIcon } from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VIcon,
  },
});

import Toast2 from 'vuetify-toast2';
Vue.use(Toast2);

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#37526f',
      },
    },
  },
};

export default new Vuetify(opts);
