<template>
  <v-card class="message-item" @click="$emit('click')">
    <v-card-text>
      <v-row>
        <v-col class="d-none d-sm-flex user-photo-column">
          <img
            class="user-photo"
            :src="userPhoto"
            alt="user profile photo"
            @error="setDefaultImage"
          />
        </v-col>
        <v-col>
          <v-layout justify-space-between>
            <v-layout align-center class="title-block">
              <SourceIcon :isSmall="true" :source="data.info.source" />
              <span class="username">
                {{
                  data.user.username
                    | facebookAnonymous(data.info.source)
                    | capitalize
                }}
              </span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="time-text ml-1"
                    v-bind="attrs"
                    v-on="on"
                    v-html="timeText"
                  >
                  </span>
                </template>
                {{ tooltipTimeText }}
              </v-tooltip>
            </v-layout>
            <v-layout class="right-tool" align-center justify-end>
              <div class="origin-text">
                {{ originText }}
              </div>
              <MessageMenu :message="data" />
            </v-layout>
          </v-layout>
          <v-row class="post-row">
            <v-col>
              <div class="second-row" v-if="data.info.page">
                <span>
                  Shared in page <strong>{{ data.info.page }}</strong>
                </span>
              </div>
              <div class="mt-2" v-if="data.info.source !== 'blockdit'">
                <strong
                  class="text-break-all"
                  v-html="data.raw_data.title"
                ></strong>
              </div>
              <div
                class="post-text text-break-all"
                v-if="data.highlighted_text"
                v-html="data.highlighted_text"
              ></div>
              <div
                class="post-text text-break-all"
                v-else-if="data.raw_data.text && truncate"
              >
                {{ data.raw_data.text | truncate(250) }}
              </div>
              <div
                class="post-text text-break-all"
                v-else-if="data.raw_data.text && !truncate"
              >
                {{ data.raw_data.text }}
              </div>
              <div v-if="currentPostImage">
                <img
                  class="post-image"
                  :src="currentPostImage"
                  alt="post image"
                  @error="setDefaultImage"
                />
              </div>
            </v-col>
            <SentimentCircleIcon class="sentiment-wrap" :data="data" />
          </v-row>
          <EngagementBar :data="data" />
          <CategoryBar
            :analyticData="data.analytic"
            :subCategoryName="subCategoryName"
          />
          <v-layout class="mt-2" v-if="showRetweetBar" align-center wrap>
            <span v-for="m in retweetList" :key="m.info.id">
              <v-tooltip top v-if="!m.info.is_origin">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1 mb-2"
                  >
                    <img :src="getTwitterImage(m)" @error="setDefaultImage" />
                  </v-avatar>
                </template>
                {{ m.user.username || 'Anonymous' }}
              </v-tooltip>
            </span>
            <span v-if="data.summary.retweet_count > retweetList.length - 1">
              and others
              {{
                (data.summary.retweet_count - retweetList.length - 1)
                  | numeral('0,0')
              }}
              people retweet this.
            </span>
          </v-layout>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import helper from '@/services/helper';

import SourceIcon from '../SourceIcon.vue';
import EngagementBar from './EngagementBar.vue';
import CategoryBar from './CategoryBar.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';
import MessageMenu from './MessageMenu.vue';

import defaultImage from '@/assets/default-profile.jpg';

export default {
  name: 'MessageItem',
  components: {
    SourceIcon,
    EngagementBar,
    CategoryBar,
    SentimentCircleIcon,
    MessageMenu,
  },
  props: {
    data: Object,
    subCategoryName: Object,
    disableThumbnail: Boolean,
    showRetweetBar: Boolean,
    retweetList: Array,
    truncate: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    setDefaultImage(e) {
      e.target.src = defaultImage;
    },
    getTwitterImage(m) {
      return helper.getCDNImageUrl(m.info.source, m.user.photo);
    },
  },
  computed: {
    userPhoto() {
      if (this.data && this.data.info) {
        if (this.data.info.source !== 'facebook' && this.data.user.photo) {
          return helper.getCDNImageUrl(
            this.data.info.source,
            this.data.user.photo
          );
        }
      }
      return '';
    },
    currentPostImage() {
      return helper.getPostImage(this.data);
    },
    timeText() {
      if (this.data && this.data.info) {
        const createdAt = dayjs(this.data.info.created_at);
        if (this.data.info.source === 'twitter') {
          let text = `Tweeted since ${createdAt.format('DD MMM YYYY, h:mm a')}`;
          if (this.data.summary.retweet_count > 0) {
            const updatedAtText = dayjs(this.data.info.updated_at).fromNow();
            text = `${text} <wbr> -- has retweeted ${updatedAtText}`;
          }
          return text;
        }
        return createdAt.fromNow();
      }
      return '';
    },
    tooltipTimeText() {
      if (this.data && this.data.info) {
        if (this.data.info.source !== 'twitter') {
          return dayjs(this.data.info.created_at).format('DD MMM YYYY, h:mm a');
        }
      }
      return '';
    },
    originText() {
      if (this.data && this.data.info) {
        if (this.data.info.is_origin) return '(Origin)';
        if (this.data.info.source === 'twitter') return '(Retweet)';
      }
      return '(Comment)';
    },
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  margin-bottom: 0.5rem;
  user-select: none;
  .user-photo-column {
    max-width: 112px;
    flex: 1 1 auto;
    .user-photo {
      max-width: 112px;
      max-height: 112px;
      width: 100%;
      height: fit-content;
    }
  }

  .time-text,
  .origin-text {
    font-size: 12px;
    color: rgb(155, 155, 155);
  }
  .title-block {
    .username {
      font-size: 18px;
      line-height: 22px;
      color: #333;
    }
    .time-text {
      margin-top: 3px;
    }
  }
  .right-tool {
    margin-right: 8px;
    .origin-text {
      margin-right: 8px;
    }
  }
  .second-row {
    margin-top: 8px;
    padding-left: 28px;
  }
  .post-row {
    margin-bottom: 15px;
  }
  .post-text {
    margin-top: 8px;
    // min-height: 60px;
  }
  .post-image {
    margin-top: 8px;
    height: 80px;
  }
  .sentiment-wrap {
    margin-right: 16px;
    margin-top: 18px;
  }
}
</style>
