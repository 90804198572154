<template>
  <div class="category-tree-select">
    <v-select
      v-model="selectedTreeCategory"
      :items="selectedTreeCategory"
      :id="id"
      class="w-100"
      label="Category"
      multiple
      chips
      outlined
      small-chips
      readonly
      @click.native="menuOpen = true"
      hide-details="auto"
      :error-messages="
        selectedTreeCategory.length === 0
          ? ['At least one category must be selected.']
          : []
      "
    >
      <template v-slot:selection="{ index, item }">
        <v-chip v-if="showAll">
          {{ getCategoryString(item) }}
        </v-chip>
        <span v-else-if="index === 0" class="grey--text caption">
          {{ selectedTreeCategory.length }} selected
        </span>
      </template>
    </v-select>
    <v-menu
      v-model="menuOpen"
      :activator="`#${id}`"
      :close-on-content-click="false"
      :nudge-width="10"
      :nudge-left="10"
      max-height="240"
      width="100%"
    >
      <v-card>
        <v-treeview
          v-model="selectedTreeCategory"
          @input="syncCategoryFilter('update', selectedTreeCategory)"
          selectable
          :items="categoryList"
        ></v-treeview>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CategoryTreeSelect',
  props: {
    id: String,
    criteria: Object,
    showAll: Boolean,
  },
  data() {
    return {
      menuOpen: false,
      selectedTreeCategory: [],
    };
  },
  methods: {
    init() {
      // console.log('Umm', this.criteria);
      this.syncCategoryFilter('init', this.criteria);
    },
    syncCategoryFilter(mode, input) {
      // console.log(mode, input);
      if (mode === 'init') {
        // From input (criteria object) -> selectedCat
        const result = [];
        if (input && input.category) {
          input.category.forEach((c) => {
            result.push(`0::${c}`);
          });
        }
        if (input && input.subCategory) {
          // console.log('UMM', input.subCategory);
          input.subCategory.forEach((c) => {
            const { category, level } = c;
            if (category) {
              category.forEach((sc) => {
                result.push(`${level}::${sc}`);
              });
            }
          });
        }
        this.selectedTreeCategory = result;
        // Category and Subcategory
      } else if (mode === 'update') {
        let category = [];
        const subCategory = [];
        // From input (selectedCat) -> local filterData -> filterCriteria
        const levelData = [[], [], [], [], []];
        for (let c of input) {
          const splitted = c.split('::');
          levelData[splitted[0]].push(splitted[1]);
        }
        for (let i in levelData) {
          const l = levelData[i];
          if (i == 0) {
            category = l;
          } else {
            subCategory.push({
              level: i,
              category: l,
            });
          }
        }
        // console.log(category, subCategory);
        this.$emit('update', {
          category,
          subCategory,
          count: input.length,
        });
      }
    },
    getCategoryString(item) {
      let splitted = item.split('::');
      const catName = this.categoryLevelNameList[splitted[0]];
      return `${catName} : ${splitted[1]}`;
    },
  },
  computed: {
    ...mapGetters({
      categoryTreeData: 'config/categoryTreeData',
      categoryLevelNameList: 'config/categoryLevelNameList',
    }),
    categoryList() {
      if (this.categoryTreeData && this.categoryTreeData.tree) {
        return this.categoryTreeData.tree;
      }
      return [];
    },
  },
  watch: {
    criteria: {
      immediate: true,
      handler: 'init',
    },
  },
};
</script>

<style lang="scss" scoped>
.category-tree-select {
  width: 100%;
}
</style>
