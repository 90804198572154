import { render, staticRenderFns } from "./CategoryTreeSelect.vue?vue&type=template&id=b7893304&scoped=true&"
import script from "./CategoryTreeSelect.vue?vue&type=script&lang=js&"
export * from "./CategoryTreeSelect.vue?vue&type=script&lang=js&"
import style0 from "./CategoryTreeSelect.vue?vue&type=style&index=0&id=b7893304&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7893304",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VChip,VMenu,VSelect,VTreeview})
