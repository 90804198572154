<template>
  <v-card elevation="3" class="keyword-table">
    <!-- input add -->
    <div class="add-keyword-input">
      <div class="add-input-wrapper">
        <div class="add-input mt-3">
          <v-text-field
            label="Add keyword..."
            hide-details="auto"
            :rules="rules"
            v-model="toAddKeyword"
            dense
            outlined
          >
          </v-text-field>
          <div class="add-btn-wrapper" v-if="showButton">
            <div class="add-btn">
              <v-btn color="success" @click="addKeyword()"> Add </v-btn>
              <v-btn @click="cancelAddKeyword()"> Cancel </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TableBase
      :headers="headers"
      :items="keywords"
      :title="isExcludedKeyword ? 'Exclude Keyword' : 'Keyword'"
      :loading="loading"
      @delete="deleteKeyword"
    />

    <!-- dialog delete -->
    <ConfirmDialog
      :title="dialogDeleteTitle"
      :open="dialogDelete"
      @close="confirmDeleteKeyword"
    />
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import api from '@/services/api.js';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';
import TableBase from '@/components/Table/TableBase.vue';

export default {
  name: 'KeywordTable',
  components: {
    ConfirmDialog,
    TableBase,
  },
  data() {
    return {
      showButton: false,
      rules: [
        (value) => {
          if (value == null || value.length == 0) {
            this.showButton = false;
            return true;
          } else {
            var keywordFound = this.keywords.filter((k) => k.id == value);
            if (!/\S/.test(value)) {
              this.showButton = false;
              return 'Invalid keyword.';
            } else if (keywordFound.length > 0) {
              this.showButton = false;
              return 'Duplicate keyword.';
            } else {
              this.showButton = true;
              return true;
            }
          }
        },
      ],
      toAddKeyword: null,
      toDeletedKeyword: null,
      keywords: [],
      loading: true,
      dialogDelete: false,
      dialogDeleteTitle: '',
    };
  },
  props: {
    isExcludedKeyword: Boolean,
  },
  async mounted() {
    await this.getKeywords();
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        {
          text: 'Keyword',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Created At',
          sortable: true,
          filterable: false,
          value: 'createdAt',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          filterable: false,
          value: 'action',
        },
      ];
    },
  },
  methods: {
    ...mapState({
      activeToast(state) {
        state.toast.active = true;
      },
      errorToast(state) {
        state.toast.error = true;
      },
    }),
    deleteKeyword(keyword) {
      this.dialogDelete = true;
      this.toDeletedKeyword = keyword;
      this.dialogDeleteTitle = `Would you like to delete keyword "${keyword.id}"?`;
    },
    async confirmDeleteKeyword(state) {
      if (state) {
        if (this.isExcludedKeyword) {
          await api
            .deleteAccountExcludedKeyword(this.toDeletedKeyword.id)
            .catch((error) => {
              console.log(error);
              this.errorToast();
            });
        } else {
          await api
            .deleteAccountKeyword(this.toDeletedKeyword.id)
            .catch((error) => {
              console.log(error);
              this.errorToast();
            });
        }
        this.closeDialogDelete();
        await this.getKeywords();
        this.activeToast();
      }
      this.toDeletedKeyword = null;
      this.dialogDelete = false;
    },
    async getKeywords() {
      var result = {};
      if (this.isExcludedKeyword) {
        result = await api.getAccountExcludedKeyword().catch((error) => {
          console.log(error);
        });
      } else {
        result = await api.getAccountKeyword().catch((error) => {
          console.log(error);
        });
      }
      this.keywords = result.message;
      this.loading = false;
    },
    async addKeyword() {
      if (this.toAddKeyword && this.toAddKeyword.length > 0) {
        if (this.isExcludedKeyword) {
          await api
            .addAccountExcludedKeyword({ id: this.toAddKeyword })
            .catch((error) => {
              console.log(error);
              this.showButton = true;
              this.errorToast();
            });
        } else {
          await api
            .addAccountKeyword({ id: this.toAddKeyword })
            .catch((error) => {
              console.log(error);
              this.showButton = true;
              this.errorToast();
            });
        }
        this.getKeywords();
        this.toAddKeyword = null;
        this.showButton = false;
        this.activeToast();
      }
    },
    cancelAddKeyword() {
      this.toAddKeyword = null;
      this.showButton = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.keyword-table {
  padding: 20px 20px 0 20px;
  .keyword-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  .add-input-wrapper {
    display: flex;
    justify-content: center;
    .add-input {
      width: 500px;
    }
    .add-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      .add-btn button:last-child {
        margin-left: 20px;
      }
    }
  }
}
</style>
