<template>
  <v-layout class="engagement-bar">
    <v-layout align-center>
      <div class="total-engage" v-if="data.info.source !== 'twitter'">
        <span class="total-engage-title">All</span>
        <span class="total-engage-value">
          {{ engageValue.all | numeral('0,0') }}
        </span>
      </div>
      <div class="counter-wrapper-inner">
        <EngagementCounter
          v-if="data.info.source === 'facebook'"
          type="reaction_count"
          type-name="Reaction"
          addition-class="reaction-element"
          :count="engageValue.reaction"
        />
        <EngagementCounter
          v-if="data.info.source === 'twitter' && data.info.is_origin"
          type="retweet_count"
          type-name="Retweet"
          :count="data.summary.retweet_count"
        />
        <!-- Remaining counter -->
        <EngagementCounter
          v-for="counter in remainingCounter"
          :key="counter.type"
          :type="counter.type"
          :type-name="counter.name"
          :count="counter.value"
        />
      </div>
    </v-layout>
    <v-spacer></v-spacer>
    <v-layout
      justify-end
      align-center
      v-if="
        data.summary &&
        typeof data.summary.view_count === 'number' &&
        data.summary.view_count > 0
      "
    >
      <EngagementCounter
        type="view_count"
        type-name="View"
        addition-class="view-element"
        :count="data.summary.view_count"
      />
    </v-layout>
  </v-layout>
</template>

<script>
import helper from '@/services/helper';
import EngagementCounter from './EngagementCounter.vue';

export default {
  name: 'EngagementBar',
  components: {
    EngagementCounter,
  },
  props: {
    data: Object,
  },
  computed: {
    engageValue() {
      let all = 0;
      let reaction = 0;
      if (this.data && this.data.info) {
        if (this.data.info.source === 'facebook') {
          let allValue = 0;
          let reactionValue = 0;
          let sumCommentShare = 0;
          let keys = Object.keys(this.data.summary);
          for (let summaryKey of keys) {
            if (summaryKey.indexOf('count') > -1) {
              if (
                summaryKey !== 'view_count' &&
                summaryKey !== 'reaction_count'
              ) {
                if (this.data.summary[summaryKey]) {
                  // TODO check number ?
                  allValue += this.data.summary[summaryKey];
                  if (
                    summaryKey === 'comment_count' ||
                    summaryKey === 'share_count'
                  ) {
                    sumCommentShare += this.data.summary[summaryKey];
                  } else {
                    reactionValue += this.data.summary[summaryKey];
                  }
                }
              }
            }
          }
          // If field is more than calculated total - use original field instead
          if (this.data.summary && this.data.summary.reaction_count) {
            if (this.data.summary.reaction_count > reactionValue) {
              reactionValue = this.data.summary.reaction_count;
              allValue = reactionValue + sumCommentShare;
            }
          }
          if (this.data.analytic.engagement_score > allValue) {
            // console.log('SCORE > ALL');
            all = this.data.analytic.engagement_score;
            let showReaction =
              this.data.analytic.engagement_score - sumCommentShare;
            if (showReaction < 0) showReaction = 0;
            reaction = showReaction;
          } else {
            all = allValue;
            reaction = reactionValue;
          }
        } else {
          all = this.data.analytic.engagement_score;
        }
      }
      return {
        all,
        reaction,
      };
    },
    remainingCounter() {
      let result = [];
      if (this.data && this.data.info) {
        if (this.data.info.source === 'twitter') {
          // Twitter has no more ...
        } else if (this.data.info.source === 'facebook') {
          // Facebook show comment and share
          result = [
            {
              type: 'comment_count',
              value: this.data.summary.comment_count || 0,
            },
            {
              type: 'share_count',
              value: this.data.summary.share_count || 0,
            },
          ];
        } else {
          const summaryKeys = Object.keys(this.data.summary);
          for (let k of summaryKeys) {
            if (k !== 'availableCount' && k !== 'view_count' && k !== 'vote') {
              if (typeof this.data.summary[k] === 'number') {
                result.push({
                  type: k,
                  name: helper.normalizeKey(k),
                  value: this.data.summary[k],
                });
              } else {
                console.warn('Engage not number ..', k);
              }
            }
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.engagement-bar {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 500;
  .total-engage {
    white-space: nowrap;
    line-height: 26px;
    padding: 4px 5px;
    margin-right: 10px;
    .total-engage-title {
      margin-right: 3px;
    }
  }
  .counter-wrapper-inner {
    display: flex;
    width: 100%;
  }
}
</style>
