<template>
  <home-base-widget
    class="home-sentiment-block"
    title="Sentiment"
    :height="340"
    :selectedPreset="selectedPreset"
    :enableToday="true"
    :error="error"
    :loading="loading"
    @reload="init"
    @changePreset="onChangePreset"
  >
    <widget-row
      v-for="s in sentimentList"
      :key="s.name"
      :countChange="sentimentData[s.name].changesCount"
      :hideChange="hideChange"
      @click="drillDown(s.name)"
    >
      <template v-slot:left>
        <v-icon class="sentiment-icon" :class="s.name">
          {{ s.iconClass }}
        </v-icon>
        <span class="text-body ml-2">{{ s.name | capitalize }}</span>
      </template>
      <template v-slot:main-value>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ (sentimentData[s.name].proportion * 100) | numeral('0.0') }}%
            </span>
          </template>
          Total {{ sentimentData[s.name].count | numeral('0,0') }}
        </v-tooltip>
      </template>
      <template v-slot:sub-value>
        <v-tooltip left v-if="selectedPreset !== 'today'">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span>
                {{
                  sentimentData[s.name].changesCount | numeral('+0.0a', true)
                }}
              </span>
              <span>
                ({{
                  (sentimentData[s.name].changesPercent * 100)
                    | numeral('+0.00a')
                }}%)
              </span>
            </span>
          </template>
          Changes
          {{ sentimentData[s.name].changesCount | numeral('0,0', true) }}
        </v-tooltip>
      </template>
    </widget-row>
  </home-base-widget>
</template>

<script>
import HomeBaseWidget from './sub/HomeBaseWidget.vue';
import WidgetRow from './sub/WidgetRow.vue';
import { mapState, mapActions } from 'vuex';
import api from '@/services/api.js';

export default {
  name: 'HomeSentimentBlock',
  components: {
    HomeBaseWidget,
    WidgetRow,
  },
  data() {
    return {
      loading: true,
      error: false,
      selectedPreset: 'previous7days',
      sentimentList: [
        {
          name: 'positive',
          iconClass: 'fa-smile-o',
        },
        {
          name: 'neutral',
          iconClass: 'fa-meh-o',
        },
        {
          name: 'negative',
          iconClass: 'fa-frown-o',
        },
      ],
      sentimentData: {
        positive: { proportion: 0, changesCount: 0, changesPercent: 0 },
        neutral: { proportion: 0, changesCount: 0, changesPercent: 0 },
        negative: { proportion: 0, changesCount: 0, changesPercent: 0 },
      },
    };
  },
  watch: {
    isEngagementMode: { handler: 'init' },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      this.error = false;
      this.loading = true;
      const result = await api
        .getHomeSentiment(this.isEngagementMode, this.selectedPreset)
        .catch(() => {
          this.error = true;
        });
      if (result && result.message) {
        this.sentimentData = result.message;
      }
      this.loading = false;
    },
    onChangePreset(preset) {
      this.selectedPreset = preset;
      this.init();
    },
    drillDown(sentiment) {
      const criteria = {
        payload: {
          title: `${sentiment} sentiment`,
          preset: this.selectedPreset,
          drilldownType: 'engage',
        },
        original: {
          sentiment: [sentiment],
          paging: {
            page: 1,
            recordPerPage: 25,
          },
        },
      };
      this.showDrilldown(criteria);
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
    hideChange() {
      return this.selectedPreset === 'today';
    },
  },
};
</script>

<style lang="scss" scoped>
.home-sentiment-block {
  grid-row: span 4;
  grid-column: span 1;
  .sentiment-icon {
    font-size: 28px;
    &.positive {
      color: var(--positive-sentiment) !important;
    }
    &.neutral {
      color: var(--neutral-sentiment) !important;
    }
    &.negative {
      color: var(--negative-sentiment) !important;
    }
  }
}
</style>
