<template>
  <v-dialog
    v-model="isOpen"
    content-class="confirm-dialog"
    max-width="400px"
    @click:outside="$emit('close', false)"
  >
    <v-card outline>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ content }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" text @click="$emit('close', false)">
          {{ cancelButton }}
        </v-btn>
        <v-btn color="primary" @click="$emit('close', true)">
          {{ okButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    open: Boolean,
    title: {
      type: String,
      default: 'Confirmation',
    },
    content: {
      type: String,
      default: 'Are you sure?',
    },
    okButton: {
      type: String,
      default: 'Yes',
    },
    cancelButton: {
      type: String,
      default: 'Cancel',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    open() {
      this.isOpen = this.open;
    },
  },
};
</script>

<style lang="scss" scoped></style>
