<template>
  <v-btn :class="sentimentClass" dark elevation="0" small fab icon>
    <v-icon>
      {{ sentimentIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SentimentCircleIcon',
  props: {
    data: Object,
  },
  computed: {
    sentimentIcon() {
      if (this.data && this.data.analytic) {
        if (this.data.analytic.sentiment_score > 1) return 'fa-smile-o';
        if (this.data.analytic.sentiment_score < -1) return 'fa-frown-o';
        return 'fa-meh-o';
      }
      return '';
    },
    sentimentClass() {
      if (this.data && this.data.analytic) {
        if (this.data.analytic.sentiment_score > 1)
          return 'sentiment-positive-bg';
        if (this.data.analytic.sentiment_score < -1)
          return 'sentiment-negative-bg';
        return 'sentiment-neutral-bg';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
