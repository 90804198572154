<template>
  <v-container fluid class="user-manager">
    <v-card>
      <v-card-text>
        <v-row class="head-row">
          <v-col cols="9">
            <v-layout align-center>
              <div class="title-text mr-3">User Manager</div>
            </v-layout>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.trim="userFilterInput"
              outlined
              label="Search"
              dense
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="userHeader"
          :items="filteredUserList"
          :items-per-page="10"
          :loading="loadingUser"
          class="elevation-0"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status === 'active'" color="green">
              mdi-account-check
            </v-icon>
            <v-icon v-else color="red"> mdi-account-remove </v-icon>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            {{ convertRow(item.role) }}
          </template>
          <template v-slot:[`item.isAcceptReport`]="{ item }">
            {{ item.isAcceptReport ? 'Yes' : 'No' }}
          </template>
          <template v-slot:[`item.isAcceptSpecialKeyword`]="{ item }">
            {{ item.isAcceptSpecialKeyword ? 'Yes' : 'No' }}
          </template>
          <template v-slot:[`item.isAcceptAnomaly`]="{ item }">
            {{ item.isAcceptAnomaly ? 'Yes' : 'No' }}
          </template>
          <template v-slot:[`item.isAcceptSettingChange`]="{ item }">
            {{ item.isAcceptSettingChange ? 'Yes' : 'No' }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ item.updatedAt | timeFromNow }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-layout align-center>
              <v-btn
                x-small
                icon
                @click="showUserDialog('edit', item)"
                color="primary"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small icon @click="deleteUser(item)" color="red">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-layout>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      scrollable
      v-model="userDialogOpen"
      content-class="user-dialog"
      max-width="900px"
    >
      <v-card outline>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title align-center>
            <v-layout class="text-body-2" align-center>
              <span v-if="userDialogMode === 'add'"> New User </span>
              <span v-else> Edit user </span>
            </v-layout>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="userDialogOpen = false">
            <v-icon small> fa-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row class="head-row mb-3">
                <v-col cols="9">
                  <div class="title-text">User Info</div>
                </v-col>
              </v-row>
              <v-layout align-center justify-space-between class="mb-4">
                <div class="title-text">Login with facebook</div>
                <v-switch
                  small
                  dense
                  v-model="loginWithFacebook"
                  inset
                  @change="setLoginType"
                  hide-details
                  class="mt-0"
                ></v-switch>
              </v-layout>
              <v-text-field
                ref="username"
                class="mb-3"
                v-model.trim="userDialogData.username"
                :rules="[rules.required]"
                outlined
                label="Username"
                dense
                hide-details="auto"
                :disabled="userDialogMode === 'edit'"
                :readonly="userDialogMode === 'edit'"
                v-if="
                  userDialogMode === 'edit' ||
                  (userDialogMode === 'add' && !loginWithFacebook)
                "
              />
              <v-text-field
                v-if="userDialogMode === 'add' && !loginWithFacebook"
                class="mb-3"
                ref="password"
                v-model="userDialogData.password"
                :rules="[rules.required, rules.securePassword]"
                outlined
                label="Password"
                type="password"
                dense
                hide-details="auto"
              />
              <v-text-field
                v-if="userDialogMode === 'add' && !loginWithFacebook"
                ref="confirmPassword"
                class="mb-3"
                v-model="confirmPasswordInput"
                :rules="[rules.required, rules.samePassword]"
                outlined
                label="Confirm password"
                type="password"
                dense
                hide-details="auto"
              />
              <v-text-field
                ref="email"
                class="mb-3"
                v-model.trim="userDialogData.email"
                :rules="[rules.required]"
                outlined
                label="Email"
                type="email"
                dense
                hide-details="auto"
              />
              <v-select
                v-model="userDialogData.role"
                :items="roleItems"
                label="Role"
                outlined
                dense
                hide-details
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-row class="head-row mb-3">
                <v-col>
                  <v-layout align-center justify-space-between>
                    <div class="title-text">Active Status</div>
                    <v-switch
                      small
                      dense
                      v-model="userActiveStatus"
                      inset
                      @change="setUserActive"
                      hide-details
                      class="mt-0"
                      color="success"
                    ></v-switch>
                  </v-layout>
                </v-col>
              </v-row>
              <v-row class="head-row">
                <v-col cols="9">
                  <div class="title-text">Receive Notification</div>
                </v-col>
              </v-row>
              <v-list>
                <v-list-item link>
                  <v-layout align-center justify-space-between>
                    <div class="text-body-1">Weekly and Monthly Report</div>
                    <v-switch
                      small
                      dense
                      v-model="userDialogData.isAcceptReport"
                      inset
                      hide-details
                      class="mt-0"
                    ></v-switch>
                  </v-layout>
                </v-list-item>
                <v-list-item link>
                  <v-layout align-center justify-space-between>
                    <div class="text-body-1">Keyword Alert</div>
                    <v-switch
                      small
                      dense
                      v-model="userDialogData.isAcceptSpecialKeyword"
                      inset
                      hide-details
                      class="mt-0"
                    ></v-switch>
                  </v-layout>
                </v-list-item>
                <v-list-item link>
                  <v-layout align-center justify-space-between>
                    <div class="text-body-1">Anomaly Alert</div>
                    <v-switch
                      small
                      dense
                      v-model="userDialogData.isAcceptAnomaly"
                      inset
                      hide-details
                      class="mt-0"
                    ></v-switch>
                  </v-layout>
                </v-list-item>
                <v-list-item link>
                  <v-layout align-center justify-space-between>
                    <div class="text-body-1">Change Settings Alert</div>
                    <v-switch
                      small
                      dense
                      v-model="userDialogData.isAcceptSettingChange"
                      inset
                      hide-details
                      class="mt-0"
                    ></v-switch>
                  </v-layout>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onUserDialogClose(false)"
            tabindex="0"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" @click="onUserDialogClose(true)"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :open="confirmDialogOpen"
      title="Delete account confirmation"
      :content="confirmDialogContent"
      okButton="Delete"
      @close="onDialogConfirm"
    />
    <v-btn
      color="primary"
      class="add-btn"
      fab
      small
      @click="showUserDialog('add')"
    >
      <v-icon>mdi-account-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import api from '@/services/api';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';

const securePassRegExp = new RegExp(
  '((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32})+$'
);

export default {
  name: 'UserManager',
  components: {
    ConfirmDialog,
  },
  async created() {
    await this.getUserList();
    if (this.userRole !== 'user') {
      this.userHeader.push({ text: 'Actions', value: 'action' });
    }
    if (this.userRole === 'domadmin') {
      this.roleItems.push({
        text: 'Dom Admin',
        value: 'domadmin',
      });
      this.roleItems.push({
        text: 'Feed Only',
        value: 'feedonly',
      });
    }
  },
  data() {
    return {
      loadingUser: true,
      userFilterInput: '',
      userHeader: [
        {
          text: 'Active',
          value: 'status',
          align: 'center',
        },
        { text: 'Username', value: 'username', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Role', value: 'role', align: 'center' },
        {
          text: 'Report Alert',
          value: 'isAcceptReport',
          align: 'center',
          width: 85,
          class: 'text-break-word',
        },
        {
          text: 'Keyword Alert',
          value: 'isAcceptSpecialKeyword',
          align: 'center',
          width: 85,
          class: 'text-break-word',
        },
        {
          text: 'Anomaly Alert',
          value: 'isAcceptAnomaly',
          align: 'center',
          width: 85,
          class: 'text-break-word',
        },
        {
          text: 'Setting Alert',
          value: 'isAcceptSettingChange',
          align: 'center',
          width: 85,
          class: 'text-break-word',
        },
        { text: 'Updated At', value: 'updatedAt', align: 'center' },
      ],
      userList: [],
      userDialogOpen: false,
      userDialogMode: 'add',
      userDialogData: {
        username: '',
        password: '',
        isAcceptReport: false,
        isAcceptSpecialKeyword: false,
        isAcceptAnomaly: false,
        isAcceptSettingChange: false,
        role: 'user',
        status: 'active',
        loginType: 'dom',
      },
      userEditOptions: {
        id: '',
        type: '',
      },
      userActiveStatus: true,
      loginWithFacebook: false,
      confirmPasswordInput: '',
      roleItems: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Admin',
          value: 'admin',
        },
        {
          text: 'Account Manager',
          value: 'accountmanager',
        },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        securePassword: (value) =>
          securePassRegExp.test(value) ||
          'Password must contain at least 8 lowercase and upper case alphabet, along with digit characters.',
        samePassword: (value) =>
          value === this.userDialogData.password ||
          'Confirm password not match',
      },
      deletingItem: {},
      confirmDialogOpen: false,
      confirmDialogContent: '',
    };
  },
  methods: {
    async getUserList() {
      this.loadingUser = true;
      const result = await api.getUserList().catch(() => {});
      if (result && result.message) {
        this.userList = result.message;
      }
      this.loadingUser = false;
    },
    convertRow(rawRole) {
      let role = 'User';
      switch (rawRole) {
        case 'user':
          role = 'User';
          break;
        case 'accountmanager':
          role = 'Account Manager';
          break;
        case 'admin':
          role = 'Admin';
          break;
        case 'domadmin':
          role = 'Dom Admin';
          break;
        case 'feedonly':
          role = 'Feed Only';
          break;
      }
      return role;
    },
    setUserActive(state) {
      if (state) this.userDialogData.status = 'active';
      else this.userDialogData.status = 'inactive';
    },
    setLoginType(state) {
      if (state) this.userDialogData.loginType = 'facebook';
      else this.userDialogData.status = 'dom';
    },
    showUserDialog(mode, data) {
      this.userDialogMode = mode;
      if (mode === 'add') {
        this.userDialogData = {
          username: '',
          password: '',
          isAcceptReport: false,
          isAcceptSpecialKeyword: false,
          isAcceptAnomaly: false,
          isAcceptSettingChange: false,
          role: 'user',
          status: 'active',
        };
        this.userActiveStatus = true;
        this.loginWithFacebook = false;
        this.confirmPasswordInput = '';
      } else {
        const {
          id,
          loginType,
          username,
          email,
          status,
          role,
          isAcceptReport,
          isAcceptSpecialKeyword,
          isAcceptAnomaly,
          isAcceptSettingChange,
        } = data;
        this.userDialogData = {
          username,
          email,
          isAcceptReport,
          isAcceptSpecialKeyword,
          isAcceptAnomaly,
          isAcceptSettingChange,
          role,
          status,
        };
        this.userEditOptions = {
          id,
          type: loginType,
        };
        this.loginWithFacebook = loginType === 'facebook';
        this.userActiveStatus = status === 'active';
      }
      this.userDialogOpen = true;
    },
    async onUserDialogClose(state) {
      if (state) {
        let result;
        if (this.userDialogMode === 'add') {
          result = await api.addUser(this.userDialogData).catch(() => {});
        } else {
          result = await api
            .updateUser(this.userDialogData, this.userEditOptions)
            .catch(() => {});
        }
        if (result && result.message) {
          await this.getUserList();
          this.$toast.success(`User ${this.userDialogMode}ed successfully.`);
        } else {
          this.$toast.error(`User ${this.userDialogMode} failed.`);
        }
      }
      this.userDialogOpen = false;
    },
    deleteUser(item) {
      this.confirmDialogContent = `Would you like to delete account "${item.username}"?`;
      this.deletingItem = item;
      this.confirmDialogOpen = true;
    },
    async onDialogConfirm(state) {
      if (state) {
        const { id, role } = this.deletingItem;
        const result = await api.removeUser(id, role);
        if (result && result.message) {
          await this.getUserList();
          this.$toast.success('User removed successfully.');
        } else {
          this.$toast.error('User remove failed.');
        }
      }
      this.confirmDialogOpen = false;
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'account/userRole',
    }),
    filteredUserList() {
      if (this.userFilterInput && this.userList) {
        const str = this.userFilterInput.toLowerCase();
        return this.userList.filter((obj) => {
          const { username, email } = obj;
          let result = false;
          if (username) {
            const cName = username.toLowerCase();
            result = result || cName.includes(str);
          }
          if (email) {
            const eName = email.toLowerCase();
            result = result || eName.includes(str);
          }
          return result;
        });
      }
      return this.userList;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-manager {
  position: relative;
  .add-btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
  }
}
</style>
