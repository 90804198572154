<template>
  <v-icon class="source-icon" :small="isSmall" :style="iconStyle">
    {{ iconText }}
  </v-icon>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SourceIcon',
  props: {
    source: String,
    isSmall: Boolean,
  },
  computed: {
    ...mapState({
      colors: (state) => state.account.colors,
    }),
    iconText() {
      switch (this.source) {
        case 'twitter':
          return 'fa-twitter';
        case 'facebook':
          return 'fa-facebook';
        case 'instagram':
          return 'fa-instagram';
        case 'googleplus':
          return 'fa-google-plus';
        case 'youtube':
          return 'fa-youtube';
        case 'blockdit':
          return 'customsrc-blockdit';
        default:
          return 'fa-comment';
      }
    },
    iconStyle() {
      // TODO get color by source
      let result = { color: '#333' };
      if (this.colors && this.colors[this.source]) {
        result.color = this.colors[this.source];
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
.source-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  &.customsrc {
    &.customsrc-blockdit {
      background: url('../assets/source-icon/blockdit.png') no-repeat;
      background-size: contain;
    }
  }
}
</style>
