export default {
  sentimentItem: [
    { value: 'positive', text: 'Positive' },
    { value: 'neutral', text: 'Neutral' },
    { value: 'negative', text: 'Negative' },
  ],
  sortFieldList: [
    { value: 'engagement_score', text: 'Engagements' },
    { value: 'search_score', text: 'Best matches' },
    { value: 'created_at', text: 'Time' },
    { value: 'sentiment_score', text: 'Sentiment' },
    { value: 'like_count', text: 'Like Count' },
    { value: 'retweet_count', text: 'Retweet Count' },
  ],
  sortDirectionList: [
    { value: 'asc', text: 'Ascending' },
    { value: 'desc', text: 'Descending' },
  ],
  editedModeList: [
    { value: 'null', text: 'Any' },
    { value: 'true', text: 'Reviewed' },
    { value: 'false', text: 'Not reviewed' },
  ],
  dateModeList: [
    { value: 'today', text: 'Today' },
    { value: 'yesterday', text: 'Yesterday' },
    { value: 'last7days', text: 'Last 7 days' },
    { value: 'last28days', text: 'Last 28 days' },
    { value: 'lastMonth', text: 'Last month' },
    { value: 'last37days', text: 'Last 37 days' },
    { value: 'thisYear', text: 'This year' },
    { value: 'lastYear', text: 'Last year' },
    { value: 'custom', text: 'Custom' },
  ],
  exportTypeList: [
    { text: 'Raw Data', value: 'raw' },
    { text: 'Report', value: 'report' },
  ],
  exportFileTypeList: [
    { text: 'CSV', value: 'csv' },
    { text: 'JSON', value: 'json' },
  ],
  reportFileTypeList: [{ text: 'PDF', value: 'pdf' }],
};
