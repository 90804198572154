<template>
  <v-container class="home">
    <div class="home-wrapper">
      <OverviewBlock />
      <SentimentBlock />
      <WordTrend />
      <SourceTraffic />
      <CategoryTrend />
      <InfluencerTrend />
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
// @ is an alias to /src
import OverviewBlock from '@/components/Home/HomeOverview.vue';
import SentimentBlock from '@/components/Home/HomeSentimentBlock.vue';
import WordTrend from '@/components/Home/HomeWordTrend.vue';
import SourceTraffic from '@/components/Home/HomeSourceTraffic.vue';
import CategoryTrend from '@/components/Home/HomeCategoryTrend.vue';
import InfluencerTrend from '@/components/Home/HomeTrendingInfluencer.vue';

export default {
  name: 'Home',
  components: {
    OverviewBlock,
    SentimentBlock,
    WordTrend,
    SourceTraffic,
    CategoryTrend,
    InfluencerTrend,
  },
  async mounted() {
    await this.getAccountKeyword().catch(() => {});
  },
  methods: {
    ...mapActions({
      getAccountKeyword: 'config/getAccountKeyword',
    }),
  },
};
</script>

<style lang="scss" scoped>
.home {
  .home-wrapper {
    // padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 90px;
    grid-auto-flow: dense;
    // grid-gap: 22px;
    margin: 0 auto;
    @media screen and (max-width: 959px) {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    @media screen and (min-width: 960px) {
      grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    }
    @media screen and (min-width: 960px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
