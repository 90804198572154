<template>
  <div>
    <v-alert
      class="alert-toast"
      :class="toggle ? 'active' : 'hide'"
      :type="error ? 'error' : 'success'"
      >{{ error ? 'Something went wrong.' : 'Success!!' }}</v-alert
    >
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'AlertToast',
  computed: {
    ...mapState({
      toggle(state) {
        setTimeout(() => {
          state.toast.active = false;
        }, 1100);
        return state.toast.active;
      },
      error(state) {
        setTimeout(() => {
          state.toast.error = false;
        }, 1600);
        return state.toast.error;
      },
    }),
  },
};
</script>
<style lang="scss" scoped>
.alert-toast {
  position: absolute;
  top: 10px;
  right: -20rem;
  z-index: 300;
  transition: right 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}
.alert-toast.active {
  right: 0;
}
.alert-toast.hide {
  right: -20rem;
}
</style>
